import React from "react";
import { motion } from "framer-motion";
import {
  MapPin,
  Phone,
  Mail,
  Facebook,
  Instagram,
  ArrowRight,
  ExternalLink,
  Youtube,
} from "lucide-react";
import logo from "../assets/LogoRkcsc.png";
import { Link } from "react-router-dom";

const Footer = () => {
  const fadeInUp = {
    initial: { y: 20, opacity: 0 },
    animate: { y: 0, opacity: 1 },
    transition: { duration: 0.5 },
  };

  const socialLinks = [
    {
      icon: <Facebook size={18} />,
      href: "https://www.facebook.com/people/Rk-Consultancy-Csc-Services/61557614644261/",
      color: "hover:bg-blue-600",
    },
    {
      icon: <Youtube size={18} />,
      href: "https://www.youtube.com/@rk.consultancy",
      color: "hover:bg-red-500",
    },
    {
      icon: <Instagram size={18} />,
      href: "https://www.instagram.com/rkcsc.in/",
      color: "hover:bg-pink-600",
    },
  ];

  const quickLinks = [
    { name: "Home", href: "/" },
    { name: "Services", href: "/services" },
    { name: "About", href: "/about" },
    { name: "Contact", href: "/contact" },
  ];

  return (
    <footer className="bg-gradient-to-br from-slate-50 via-slate-100 to-slate-200">
      <div className="container mx-auto px-6 py-12">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6 }}
          className="grid grid-cols-1 md:grid-cols-3 gap-8 mb-12"
        >
          {[
            {
              icon: <MapPin className="w-5 h-5" />,
              title: "Visit Us",
              content:
                "Pantnagar , Udham Singh Nagar , Uttarakhand",
            },
            {
              icon: <Phone className="w-5 h-5" />,
              title: "Call Us",
              content: "+91 8445522551",
            },
            {
              icon: <Mail className="w-5 h-5" />,
              title: "Email Us",
              content: "rkconsultancy.csc@gmail.com",
            },
          ].map((item, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6, delay: index * 0.1 }}
              className="bg-white p-6 rounded-xl shadow-sm hover:shadow-md transition-shadow duration-300"
            >
              <div className="flex items-start gap-4">
                <div className="p-3 bg-blue-50 rounded-lg text-blue-600">
                  {item.icon}
                </div>
                <div>
                  <h3 className="text-lg font-semibold text-gray-900 mb-2">
                    {item.title}
                  </h3>
                  <p className="text-black">{item.content}</p>
                </div>
              </div>
            </motion.div>
          ))}
        </motion.div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-12 pt-12 border-t border-gray-200">
          <motion.div {...fadeInUp}>
            <div className="flex items-center gap-3 mb-6">
              <img src={logo} alt="RK CSC logo" className="w-11 h-11 rounded" />
              <h2 className="text-xl font-bold text-gray-900">
                RK Consultancy
              </h2>
            </div>
            <p className="text-black mb-8">
              Providing comprehensive Government, Semi-Government and Private
              Company Online Services at competitive rates.
            </p>
            <div className="flex gap-4">
              {socialLinks.map((social, index) => (
                <motion.a
                  key={index}
                  href={social.href}
                  target="_blank"
                  whileHover={{ scale: 1.1 }}
                  whileTap={{ scale: 0.95 }}
                  className={`p-2 bg-gray-100 rounded-lg text-gray-600 transition-colors ${social.color}`}
                >
                  {social.icon}
                </motion.a>
              ))}
            </div>
          </motion.div>

          <motion.div {...fadeInUp} className="lg:ml-12">
            <h3 className="text-lg font-semibold text-gray-900 mb-6">
              Quick Links
            </h3>
            <ul className="space-y-4">
              {quickLinks.map((link, index) => (
                <motion.li
                  key={index}
                  whileHover={{ x: 5 }}
                  className="flex items-center gap-2"
                >
                  <ArrowRight className="w-4 h-4 text-blue-600" />
                  <Link
                    to={link.href}
                    className="text-black hover:text-blue-600 transition-colors"
                  >
                    {link.name}
                  </Link>
                </motion.li>
              ))}
            </ul>
          </motion.div>

          <motion.div {...fadeInUp}>
            <h3 className="text-lg font-semibold text-gray-900 mb-6">
              Business Hours
            </h3>
            <ul className="space-y-4">
              {[
                { day: "Monday - Friday", hours: "9:00 AM - 6:00 PM" },
                { day: "Saturday", hours: "9:00 AM - 4:00 PM" },
                { day: "Sunday", hours: "Closed" },
              ].map((schedule, index) => (
                <li key={index} className="flex justify-between text-black">
                  <span>{schedule.day}</span>
                  <span>{schedule.hours}</span>
                </li>
              ))}
            </ul>
          </motion.div>
        </div>
      </div>

      <div className="border-t border-gray-200 bg-white/50 backdrop-blur-sm">
        <div className="container mx-auto px-6 py-4">
          <div className="flex flex-col md:flex-row justify-between items-center gap-4">
            <p className="text-gray-600 text-sm">
              © {new Date().getFullYear()} RK Consultancy. All rights reserved.
            </p>

            <div className="flex items-center gap-2 text-gray-600">
              <span>Made with ❤️ by</span>
              <a
                href="https://bytewave.in/"
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-600 hover:text-blue-700 transition-colors flex items-center gap-1"
              >
                BIL
                <ExternalLink className="w-3 h-3" />
              </a>
            </div>

            <div className="flex gap-3 justify-center items-center">
              <a
                href="/privacy"
                className="text-gray-600 hover:text-blue-600 transition-colors"
              >
                Privacy Policy
              </a>
              <a
                href="/terms"
                className="text-gray-600 hover:text-blue-600 transition-colors"
              >
                Terms of Service
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;

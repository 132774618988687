import React, { useContext, useState } from "react";
import descriptionImg from "../assets/description.png";
import titleImg from "../assets/cardServiceIcon.png";
import AppContext from "../AppContext/AppContext";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const ProductCard = ({ product, setSigninClicked }) => {
  const [isHovered, setIsHovered] = useState(false);
  const appContext = useContext(AppContext);
  const navigate = useNavigate();

  const notify = (text, time) => {
    toast.dismiss();
    toast.info(text, {
      toastId: "random1",
      position: "top-center",
      autoClose: time || 1500,
      theme: "light",
      style: {
        padding: "15px 20px",
        fontSize: "16px",
        textAlign: "center",
        fontWeight: "500",
      },
    });
  };

  return (
    <div 
      className={`relative bg-white rounded-xl overflow-hidden transform transition-all duration-500 ease-out
        ${isHovered ? 'shadow-2xl translate-y-[-8px]' : 'shadow-lg hover:shadow-xl'}
        max-w-sm w-full h-[405px] flex flex-col`}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    > 
      <div className="relative h-48 flex-shrink-0">
        <div className={`absolute inset-0 bg-gradient-to-t from-black/30 via-transparent to-transparent
          transition-opacity duration-500 ${isHovered ? 'opacity-100' : 'opacity-0'}`} />
        <img
          src={product.image}
          alt={product.productName}
          className={`w-full h-full object-cover transform transition-all duration-700 ease-out
            ${isHovered ? 'scale-110' : 'scale-100'}`}
        />
      </div>
 
      <div className={`flex-1 flex flex-col p-4 space-y-4 transform transition-all duration-500
        ${isHovered ? 'bg-gradient-to-b from-white to-blue-50' : 'bg-white'}`}>
        
        <div className="flex items-start gap-1 h-[45px] transform transition-all duration-300">
          <img
            src={titleImg}
            alt="Apartment icon"
            className={`h-[30px] w-[30px] flex-shrink-0 transform transition-all duration-300
              ${isHovered ? 'scale-110 rotate-12' : 'scale-100 rotate-0'}`}
          />
          <h3 className="xl:text-lg text-lg font-semibold text-blue-800 line-clamp-2 break-words" 
              title={product.productName}>
            {product.productName}
          </h3>
        </div>
 
        <div className="flex items-start gap-1 flex-1">
          <img
            src={descriptionImg}
            alt="Location icon"
            className={`h-[25px] w-[25px] flex-shrink-0 transform transition-all duration-300
              ${isHovered ? 'scale-110 rotate-12' : 'scale-100 rotate-0'}`}
          />
          <p className="text-gray-600 text-[14px] line-clamp-3">
            {product.description}
          </p>
        </div>
 
        <div className="flex items-center justify-between pt-2 px-2">
          <span className={`text-xl font-bold transition-all duration-300
            ${isHovered ? 'text-blue-700 scale-105' : 'text-gray-800'}`}>
            ₹ {appContext.userInfoVal.accountType === "member" 
              ? product.member_price 
              : product.price}
          </span>
          
          <button
            onClick={(e) => {
              e.preventDefault();
              if (appContext.userInfoVal.sessionId === null) {
                setSigninClicked(true);
                notify("Please login to continue", 3000);
              } else {
                navigate("/checkout");
              }
            }}
            className={`px-6 py-2.5 rounded-lg font-medium transition-all duration-300
              ${isHovered 
                ? 'bg-blue-600 text-white transform scale-105 shadow-lg shadow-blue-200' 
                : 'bg-blue-500 text-white'}`}
          >
            Apply Now
          </button>
        </div>
      </div>

      <div className={`absolute inset-0 border-2 rounded-xl transition-all duration-500
        ${isHovered 
          ? 'border-blue-400 scale-105 opacity-100' 
          : 'border-transparent scale-100 opacity-0'}`}
      />
    </div>
  );
};

export default ProductCard;
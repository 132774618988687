import React, { useState, useRef, useEffect } from "react";
import { FiPlus } from "react-icons/fi";
import { LuWallet } from "react-icons/lu";
import { LuCreditCard } from "react-icons/lu";
import Core from "../core/Core";
import { IoMdClose } from "react-icons/io";

const AddWallet = ({ showAddWallet, setShowAddeWallet }) => {
  const { handleAddWallet } = Core();
  const [amount, setAmount] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  
  const minimumAmount = 100;
  const presetAmounts = [100, 250, 500, 1000, 1500];
  const walletRef = useRef(null);

  const validateAmount = (value) => {
    setError("");
    
    const numValue = Number(value);
    
    if (value.includes('.')) {
      setError("Please enter amount in whole rupees only (no decimal points)");
      return false;
    }
    
    if (numValue < minimumAmount) {
      setError(`Minimum amount should be ₹${minimumAmount}`);
      return false;
    }
    
    return true;
  };

  const handleAmountChange = (e) => {
    const value = e.target.value;
    setAmount(value);
    
    if (value) {
      validateAmount(value);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    
    if (!validateAmount(amount)) {
      return;
    }
    
    setLoading(true);
    handleAddWallet(amount);
    // setTimeout(() => {
    //   setLoading(false);
    //   setAmount("");
    // }, 1500);
  };

  useEffect(() => {
    const handleClose = (e) => {
      if (walletRef.current && !walletRef.current.contains(e.target)) {
        setShowAddeWallet(false);
      }
    };

    document.addEventListener("mousedown", handleClose);
    return () => {
      document.removeEventListener("mousedown", handleClose);
    };
  }, [walletRef]);

  return (
    <div 
      className="flex justify-center items-center top-0 h-full bg-black/50 w-full z-[1000] left-0"
      style={{position:"fixed"}}
    >
      <div
        ref={walletRef}
        className="lg:w-1/2 w-[90%] relative max-w-md mx-auto bg-white rounded-lg shadow-lg overflow-hidden"
      >
        <button
          onClick={() => {
            setShowAddeWallet(false);
          }}
          className="cursor-pointer text-2xl absolute top-0 right-0 px-2 bg-slate-600 text-white flex justify-center items-center hover:bg-slate-700"
        >
          <IoMdClose />
        </button>

        <div className="p-6 space-y-1">
          <div className="flex items-center space-x-2">
            <LuWallet className="w-6 h-6 text-blue-600" />
            <h2 className="text-2xl font-bold text-gray-800">
              Add Money to Wallet
            </h2>
          </div>
          <p className="text-gray-500">
            Choose an amount to add to your wallet
          </p>
        </div>

        <div className="p-6 pt-0">
          <form onSubmit={handleSubmit} className="space-y-6">
            <div className="space-y-2">
              <label className="block text-sm font-medium text-gray-700">
                Enter Amount (Minimum ₹{minimumAmount})
              </label>
              <div className="relative">
                <span className="absolute left-3 top-1/2 -translate-y-1/2 text-gray-500">
                  ₹
                </span>
                <input
                  type="number"
                  value={amount}
                  onChange={handleAmountChange}
                  className={`w-full pl-8 pr-4 py-2 border rounded-lg focus:ring-2 focus:ring-blue-600 focus:border-transparent outline-none transition-all ${
                    error ? "border-red-500" : "border-gray-300"
                  }`}
                  placeholder="100"
                  min={minimumAmount}
                  step="1"
                  onKeyDown={(e) => {
                    if (e.key === '.') {
                      e.preventDefault();
                    }
                  }}
                />
              </div>
              {error && (
                <p className="text-red-500 text-sm mt-1">{error}</p>
              )}
            </div>

            <div className="space-y-2">
              <label className="block text-sm font-medium text-gray-700">
                Quick Select
              </label>
              <div className="grid grid-cols-3 gap-2">
                {presetAmounts.map((preset) => (
                  <button
                    key={preset}
                    type="button"
                    onClick={() => {
                      setAmount(preset.toString());
                      setError("");
                    }}
                    className="px-4 py-2 border border-gray-200 rounded-lg hover:bg-blue-50 hover:border-blue-600 transition-colors"
                  >
                    ₹{preset}
                  </button>
                ))}
              </div>
            </div>

            <div className="bg-blue-50 p-4 rounded-lg flex items-start space-x-3">
              <LuCreditCard className="w-5 h-5 text-blue-600 mt-0.5" />
              <div className="text-sm text-blue-800">
                <p className="font-medium">Secure Payment</p>
                <p>Your transaction is secured with SSL encryption</p>
              </div>
            </div>

            <button
              type="submit"
              disabled={!amount || loading || error}
              className={`w-full flex items-center justify-center space-x-2 py-3 rounded-lg text-white font-medium
             ${
               loading || !amount || error
                 ? "bg-blue-400 cursor-not-allowed"
                 : "bg-blue-600 hover:bg-blue-700"
             } 
             transition-colors focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2`}
            >
              {loading ? (
                <div className="w-5 h-5 border-2 border-white border-t-transparent rounded-full animate-spin" />
              ) : (
                <>
                  <FiPlus className="w-5 h-5" />
                  <span>Add ₹{amount || "0"} to Wallet</span>
                </>
              )}
            </button>
          </form>
        </div>

        <div className="bg-gray-50 px-6 py-4 text-sm text-gray-600">
          Funds will be available instantly after successful payment
        </div>
      </div>
    </div>
  );
};

export default AddWallet;
import React, { useState, useEffect } from 'react';
import { Search } from 'lucide-react';

const ServicesHeader = ({ onSearch }) => {
  const [searchValue, setSearchValue] = useState('');
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setIsVisible(true);
  }, []);

  return (
    <div className="relative pt-2 pb-16 overflow-hidden">
    
      <div className="absolute inset-0 overflow-hidden">
        <div className="absolute -rotate-12 -left-10 -top-10 w-40 h-40 bg-blue-200 rounded-full opacity-20" />
        <div className="absolute rotate-12 -right-10 -bottom-10 w-40 h-40 bg-blue-300 rounded-full opacity-20" />
      </div>

 
      <div className={`max-w-md mx-auto mb-2 transform transition-all duration-700 ${
        isVisible ? 'translate-y-0 opacity-100' : '-translate-y-10 opacity-0'
      }`}>
        <div className="relative">
          <input
            type="text"
            placeholder="Search services..."
            value={searchValue}
            onChange={(e) => {
              setSearchValue(e.target.value);
              onSearch(e.target.value);
            }}
            style={{border: "2px solid #dbdbdb"}}
            className="w-full px-4 py-2 pl-12 shadow-xl rounded-full border-2 border-blue-200 focus:border-blue-400 focus:ring-2 focus:ring-blue-200 transition-all duration-300 outline-none"
          />
          <Search className="absolute left-4 top-1/2 transform -translate-y-1/2 text-blue-400 w-5 h-5" />
        </div>
      </div> 
      <div className="relative text-center">
        <div className={`transform transition-all duration-1000 ${
          isVisible ? 'translate-y-0 opacity-100' : 'translate-y-10 opacity-0'
        }`}>
          <h1 className="text-5xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-blue-600 to-purple-600 mb-2 tracking-tight">
            Our Services
          </h1>
          <div className="relative inline-block">
            <span className="absolute -inset-1 bg-gradient-to-r from-blue-600 to-purple-600 rounded-lg blur opacity-25"></span>
            <span className="relative text-lg text-gray-600">
              Discover Our Professional Solutions
            </span>
          </div>
        </div>
 
        <div className={`h-1 w-24 bg-gradient-to-r from-blue-600 to-purple-600 mx-auto mt-4 rounded-full transition-all duration-1000 ${
          isVisible ? 'w-24 opacity-100' : 'w-0 opacity-0'
        }`} />
      </div>
    </div>
  );
};

export default ServicesHeader;
import React, { useState } from "react";
import { useSearchParams } from "react-router-dom";
import { GoEyeClosed } from "react-icons/go";
import { GoEye } from "react-icons/go";
import Core from "../core/Core";
import { toast } from "react-toastify";

const ForgetPassword = () => {
  const notify = (text, time) => {
    toast.dismiss();

    toast.info(text, {
      toastId: "random1",
      closeButton: false,
      position: "top-center",
      autoClose: time ? time : 1500,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      style: {
        // width: "400px",
        // maxWidth: "90vw",
        padding: "15px 20px",
        fontSize: "16px",
        textAlign: "center",
        fontWeight: "500",
      },
    });
  };
  const [searchParams] = useSearchParams();

  const token = searchParams.get("token");
  const { handleNewPass } = Core();

  const [formData, setFormData] = useState({
    newPassword: "",
    cNewPassword: "",
  });

  const [showPassword , setShowPassword] = useState(false);
  const [showCnfPassword , setShowCnfPassword] = useState(false);

  

  const [errors, setErrors] = useState({
    newPassword: "",
    cNewPassword: "",
  });

  const validateForm = () => {
    let isValid = true;
    const newErrors = {
      newPassword: "",
      cNewPassword: "",
    };

    if (!formData.newPassword) {
      newErrors.newPassword = "Password is required";
      isValid = false;
    } else if (formData.newPassword.length < 6) {
      newErrors.newPassword = "Password must be at least 6 characters";
      isValid = false;
    }

    if (!formData.cNewPassword) {
      newErrors.cNewPassword = "Confirm password is required";
      isValid = false;
    } else if (formData.newPassword !== formData.cNewPassword) {
      newErrors.cNewPassword = "Passwords do not match";
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!token) {
      notify("Invalid or missing token", 2000);
      return;
    }

    if (validateForm()) {
      const data = {
        newPassword: formData.newPassword,
        cNewPassword: formData.cNewPassword,
      };

      try {
        await handleNewPass(data, token);
      } catch (error) {
        notify("Error resetting password. Please try again.", 2000);
      }
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  return (
    <div className="min-h-screen flex items-start justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full space-y-8">
        <div>
          <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
            Reset Password
          </h2>
        </div>
        <form className="mt-8 space-y-6" onSubmit={handleSubmit}>
          <div className="space-y-4">
            <div>
              <label
                htmlFor="newPassword"
                className="block text-sm font-medium text-gray-700"
              >
                New Password
              </label>
              <div className="mt-1 relative">
                <input
                  id="newPassword"
                  name="newPassword"
                  type={showPassword ? "text" : "password"}
                  value={formData.newPassword}
                  onChange={handleChange}
                  className="appearance-none relative focus:border-2 block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500 focus:z-10 sm:text-sm"
                  placeholder="Enter new password"
                  style={{border:"1px solid black"}}
                />
                <button
                  type="button"
                  onClick={() => setShowPassword(!showPassword)}
                  className="absolute inset-y-0 right-0 pr-3 flex items-center z-50"
                >
                  {showPassword ? (
                    <GoEyeClosed className="h-5 w-5 text-black" />
                  ) : (
                    <GoEye className="h-5 w-5 text-black" />
                  )}
                </button>
              </div>
              {errors.newPassword && (
                <p className="mt-1 text-sm text-red-600">
                  {errors.newPassword}
                </p>
              )}
            </div>

            <div>
              <label
                htmlFor="cNewPassword"
                className="block text-sm font-medium text-gray-700"
              >
                Confirm New Password
              </label>
              <div className="mt-1 relative">
                <input
                  id="cNewPassword"
                  name="cNewPassword"
                  type={showCnfPassword ? "text" : "password"}
                  value={formData.cNewPassword}
                  onChange={handleChange}
                  className="appearance-none relative focus:border-2 block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500 focus:z-10 sm:text-sm"
                  style={{border:"1px solid black"}}
                  placeholder="Confirm new password"
                />
                <button
                  type="button"
                  onClick={() => setShowCnfPassword (!showCnfPassword)}
                  className="absolute inset-y-0 right-0 pr-3 flex items-center z-50 text-black"
                >
                  {showCnfPassword ? (
                    <GoEyeClosed className="h-5 w-5 text-black" />
                  ) : (
                    <GoEye className="h-5 w-5 text-black" />
                  )}
                </button>
              </div>
              {errors.cNewPassword && (
                <p className="mt-1 text-sm text-red-600">
                  {errors.cNewPassword}
                </p>
              )}
            </div>
          </div>

          <div>
            <button
              type="submit"
              className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
              Reset Password
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ForgetPassword;
//
import React, { useState, useContext, useRef, useEffect } from "react";
import { IoMdClose } from "react-icons/io";
import AppContext from "../AppContext/AppContext";
import Core from "../core/Core";
import { toast } from "react-toastify";
import UpdatePassword from "./UpdatePass";

const UpdateProfile = ({ showUpdateProfile, setShowUpdateProfile }) => {
  const appContext = useContext(AppContext);
  const { handleSetProfile, handleUpdatePass } = Core();

  const notify = (text, time) => {
    toast.dismiss();

    toast.info(text, {
      toastId: "random1",
      closeButton: false,
      position: "top-center",
      autoClose: time ? time : 1500,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      style: {
        // width: "400px",
        // maxWidth: "90vw",
        padding: "15px 20px",
        fontSize: "16px",
        textAlign: "center",
        fontWeight: "500",
      },
    });
  };

  const updateProfRef = useRef(null);
  const [showUpdatePassword, setShowUpdatePassword] = useState(false);
  const [formData, setFormData] = useState({
    firstName: "",
    email: "",
    firmName: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (["firstName", "email", "firmName"].includes(name)) {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // if (formData.firstName.trim().split(" ").length < 3) {
    //   notify("Name must be greater than three words", 2000);
    //   return;
    // }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(formData.email.trim())) {
      notify("Invalid email", 2000);
      return;
    }

    const profileData = {
      firstName: formData.firstName.trim(),
      email: formData.email.trim(),
      ...(appContext.userInfoVal.accountType === "member" && {
        firmName: formData.firmName.trim(),
      }),
    };

    handleSetProfile(profileData, setShowUpdateProfile);
  };

  useEffect(() => {
    const handleClose = (e) => {
      if (updateProfRef.current && !updateProfRef.current.contains(e.target)) {
        setShowUpdateProfile(false);
      }
    };

    document.addEventListener("mousedown", handleClose);
    return () => {
      document.removeEventListener("mousedown", handleClose);
    };
  }, [updateProfRef]);

  return (
    <div className="flex justify-center items-center absolute top-0 h-full bg-black/50 w-full z-[1000] left-0 ">
      <div
        ref={updateProfRef}
        className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-[4%]"
      >
        <div className="bg-white rounded-lg w-full max-w-md relative">
          <div className="p-[2%] border-b">
            <h2 className="text-xl font-semibold">Update Profile</h2>
            <button
              onClick={() => {
                setShowUpdateProfile(false);
              }}
              className="cursor-pointer text-2xl absolute rounded-tr-md top-0 right-0 px-2 bg-slate-600 text-white flex justify-center items-center hover:bg-slate-700"
            >
              <IoMdClose />
            </button>
          </div>

          <form onSubmit={handleSubmit} className="p-4">
            <div className="space-y-4">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Name
                </label>
                <input
                  type="text"
                  name="firstName"
                  value={formData.firstName}
                  onChange={handleInputChange}
                  className="w-full px-3 py-2 border text-base font-semibold border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500"
                  placeholder="Enter your name"
                  style={{border:"1px solid black"}}
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Email
                </label>
                <input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleInputChange}
                  className="w-full px-3 py-2 border text-base font-semibold border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500"
                  placeholder="Enter your email"
                  style={{border:"1px solid black"}}
                />
              </div>

              {appContext.userInfoVal.accountType === "member" && (
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Firm Name
                  </label>
                  <input
                    type="text"
                    name="firmName"
                    value={formData.firmName}
                    onChange={handleInputChange}
                    className="w-full px-3 py-2 border text-base font-semibold border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500"
                    placeholder="Enter your firm name"
                  />
                </div>
              )}
            </div>
            <div className="flex justify-between items-center mt-6">
              <button
                type="button"
                className="text-blue-500 hover:text-blue-600 font-medium"
                onClick={() => setShowUpdatePassword(true)}
              >
                Update Password
              </button>

              <button
                type="submit"
                className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 font-medium"
              >
                Update Profile
              </button>
            </div>
          </form>
        </div>
        {showUpdatePassword && (
          <UpdatePassword
            showUpdatePassword={showUpdatePassword}
            setShowUpdatePassword={setShowUpdatePassword}
            setShowUpdateProfile = {setShowUpdateProfile}
          />
        )}
      </div>
    </div>
  );
};

export default UpdateProfile;

import React, { useState, useEffect, useRef } from "react";
import "./App.css";
import Landing from "./Landing";
import AppContext from "./AppContext/AppContext";
import Navbar from "./components/Navbar";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useAsyncError,
} from "react-router-dom";
import LoginWrapper from "./components/LoginWrapper";
import About from "./components/About";
import Cards from "./components/Cards";
import ContactUs from "./components/ContactUs";
import Notification from "./components/Notification";
import Footer from "./components/Footer";
import ProfileSection from "./components/ProfileSection";
import UpdateProfile from "./components/UpdateProfile";
import ProductDetail from "./components/ProductDetails";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AllServices from "./components/AllServices";
import Dashboard from "./componentsAdmin/Dashboard";
import NavbarAdmin from "./componentsAdmin/NavbarAdmin";
import ManageOrders from "./componentsAdmin/ManageOrders";
import ManageProducts from "./componentsAdmin/ManageProducts";
import ManageUsers from "./componentsAdmin/ManageUsers";
import LoginSignup from "./components/LoginSignup";
import Loader from "./components/Loader";
import Payment from "./components/Payment";
import ApplyNow from "./components/ApplyNow";
import AddWallet from "./components/AddWallet";
import AddProduct from "./componentsAdmin/AddProduct";
import UpdateProduct from "./componentsAdmin/UpdateProduct";
import ForgetPassword from "./components/ForgetPassword";
import ContactData from "./componentsAdmin/ContactData";

const local_storagePrefences = localStorage.getItem("PrefrenceVal");

function App() {
  // const appContext = useContext(AppContext);

  const [signinClicked, setSigninClicked] = useState(false);
  const [isLoggedIn, setISsLoggedIn] = useState(false);
  const [userSignUp, setUserSignUp] = useState(false);
  const [memberSignUp, setMemberSignUp] = useState(false);
  const [isSignup, setIsSignup] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const [failedNoti, setFailedNoti] = useState(false);
  const [showGlobalLoader, setShowGlobalLoader] = useState(false);
  const [showAddWallet, setShowAddeWallet] = useState(false);
  const [selectedProd, setSeletecedProd] = useState(null);
  const [createdSuccess, setCreatedSuccess] = useState(false);
  const [isClickedAccount, setIsClickedAccount] = useState(true);
  const [isClickedBilling, setIsClickedBilling] = useState(false);
  const [isClickedSupport, setIsClickedSupport] = useState(false);
  const [isWalletClicked, setIsWalletClicked] = useState(false);

  const navRef = useRef(null);

  const [userInfo, setUserInfo] = useState(
    local_storagePrefences != null
      ? JSON.parse(localStorage.getItem("PrefrenceVal")).userInfoVal
      : {
          firstName: "rkcsc",
          phoneNumber: null,
          email: "noreply@rkcsc.in",
          picture: "https://picsum.photos/400/400",
          username: null,
          accountType: null,
          wallet: null,
          firmName: null,
          orders: [],
          walletHistory: [],
          userId: null,
          sessionId: null,
        }
  );

  const [productInfo, setProductInfo] = useState(
    local_storagePrefences != null
      ? JSON.parse(localStorage.getItem("PrefrenceVal")).productInfoVal
      : {
          description: "rkcsc",
          image: "https://picsum.photos/400/400",
          member_price: "100",
          price: "100",
          processFlow: {},
          productName: "Pan",
          requiredDocs: {},
          _id: null,
        }
  );

  const preferencesVal = {
    userInfoVal: userInfo,
    setUserInfo,
    productInfoVal: productInfo,
    setProductInfo,
  };

  useEffect(() => {
    localStorage.setItem("PrefrenceVal", JSON.stringify(preferencesVal));
  }, [preferencesVal]);

  const ProtectedAdminRoute = ({ children }) => {
    const appContext = React.useContext(AppContext);

    return appContext.userInfoVal.accountType === "admin" ||
      appContext.userInfoVal.accountType === "pseudoAdmin" ? (
      children
    ) : (
      <Navigate to="/" replace />
    );
  };

  const StrictAdminRoute = ({ children }) => {
    const appContext = React.useContext(AppContext);
    return appContext.userInfoVal.accountType === "admin" ? (
      children
    ) : (
      <Navigate to="/adminRk/orders" replace />
    );
  };

  return (
    <>
      <AppContext.Provider value={preferencesVal}>
        <Router>
          <Routes>
            <Route
              path="/adminRk/*"
              element={
                <ProtectedAdminRoute>
                  <NavbarAdmin />
                  <Routes>
                    <Route
                      path=""
                      element={
                        <StrictAdminRoute>
                          <Dashboard />
                        </StrictAdminRoute>
                      }
                    />
                    <Route
                      path="products"
                      element={
                        <StrictAdminRoute>
                          <ManageProducts
                            selectedProd={selectedProd}
                            setSeletecedProd={setSeletecedProd}
                            createdSuccess={createdSuccess}
                            setCreatedSuccess={setCreatedSuccess}
                          />
                        </StrictAdminRoute>
                      }
                    />
                    <Route
                      path="products/addproduct"
                      element={
                        <StrictAdminRoute>
                          <AddProduct
                            selectedProd={selectedProd}
                            setSeletecedProd={setSeletecedProd}
                            createdSuccess={createdSuccess}
                            setCreatedSuccess={setCreatedSuccess}
                          />
                        </StrictAdminRoute>
                      }
                    />
                    <Route
                      path="products/updateproduct"
                      element={
                        <StrictAdminRoute>
                          <UpdateProduct
                            setSeletecedProd={setSeletecedProd}
                            selectedProd={selectedProd}
                          />
                        </StrictAdminRoute>
                      }
                    />
                    <Route
                      path="users"
                      element={
                        <StrictAdminRoute>
                          <ManageUsers />
                        </StrictAdminRoute>
                      }
                    />
                    <Route
                      path="contactData"
                      element={
                        <StrictAdminRoute>
                          <ContactData />
                        </StrictAdminRoute>
                      }
                    />
                    <Route path="orders" element={<ManageOrders />} />
                  </Routes>
                </ProtectedAdminRoute>
              }
            />
            <Route
              path="*"
              element={
                <>
                  <Navbar
                    navRef={navRef}
                    signinClicked={signinClicked}
                    setSigninClicked={setSigninClicked}
                    isLoggedIn={isLoggedIn}
                    setISsLoggedIn={setISsLoggedIn}
                    userSignUp={userSignUp}
                    setUserSignUp={setUserSignUp}
                    memberSignUp={memberSignUp}
                    setMemberSignUp={setMemberSignUp}
                    setIsSignup={setIsSignup}
                    isSignup={isSignup}
                    isClickedAccount={isClickedAccount}
                    setIsClickedAccount={setIsClickedAccount}
                    isClickedBilling={isClickedBilling}
                    setIsClickedBilling={setIsClickedBilling}
                    isClickedSupport={isClickedSupport}
                    setIsClickedSupport={setIsClickedSupport}
                    isWalletClicked={isWalletClicked}
                    setIsWalletClicked={setIsWalletClicked}
                  />
                  {showGlobalLoader && <Loader />}

                  {signinClicked && (
                    <LoginWrapper
                      navRef={navRef}
                      setSigninClicked={setSigninClicked}
                      setUserSignUp={setUserSignUp}
                      userSignUp={userSignUp}
                      signinClicked={signinClicked}
                      memberSignUp={memberSignUp}
                      setMemberSignUp={setMemberSignUp}
                      setIsSignup={setIsSignup}
                      isSignup={isSignup}
                      setShowGlobalLoader={setShowGlobalLoader}
                      showGlobalLoader={showGlobalLoader}
                      setShowNotification={setShowNotification}
                      setFailedNoti={setFailedNoti}
                    />
                  )}
                  <Routes>
                    <Route
                      path="/"
                      element={
                        <Landing
                          navRef={navRef}
                          signinClicked={signinClicked}
                          setSigninClicked={setSigninClicked}
                          isLoggedIn={isLoggedIn}
                          setISsLoggedIn={setISsLoggedIn}
                          userSignUp={userSignUp}
                          setUserSignUp={setUserSignUp}
                          memberSignUp={memberSignUp}
                          setMemberSignUp={setMemberSignUp}
                          setIsSignup={setIsSignup}
                          isSignup={isSignup}
                          setShowNotification={setShowNotification}
                          setFailedNoti={setFailedNoti}
                          setShowAddeWallet={setShowAddeWallet}
                          showAddWallet={showAddWallet}
                        />
                      }
                    />
                    <Route path="/about" element={<About />} />
                    <Route path="/services" element={<AllServices />} />
                    <Route
                      path="/contact"
                      element={<ContactUs showOtherDetails={true} />}
                    />
                    <Route
                      path="/profile"
                      element={
                        <ProfileSection
                          setShowAddeWallet={setShowAddeWallet}
                          showAddWallet={showAddWallet}
                          isClickedAccount={isClickedAccount}
                          setIsClickedAccount={setIsClickedAccount}
                          isClickedBilling={isClickedBilling}
                          setIsClickedBilling={setIsClickedBilling}
                          isClickedSupport={isClickedSupport}
                          setIsClickedSupport={setIsClickedSupport}
                          isWalletClicked={isWalletClicked}
                          setIsWalletClicked={setIsWalletClicked}
                        />
                      }
                    />

                    <Route
                      path="/product"
                      element={
                        <ProductDetail setSigninClicked={setSigninClicked} />
                      }
                    />
                    <Route
                      path="/payment"
                      element={
                        <Payment
                          setIsClickedAccount={setIsClickedAccount}
                          setIsClickedBilling={setIsClickedBilling}
                          setIsClickedSupport={setIsClickedSupport}
                          setIsWalletClicked={setIsWalletClicked}
                        />
                      }
                    />
                    <Route
                      path="/forgot-password"
                      element={<ForgetPassword />}
                    />
                    <Route
                      path="/checkout"
                      element={
                        <ApplyNow
                          setShowAddeWallet={setShowAddeWallet}
                          showAddWallet={showAddWallet}
                          setSigninClicked={setSigninClicked}
                        />
                      }
                    />
                  </Routes>
                  <Footer />
                  {showAddWallet && (
                    <AddWallet
                      setShowAddeWallet={setShowAddeWallet}
                      showAddWallet={showAddWallet}
                    />
                  )}
                </>
              }
            />
          </Routes>
        </Router>
      </AppContext.Provider>
      <ToastContainer limit={1} />
    </>
  );
}

export default App;

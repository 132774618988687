import React, { useState, useEffect } from "react";
import { Phone, Mail, MapPin } from "lucide-react";
import Core from "../core/Core";
import SocialICons from "./SocialIcons";

const ContactUs = ({ showOtherDetails }) => {
  const { handleContactData } = Core();

  const [formData, setFormData] = useState({
    First_name: "",
    Last_name: "",
    email: "",
    mobile: "",
    message: "",
  });

  const [errors, setErrors] = useState({
    email: "",
    mobile: "",
  });

  const [isSubmitting, setIsSubmitting] = useState(false);

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!email) {
      return "Email is required";
    }
    if (!emailRegex.test(email)) {
      return "Please enter a valid email address";
    }
    return "";
  };

  const validatePhone = (phone) => {
    const phoneRegex = /^[0-9]{10}$/;
    if (!phone) {
      return "Phone number is required";
    }
    if (!phoneRegex.test(phone)) {
      return "Please enter a valid 10-digit phone number";
    }
    return "";
  };

  const handleInputChange = (field, value) => {
    setFormData({ ...formData, [field]: value });
    
    setErrors({ ...errors, [field]: "" });
    
    if (field === 'email') {
      setErrors(prev => ({ ...prev, email: validateEmail(value) }));
    }
    if (field === 'mobile') {
      setErrors(prev => ({ ...prev, mobile: validatePhone(value) }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const emailError = validateEmail(formData.email);
    const phoneError = validatePhone(formData.mobile);

    setErrors({
      email: emailError,
      mobile: phoneError,
    });

    if (emailError || phoneError) {
      return;
    }

    setIsSubmitting(true);

    try {
      const formattedData = {
        ...formData,
        mobile: Number(formData.mobile),
      };

      await handleContactData(formattedData);

      setFormData({
        First_name: "",
        Last_name: "",
        email: "",
        mobile: "",
        message: "",
      });
      setErrors({ email: "", mobile: "" });
    } catch (error) {
      console.error("Error submitting form:", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const ErrorMessage = ({ message }) => (
    message ? <p className="text-red-500 text-sm mt-1">{message}</p> : null
  );


  return (
    <div
      className={`max-w-7xl mx-auto px-4  ${showOtherDetails ? "py-12 " : ""}`}
    >
      <div className="grid md:grid-cols-2 gap-12 items-start">
        {showOtherDetails ? (
          <div className="space-y-8 transform transition duration-500 hover:scale-[1.02]">
            <div>
              <h2 className="text-3xl font-bold text-blue-600 mb-6 animate-fade-in">
                Get in Touch
              </h2>
              <p className="text-gray-600 mb-8">
                We'd love to hear from you. Please fill out the form or contact
                us using the information below.
              </p>
            </div>

            <div className="space-y-4">
              {[
                {
                  icon: MapPin,
                  title: "Address",
                  content:
                    "Pantnagar Railway Station, U.S Nagar, Uttarakhand, India",
                },
                {
                  icon: Phone,
                  title: "Phone",
                  content: "8445522551",
                  ref: "tel",
                },
                {
                  icon: Mail,
                  title: "Email",
                  content: "rkconsultancy.csc@gmail.com",
                  ref: "",
                },
              ].map((item, index) => (
                <div
                  key={item.title}
                  className="flex items-center space-x-4 p-4 rounded-lg hover:bg-blue-50 transition-all duration-300 transform hover:-translate-y-1"
                  style={{ animationDelay: `${index * 150}ms` }}
                >
                  <item.icon className="text-blue-600 w-6 h-6" />
                  <div>
                    <h3 className="font-semibold">{item.title}</h3>
                    <a href="" className="text-gray-600 whitespace-pre-line">
                      {item.content}
                    </a>
                  </div>
                </div>
              ))}
            </div>

            <div className="flex flex-wrap space-x-5 mt-5">
              <SocialICons />
            </div>
          </div>
        ) : (
          <div className="space-y-8 p-0 md:pt-32  transform transition flex flex-col justify-center items-center duration-500 hover:scale-[1.02]">
            <div>
              <h2 className="text-5xl font-bold text-blue-600 mb-6 animate-fade-in">
              Need Support?
              </h2>
              <p className="text-gray-600 mb-8">
                We'd love to hear from you. Please fill out the form or contact
                us using the information below.
              </p>
            </div>
          </div>
        )}

        <div className="bg-white rounded-lg shadow-2xl p-6 transform transition duration-500 hover:shadow-2xl">
          <form onSubmit={handleSubmit} className="space-y-6">
            {/* Name fields remain unchanged */}
            <div className="grid grid-cols-2 gap-4">
              <div className="transform transition-all duration-300">
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  First Name
                </label>
                <input
                  type="text"
                  value={formData.First_name}
                  onChange={(e) => handleInputChange("First_name", e.target.value)}
                  className="w-[95%] px-4 rounded-lg text-black py-2 focus:ring-blue-500 focus:border-blue-500 transition-all duration-300 hover:border-blue-400"
                  required
                  style={{ border: "1.5px solid black" }}
                />
              </div>
              <div className="transform transition-all duration-300">
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Last Name
                </label>
                <input
                  type="text"
                  value={formData.Last_name}
                  onChange={(e) => handleInputChange("Last_name", e.target.value)}
                  className="w-[95%] px-4 rounded-lg text-black py-2 focus:ring-blue-500 focus:border-blue-500 transition-all duration-300 hover:border-blue-400"
                  required
                  style={{ border: "1.5px solid black" }}
                />
              </div>
            </div>

            {showOtherDetails ? (
              <>
                <div className="transform transition-all duration-300">
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Email Address
                  </label>
                  <input
                    type="email"
                    value={formData.email}
                    onChange={(e) => handleInputChange("email", e.target.value)}
                    className={`w-[95%] px-4 rounded-lg py-2 focus:ring-blue-500 focus:border-blue-500 transition-all duration-300 hover:border-blue-400 ${
                      errors.email ? "border-red-500" : ""
                    }`}
                    required
                    style={{ border: errors.email ? "1.5px solid #ef4444" : "1.5px solid black" }}
                  />
                  <ErrorMessage message={errors.email} />
                </div>

                <div className="transform transition-all duration-300">
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Mobile Number
                  </label>
                  <input
                    type="tel"
                    value={formData.mobile}
                    onChange={(e) => handleInputChange("mobile", e.target.value)}
                    className={`w-[95%] px-4 rounded-lg py-2 focus:ring-blue-500 focus:border-blue-500 transition-all duration-300 hover:border-blue-400 ${
                      errors.mobile ? "border-red-500" : ""
                    }`}
                    required
                    style={{ border: errors.mobile ? "1.5px solid #ef4444" : "1.5px solid black" }}
                  />
                  <ErrorMessage message={errors.mobile} />
                </div>
              </>
            ) : (
              <div className="grid grid-cols-2 gap-4">
                <div className="transform transition-all duration-300">
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Email Address
                  </label>
                  <input
                    type="email"
                    value={formData.email}
                    onChange={(e) => handleInputChange("email", e.target.value)}
                    className={`w-[95%] px-4 text-black rounded-lg py-2 focus:ring-blue-500 focus:border-blue-500 transition-all duration-300 hover:border-blue-400 ${
                      errors.email ? "border-red-500" : ""
                    }`}
                    required
                    style={{ border: errors.email ? "1.5px solid #ef4444" : "1.5px solid black" }}
                  />
                  <ErrorMessage message={errors.email} />
                </div>

                <div className="transform transition-all duration-300">
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Mobile Number
                  </label>
                  <input
                    type="tel"
                    value={formData.mobile}
                    onChange={(e) => handleInputChange("mobile", e.target.value)}
                    className={`w-[95%] px-4 rounded-lg text-black py-2 focus:ring-blue-500 focus:border-blue-500 transition-all duration-300 hover:border-blue-400 ${
                      errors.mobile ? "border-red-500" : ""
                    }`}
                    required
                    style={{ border: errors.mobile ? "1.5px solid #ef4444" : "1.5px solid black" }}
                  />
                  <ErrorMessage message={errors.mobile} />
                </div>
              </div>
            )}

            <div className="transform transition-all duration-300">
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Message
              </label>
              <textarea
                rows={3}
                value={formData.message}
                onChange={(e) => handleInputChange("message", e.target.value)}
                className="w-full px-4 py-2 text-black border border-gray-500 rounded-md focus:ring-blue-500 focus:border-blue-500 transition-all duration-300 hover:border-blue-400"
                required
              />
            </div>

            <button
              type="submit"
              disabled={isSubmitting}
              className={`w-full bg-blue-600 text-white py-2 px-4 rounded-md transition-all duration-300 transform hover:scale-[1.02] hover:bg-blue-700 ${
                isSubmitting ? "opacity-75 cursor-not-allowed" : ""
              }`}
            >
              {isSubmitting ? "Sending..." : "Send Message"}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;

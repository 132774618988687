import React, { useEffect, useState, useRef, useContext } from "react";
import axios from "axios";
import AppContext from "../AppContext/AppContext";
import { toast } from "react-toastify";
import { data, useNavigate } from "react-router-dom";

const Core = () => {
  const appContext = useContext(AppContext);
  const navigate = useNavigate();

  const notify = (text, time) => {
    toast.dismiss();

    toast.info(text, {
      toastId: "random1",
      closeButton: false,
      position: "top-center",
      autoClose: time ? time : 1500,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      style: {
        // width: "400px",
        // maxWidth: "90vw",
        padding: "15px 20px",
        fontSize: "16px",
        textAlign: "center",
        fontWeight: "500",
      },
    });
  };

  const loginEmailPass = async (
    email,
    password,
    setSigninClicked,
    setShowGlobalLoader
  ) => {
    // console.log("Login attempt started");
    setShowGlobalLoader(true);

    if (!email || !password) {
      console.error("Please enter both email and password");
      return;
    }

    const data = {
      email: email,
      password: password,
    };

    // console.log("data", data);

    try {
      const response = await axios({
        method: "post",
        url: process.env.REACT_APP_BASE_URL + "/api/v1/auth/login",
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      });

      // console.log("Full login response:", response);

      if (response.data.data) {
        // console.log("Login successful:", response.data.data);
        const userDataresp = await getUserData(
          response.data?.data?.accessToken
        );

        if (userDataresp) {
          appContext.setUserInfo((prev) => ({
            ...prev,
            firstName: userDataresp?.userData?.firstName,
            phoneNumber: userDataresp?.userData?.phoneNumber,
            email: userDataresp?.userData?.email,
            picture: userDataresp?.userData?.photoUrl,
            username: userDataresp?.userData?.username,
            accountType: userDataresp?.userData?.accountType,
            orders: userDataresp?.orderData,
            userId: userDataresp?.userData?._id,
            sessionId: response.data?.data?.accessToken,
          }));

          if (userDataresp?.userData?.accountType === "member") {
            appContext.setUserInfo((prev) => ({
              ...prev,
              wallet: userDataresp?.userData?.wallet,
              firmName: userDataresp?.userData?.firmName,
              walletHistory: userDataresp?.walletData,
            }));
          }

          if (userDataresp?.userData?.accountType === "admin") {
            navigate("/adminRk");
            handleDashBoeard(response.data?.data?.accessToken);
            notify("Admin Login Successfully", 2000);
          }
        }
        if (userDataresp?.userData?.accountType === "pseudoAdmin") {
          navigate("/adminRk/orders");
          notify("Pseudo Admin Login Successfully", 2000);
        }

        if (userDataresp?.userData?.accountType !== "admin") {
          notify("Login Successfully", 2000);
        }
        setSigninClicked(false);
        setShowGlobalLoader(false);
      }
    } catch (error) {
      console.error("Login Error:", {
        message: error.message,
        response: error.response ? error.response.data : "No response",
        status: error.response ? error.response.status : "No status",
      });

      if (error.response) {
        if (error.response.data.message === "Wrong Password!") {
          notify("Wrong Password!", 2000);
        } else if (
          error.response.data.message === "Email or username doesn't exist"
        ) {
          notify(error.response.data.message, 2000);
        }
        if (
          error.response.data.message ===
          "Email or username doesn't exist or not verified"
        ) {
          notify(error.response.data.message, 2000);
        }
      } else if (error.request) {
        console.error("No response received from server");
      } else {
        console.error("Error setting up login request:", error.message);
      }
    } finally {
      setShowGlobalLoader(false);
    }
  };

  const signupEmailPass = async (
    firstName,
    phoneNumber,
    email,
    password,
    firmName,
    docs,
    setSigninClicked,
    setShowGlobalLoader
  ) => {
    setShowGlobalLoader(true);

    if (!firstName || !phoneNumber || !email || !password) {
      notify("Please fill in all required fields",2000);
      return null;
    }

    if(firstName && firstName.length < 3) {
      notify("Name should be more than 3 character",2000);
      setShowGlobalLoader(false);
      return null;
    }

    if(firmName &&firmName.length < 3) {
      notify("Firm Name should be more than 3 character",2000);
      setShowGlobalLoader(false);
      return null;
    }

    if(password && password.length < 6) {
      notify("Password should be more than 3 character",2000);
      setShowGlobalLoader(false);
      return null;
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      notify("Invalid email format");
      setShowGlobalLoader(false);
      return null;
    }

    const phoneRegex = /^[0-9]{10}$/;
    if (!phoneRegex.test(phoneNumber)) {
      notify("Invalid phone number");
      setShowGlobalLoader(false);
      return null;
    }

    const formData = new FormData();

    formData.append("firstName", firstName);
    formData.append("phone", phoneNumber);
    formData.append("email", email);
    formData.append("password", password);

    if (firmName) {
      formData.append("firmName", firmName);
    }

    if (docs && docs instanceof File) {
      formData.append("file", docs);
    } else if (docs && typeof docs === "string") {
      const fileBlob = dataURItoBlob(docs);
      formData.append("file", fileBlob, "document.pdf");
    }

    const userType = firmName && docs ? "member" : "user";

    try {
      const response = await axios({
        method: "post",
        url: process.env.REACT_APP_BASE_URL + `/api/v1/auth/signup/${userType}`,
        headers: {
          "Content-Type": "multipart/form-data",
        },
        data: formData,
      });

      // console.log("Full signup response:", response);

      if (response.data) {
        console.log("Signup response data:", response.data);

        if (response.data.data?.accessToken) {
          // console.log("User successfully signed up with access token");
          const userDataresp = await getUserData(
            response.data?.data?.accessToken
          );
          console.log("LOG USER DATA: ", userDataresp.userData);

          if (userDataresp) {
            appContext.setUserInfo((prev) => ({
              ...prev,
              firstName: userDataresp?.userData?.firstName,
              phoneNumber: userDataresp?.userData?.phoneNumber,
              email: userDataresp?.userData?.email,
              picture: userDataresp?.userData?.photoUrl,
              username: userDataresp?.userData?.username,
              accountType: userDataresp?.userData?.accountType,
              orders: userDataresp?.orderData,
              userId: userDataresp?.userData?._id,
              sessionId: response.data?.data?.accessToken,
            }));
          }

          setSigninClicked(false);
          setShowGlobalLoader(false);
          notify("Signed up successfully!", 2000);
        } else if (response.data.data) {
          // console.log("Here is the payment link", response.data.data);
          const paymentUrl = response.data.data;
          setShowGlobalLoader(false);

          window.location.href = paymentUrl;
          setSigninClicked(false);
        } else {
          // console.warn("Unexpected signup response structure");
        }
      } else {
        // console.error("No data in signup response");
      }
    } catch (error) {
      console.error("Signup Error:", {
        message: error.message,
        response: error.response ? error.response.data : "No response",
        status: error.response ? error.response.status : "No status",
      });
      if (
        error.response.data.message &&
        error.response.data.message.includes(
          "account already exists with phoneNumber"
        )
      ) {
        notify("Account already exists with this phoneNumber", 2000);
      }
      if (
        error.response.data.message &&
        error.response.data.message.includes(
          "account already exists with email"
        )
      ) {
        notify("Account already exists with this email", 2000);
      }

      if (error.response) {
        // console.error("Bad Request: ", error.response.data.message);
      } else if (error.request) {
        // console.error("No response received from server during signup");
      } else {
        // console.error("Error setting up signup request:", error.message);
      }
    } finally {
      setShowGlobalLoader(false);
    }
  };

  function dataURItoBlob(dataURI) {
    let byteString;
    if (dataURI.split(",")[0].indexOf("base64") >= 0)
      byteString = atob(dataURI.split(",")[1]);
    else byteString = unescape(dataURI.split(",")[1]);

    const mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];

    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ab], { type: mimeString });
  }

  const handleLogout = () => {
    appContext.setUserInfo((prev) => ({
      ...prev,
      firstName: "rkcsc",
      phoneNumber: null,
      email: "noreply@rkcsc.in",
      picture: "https://picsum.photos/400/400",
      username: null,
      accountType: null,
      wallet: null,
      firmName: null,
      orders: [],
      userId: null,
      sessionId: null,
    }));

    navigate("/");
  };

  const getUserData = async (sessionId) => {
    let config = {
      method: "GET",
      maxBodyLength: Infinity,
      url: process.env.REACT_APP_BASE_URL + `/api/v1/user/get_profile`,
      headers: {
        Authorization: "Bearer " + sessionId,
      },
    };
    try {
      const response = await axios.request(config);
      if (response.data?.data) {
        return response.data.data;
      }
    } catch (error) {
      console.error("Error:", error);
      if (
        error.response.data.message &&
        error.response.data.message.includes("jwt expired")
      ) {
        notify("Your login session Expired. Please login again.", 2000);
        handleLogout();
      }
      return null;
    }
  };

  const getUserDataAdmin = async (userId) => {
    let config = {
      method: "GET",
      maxBodyLength: Infinity,
      url: process.env.REACT_APP_BASE_URL + `/api/v1/user/get_profile?_id=${userId}`,
      headers: {
        Authorization: "Bearer " + appContext.userInfoVal.sessionId,
      },
    };
    try {
      const response = await axios.request(config);
      if (response.data?.data) {
        return response.data.data;
      }
    } catch (error) {
      console.error("Error:", error);
      if (
        error.response.data.message &&
        error.response.data.message.includes("jwt expired")
      ) {
        notify("Your login session Expired. Please login again.", 2000);
        handleLogout();
      }
      return null;
    }
  };

  //Admin Endpoints

  const getAllUsers = async (userType) => {
    // console.log("all user called with", userType);

    let config = {
      method: "GET",
      maxBodyLength: Infinity,
      url:
        process.env.REACT_APP_BASE_URL + `/api/v1/admin/getAllUser/${userType}`,
      headers: {
        Authorization: `Bearer ${appContext.userInfoVal.sessionId}`,
      },
    };
    try {
      const response = await axios.request(config);
      if (response.data?.data) {
        // console.log("all user response",response.data?.data);
        return response.data?.data;
      }
    } catch (error) {
      console.error("Error:", error);
      if (
        error.response.data.message &&
        error.response.data.message.includes("jwt expired")
      ) {
        notify("Your login session Expired. Please login again.", 2000);
        handleLogout();
      }
      return null;
    }
  };

  const deleteAndVerify = async (userId, action) => {
    console.log("all user called with", userId, action);

    let config = {
      method: "GET",
      maxBodyLength: Infinity,
      url:
        process.env.REACT_APP_BASE_URL +
        `/api/v1/admin/deleteUser/${userId}/${action}`,
      headers: {
        Authorization: `Bearer ${appContext.userInfoVal.sessionId}`,
      },
    };
    try {
      const response = await axios.request(config);
      if (response.data?.data) {
        return response.data?.data;
      }
    } catch (error) {
      console.error("Error:", error);
      return null;
    }
  };

  const getAdminAllOrders = async (
    paymentFilter = null,
    orderFilter = "All"
  ) => {
    try {
      // Prepare query parameters
      const queryParams = new URLSearchParams();

      if (paymentFilter) {
        queryParams.append("payment", paymentFilter);
      }

      if (orderFilter !== "All") {
        queryParams.append("order", orderFilter);
      }

      const url = `${process.env.REACT_APP_BASE_URL}/api/v1/order/pseudo${
        queryParams.toString() ? `?${queryParams.toString()}` : ""
      }`;

      // console.log("endpoint", url);

      const config = {
        method: "GET",
        url: url,
        headers: {
          Authorization: `Bearer ${appContext.userInfoVal.sessionId}`,
        },
      };

      const response = await axios.request(config);

      if (response.data?.data?.orderList) {
        return response.data.data;
      }

      return null;
    } catch (error) {
      console.error("Error fetching orders:", error);
      if (
        error.response.data.message &&
        error.response.data.message.includes("jwt expired")
      ) {
        notify("Your login session Expired. Please login again.", 2000);
        handleLogout();
      }
      return null;
    }
  };

  const handleAddAdmin = async (data) => {
    try {
      const parsedData = JSON.parse(data);

      let config = {
        method: "POST",
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_BASE_URL}/api/v1/admin/createAdmin`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${appContext.userInfoVal.sessionId}`,
        },
        data: parsedData,
      };

      const response = await axios.request(config);

      if (response.data?.data) {
        if (response.data.message === "Successfully created an admin.") {
          notify("Admin created successfully", 2000);
          return response.data;
        }
      }
      return null;
    } catch (error) {
      console.error("Error:", error);
      if (
        error.response.data.message &&
        error.response.data.message.includes(
          "account already exists with phoneNumber"
        )
      ) {
        notify("Account already exists with this phoneNumber", 2000);
      }
      if (
        error.response.data.message &&
        error.response.data.message.includes(
          "account already exists with email"
        )
      ) {
        notify("Account already exists with this email", 2000);
      }
      return null;
    }
  };

  const handleDashBoeard = async (sessionId) => {
    let config = {
      method: "GET",
      maxBodyLength: Infinity,
      url: process.env.REACT_APP_BASE_URL + `/api/v1/admin`,
      headers: {
        Authorization: "Bearer " + sessionId,
      },
    };
    try {
      const response = await axios.request(config);
      if (response.data?.data) {
        return response.data?.data;
      }
    } catch (error) {
      console.error("Error:", error);
      if (
        error.response.data.message &&
        error.response.data.message.includes("jwt expired")
      ) {
        notify("Your login session Expired. Please login again.", 2000);
        handleLogout();
      }
      return null;
    }
  };

  const handleSearchOrder = async (searchType, searchValue) => {
    const endpoint =
      process.env.REACT_APP_BASE_URL +
      `/api/v1/order/pseudo/search/${searchType}/${searchValue}`;

    let config = {
      method: "GET",
      maxBodyLength: Infinity,
      url: endpoint,
      // process.env.REACT_APP_BASE_URL + `/api/v1/order/pseudo/search/${searchType}/${searchValue}`,
      headers: {
        Authorization: `Bearer ${appContext.userInfoVal.sessionId}`,
      },
    };
    try {
      const response = await axios.request(config);
      if (response.data?.data) {
        return response.data?.data;
      }
    } catch (error) {
      console.error("Error:", error);
      return null;
    }
  };

  const handleUpdateOrder = async (data) => {

    console.log("update order data",data);
    const endpoint =
      process.env.REACT_APP_BASE_URL + `/api/v1/order/pseudo/delivery`;

    let config = {
      method: "PUT",
      maxBodyLength: Infinity,
      url: endpoint,
      // process.env.REACT_APP_BASE_URL + `/api/v1/order/pseudo/search/${searchType}/${searchValue}`,
      headers: {
        Authorization: `Bearer ${appContext.userInfoVal.sessionId}`,
      },
      data: data,
    };
    try {
      const response = await axios.request(config);
      if (response.data?.data) {
        console.log("update order response", response.data?.data);
        return response.data?.data;
      }
    } catch (error) {
      console.error("Error:", error);
      return null;
    }
  };

  const getAllProducts = async (limit, page) => {
    try {
      const queryParams = new URLSearchParams();

      queryParams.append("limit", limit);

      queryParams.append("page", page);

      const url = `${process.env.REACT_APP_BASE_URL}/api/v1/product${
        queryParams.toString() ? `?${queryParams.toString()}` : ""
      }`;

      // console.log("endpoint", url);

      const config = {
        method: "GET",
        url: url,
        headers: {
          // Authorization: `Bearer ${appContext.userInfoVal.sessionId}`,
        },
      };

      const response = await axios.request(config);

      if (response) {
        return response.data?.data;
      }

      return null;
    } catch (error) {
      console.error("Error fetching orders:", error);
      return null;
    }
  };

  const handleCreateOrder = async (data, setShowAddeWallet) => {
    try {
      let config = {
        method: "POST",
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_BASE_URL}/api/v1/payment/createOrder/amount`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${appContext.userInfoVal.sessionId}`,
        },
        data: data,
      };

      const response = await axios.request(config);

      if (response.data?.data) {
        if (response.data.message === "link generated successfully") {
          // console.log("order create datat", response.data?.data);
          const paymentUrl =
            response.data?.data?.data?.instrumentResponse?.redirectInfo?.url;

          window.location.href = paymentUrl;
          return response.data;
        }
        if (response.data.message === "payment_success") {
          // console.log("wallet Payment", response.data.data);
          const redirectUrl = response.data.data;
          window.location.href = redirectUrl;
        }
      }
      return null;
    } catch (error) {
      console.error("Error:", error);
      if (error.response.data.message === "insufficient balance") {
        notify("Insufficient Balance in your wallet", 3000);
        setShowAddeWallet(true);
      }
      return null;
    }
  };

  const handleAddWallet = async (amount) => {
    try {
      let config = {
        method: "POST",
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_BASE_URL}/api/v1/payment/createOrder/${amount}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${appContext.userInfoVal.sessionId}`,
        },
        data: { product: "67545002b29479dbefc076d8" },
      };

      const response = await axios.request(config);

      if (response.data?.data) {
        if (response.data.message === "link generated successfully") {
          // console.log("order create datat", response.data?.data);
          const paymentUrl =
            response.data?.data?.data?.instrumentResponse?.redirectInfo?.url;
          window.location.href = paymentUrl;
          return response.data;
        }
      }
      return null;
    } catch (error) {
      console.error("Error:", error);
      return null;
    }
  };

  const handleSetProfile = async (data, setShowUpdateProfile) => {
    try {
      const filteredData = Object.fromEntries(
        Object.entries(data).filter(([_, value]) => value !== "" && value !== null && value !== undefined)
      );
      
      if (Object.keys(filteredData).length === 0) {
        notify("No changes to update", 2000);
        return null;
      }
  
      if (appContext.userInfoVal.accountType === "member" && !filteredData.hasOwnProperty("firmName")) {
      
        console.log("Member account with no firm name update");
      }
  
      let config = {
        method: "PUT",
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_BASE_URL}/api/v1/user/set_profile`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${appContext.userInfoVal.sessionId}`,
        },
        data: filteredData,
      };
  
      const response = await axios.request(config);
  
      if (response) {
        if (response.data.message === "profile updated successfully") {
          setShowUpdateProfile(false);
          notify("Profile updated successfully", 2000);
          
          if (appContext.setUserInfoVal) {
            appContext.setUserInfoVal(prevInfo => ({
              ...prevInfo,
              ...filteredData
            }));
          }
        }
      }
      return null;
    } catch (error) {
      console.error("Error:", error);
      notify("Failed to update profile", 2000);
      return null;
    }
  };

  const handleUpdatePass = async (
    data,
    setShowUpdatePassword,
    setShowUpdateProfile
  ) => {
    try {
      let config = {
        method: "PUT",
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_BASE_URL}/api/v1/user/set_profile`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${appContext.userInfoVal.sessionId}`,
        },
        data: data,
      };

      const response = await axios.request(config);

      if (response) {
        // console.log("update pass response", response.data.message);
        if (response.data.message === "password updated successfully") {
          setShowUpdatePassword(false);
          setShowUpdateProfile(false);
          notify("Password updated successfully", 2000);
        }
        if (response.data.message === "incorrect old password!") {
          notify("Incorrect old password!", 2000);
        }
      }
      return null;
    } catch (error) {
      console.error("Error:", error);
      return null;
    }
  };

  const handleForgotPass = async (data) => {
    try {
      let config = {
        method: "POST",
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_BASE_URL}/api/v1/auth/forgot-password`,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      const response = await axios.request(config);

      if (response) {
        if (
          response.data.message ===
          "Email has been sent to change forgotten password!"
        ) {
          notify(
            "Forgot password link sent! Please check your email to proceed.",
            2000
          );
        }
      }
      return null;
    } catch (error) {
      console.error("Error:", error);
      if (
        error.response.data.message ===
        "User is not authenticated or not found!"
      ) {
        notify("User is not authenticated or not found!", 2000);
      }
      return null;
    }
  };

  const handleNewPass = async (data, token) => {
    try {
      // console.log("New pass data", data);

      let config = {
        method: "POST",
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_BASE_URL}/api/v1/auth/forgot-password/${token}`,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      const response = await axios.request(config);
      // console.log("Forgot pass response", response);

      if (response) {
        if (response.data.message === "Password has been changed!") {
          notify(
            "Password reset successful. You may now log in with your new credentials.",
            2000
          );
          navigate("/");
        }
      }
      return null;
    } catch (error) {
      console.error("Error:", error);
      if (
        error.response.data.message ===
        "User is not authenticated or not found!"
      ) {
        notify("User is not authenticated or not found!", 2000);
      }
      return null;
    }
  };

  const getAllCategory = async () => {
    try {
      const queryParams = new URLSearchParams();

      const url = `${process.env.REACT_APP_BASE_URL}/api/v1/product?category=and`;

      const config = {
        method: "GET",
        url: url,
        headers: {
          // Authorization: `Bearer ${appContext.userInfoVal.sessionId}`,
        },
      };

      const response = await axios.request(config);

      if (response) {
        return response.data?.data;
      }

      return null;
    } catch (error) {
      console.error("Error fetching orders:", error);
      return null;
    }
  };

  const handleDelteProduct = async (productId, setDeleteProduct) => {
    try {
      const url = `${process.env.REACT_APP_BASE_URL}/api/v1/product/admin/${productId}`;

      // console.log("delteProduct endpoint", url);
      const config = {
        method: "DELETE",
        url: url,
        headers: {
          Authorization: `Bearer ${appContext.userInfoVal.sessionId}`,
        },
      };

      const response = await axios.request(config);

      if (response) {
        // console.log("response", response.data.message);
        if (response.data.message === "Product has been deleted!") {
          notify("Product has been deleted successfully!", 2500);
          setDeleteProduct(false);
        }
        return response.data?.data;
      }

      return null;
    } catch (error) {
      console.error("Error fetching orders:", error);
      return null;
    }
  };

  const handleCreateProduct = async (data) => {
    try {
      // console.log("Product created with", data);

      let config = {
        method: "POST",
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_BASE_URL}/api/v1/product/admin`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${appContext.userInfoVal.sessionId}`,
        },
        data: data,
      };

      const response = await axios.request(config);

      if (response) {
      }
      return null;
    } catch (error) {
      console.error("Error:", error);
      return null;
    }
  };

  const handleUpdateProduct = async (data) => {
    try {
      // console.log("Product updated with", data);

      let config = {
        method: "PUT",
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_BASE_URL}/api/v1/product/admin`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${appContext.userInfoVal.sessionId}`,
        },
        data: data,
      };

      const response = await axios.request(config);

      if (response) {
        return response;
      }
      return null;
    } catch (error) {
      console.error("Error:", error);
      return null;
    }
  };

  const handleContactData = async (data) => {
    try {
      // console.log("Contact us data", data);

      let config = {
        method: "POST",
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_BASE_URL}/api/v1/user/contact_us`,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      const response = await axios.request(config);

      if (response) {
        return response;
      }
      return null;
    } catch (error) {
      console.error("Error:", error);
      return null;
    }
  };

  const deleteContactData = async (data) => {
    try {

      let config = {
        method: "DELETE",
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_BASE_URL}/api/v1/user/contact_us`,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      const response = await axios.request(config);

      if (response) {
        return response;
      }
      return null;
    } catch (error) {
      console.error("Error:", error);
      return null;
    }
  };

  const fetchAllContactData = async () => {
    try {

      const url = `${process.env.REACT_APP_BASE_URL}/api/v1/user/admin`;

      const config = {
        method: "GET",
        url: url,
        headers: {
          Authorization: `Bearer ${appContext.userInfoVal.sessionId}`,
        },
      };

      const response = await axios.request(config);

      if (response) {
        // console.log("all contact data", response);
        return response.data?.data;
      }

      return null;
    } catch (error) {
      console.error("Error fetching contact data:", error);
      return null;
    }
  };
  
  const handleSearchProduct = async (searchValue) => {
    const endpoint =
      process.env.REACT_APP_BASE_URL +
      `/api/v1/product/search/${searchValue}`;

    let config = {
      method: "GET",
      maxBodyLength: Infinity,
      url: endpoint,
      headers: {
        // Authorization: `Bearer ${appContext.userInfoVal.sessionId}`,
      },
    };
    try {
      const response = await axios.request(config);
      if (response.data?.data) {
        return response.data?.data;
      }
    } catch (error) {
      console.error("Error:", error);
      return null;
    }
  };

  return {
    loginEmailPass,
    signupEmailPass,
    getAllUsers,
    deleteAndVerify,
    getAdminAllOrders,
    handleAddAdmin,
    handleDashBoeard,
    handleSearchOrder,
    handleUpdateOrder,
    getUserData,
    getAllProducts,
    handleCreateOrder,
    handleAddWallet,
    handleSetProfile,
    getAllCategory,
    handleDelteProduct,
    handleCreateProduct,
    handleUpdateProduct,
    handleUpdatePass,
    handleForgotPass,
    handleNewPass,
    handleContactData,
    fetchAllContactData,
    handleSearchProduct,
    getUserDataAdmin
  };
};
export default Core;

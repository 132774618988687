import React, { useState, useEffect } from "react";

import { CiHome } from "react-icons/ci";
import { MdOutlineFileDownload } from "react-icons/md";
import { FaArrowRight } from "react-icons/fa";
import { FaCheck } from "react-icons/fa6";
import { MdError } from "react-icons/md";
import { useNavigate, useLocation } from "react-router-dom";

const Payment = ({
  setIsWalletClicked,
  setIsClickedSupport,
  setIsClickedBilling,
  setIsClickedAccount,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [paymentDetails, setPaymentDetails] = useState({
    responseCode: "",
    transactionId: "",
    product: "",
    amount: "",
  });

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    setPaymentDetails({
      responseCode: params.get("responseCode") || "",
      transactionId: params.get("transactionId") || "",
      product: params.get("product") || "",
      amount: params.get("amount") || "",
    });
  }, [location]);

  const getMessageByProduct = () => {
    switch (paymentDetails.product) {
      case "Partner Registration Fee":
        return "Your Partner Registration has been completed. Your documents have been sent for verification. After successful verification, you can login and avail services.";
      case "Wallet Recharge":
        return "Your wallet has been recharged successfully. The amount has been added to your account.";
      default:
        return "Your transaction has been completed successfully.";
    }
  };

  return (
    <div className="min-h-screen bg-gray-50 flex flex-col justify-center items-center p-4">
      <div className="bg-white rounded-lg shadow-lg p-8 max-w-md w-full space-y-6">
        <div className="flex justify-center">
          <div className="bg-green-100 rounded-full p-3">
            {paymentDetails.responseCode === "PAYMENT_SUCCESS" ? (
              <div className="bg-green-500 rounded-full p-3">
                <FaCheck className="w-8 h-8 text-white" />
              </div>
            ) : (
              <div className="bg-red-500 rounded-full p-3">
                <MdError className="w-8 h-8 text-white" />
              </div>
            )}
          </div>
        </div>

        <div className="text-center space-y-2">
          <h1 className="text-2xl font-bold text-gray-800">
            {paymentDetails.responseCode === "PAYMENT_SUCCESS"
              ? "Payment Successful!"
              : "Payment Failed!"}
          </h1>
          <p className="text-gray-600">
            {paymentDetails.responseCode === "PAYMENT_SUCCESS"
              ? getMessageByProduct()
              : "Your transaction could not be completed. Please try again."}
          </p>
        </div>

        <div className="bg-gray-50 rounded-lg p-4 space-y-3">
          <div className="flex justify-between text-sm">
            <span className="text-gray-600">Transaction ID</span>
            <span className="text-gray-800 font-medium">
              {paymentDetails.transactionId}
            </span>
          </div>
          <div className="flex justify-between text-sm">
            <span className="text-gray-600">Amount Paid</span>
            <span className="text-gray-800 font-medium">
              ₹{paymentDetails.amount}
            </span>
          </div>
          <div className="flex justify-between text-sm">
            <span className="text-gray-600">Product Name</span>
            <span className="text-gray-800 font-medium">
              {paymentDetails.product}
            </span>
          </div>
        </div>

        <div className="space-y-3">
          {/* <button className="w-full bg-green-500 text-white py-2 px-4 rounded-lg hover:bg-green-600 transition-colors flex items-center justify-center gap-2">
            <MdOutlineFileDownload className="w-4 h-4" />
            Download Receipt
          </button> */}

          <div className="flex gap-3">
            <button
              onClick={() => {
                navigate("/");
              }}
              className="flex-1 border border-gray-300 text-gray-700 py-2 px-4 rounded-lg hover:bg-gray-50 transition-colors flex items-center justify-center gap-2"
            >
              <CiHome className="w-4 h-4" />
              Home
            </button>
            <button
              onClick={() => {
                navigate("/profile");
                setIsClickedBilling(true);
                setIsClickedAccount(false);
                setIsClickedSupport(false);
                setIsWalletClicked(false);
              }}
              className="flex-1 border border-gray-300 text-gray-700 py-2 px-4 rounded-lg hover:bg-gray-50 transition-colors flex items-center justify-center gap-2"
            >
              View Order
              <FaArrowRight className="w-4 h-4" />
            </button>
          </div>
        </div>
      </div>

      <div className="mt-8 text-center text-sm text-gray-600">
        Need help?{" "}
        <button
          onClick={() => {
            navigate("/profile");
            setIsClickedBilling(false);
            setIsClickedAccount(false);
            setIsClickedSupport(true);
            setIsWalletClicked(false);
          }}
          className="text-green-500 hover:text-green-600">
          Contact Support
        </button>
      </div>
    </div>
  );
};

export default Payment;
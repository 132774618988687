import React, { useState, useEffect, useRef } from "react";
import Core from "../core/Core";
import ProductCardsAdmin from "./ProductCardAdmin";
import { FiPlus } from "react-icons/fi";
import AddProduct from "./AddProduct";
import { IoClose } from "react-icons/io5";
import { Link } from "react-router-dom";
import { Search } from "lucide-react";

const ManageProducts = ({
  setSeletecedProd,
  createdSuccess,
  setCreatedSuccess,
}) => {
  const { getAllProducts, handleDelteProduct, handleSearchProduct } = Core();

  const [allProducts, setAllProducts] = useState([]);
  const [deleteProduct, setDeleteProduct] = useState(false);
  const [delteProdId, setDeleteProdId] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [displayedProducts, setDisplayedProducts] = useState([]);

  const handleSearch = async (value) => {
    setSearchValue(value);

    if (value.trim() === "") {
      setDisplayedProducts(allProducts);
      return;
    }

    setIsLoading(true);
    try {
      const searchResults = await handleSearchProduct(value);
      if (searchResults?.ProductList) {
        setDisplayedProducts(searchResults.ProductList);
      }
    } catch (error) {
      console.error("Error searching products:", error);
      setDisplayedProducts(allProducts);
    } finally {
      setIsLoading(false);
    }
  };

  const handleGetAllProducts = async (limit, page) => {
    try {
      setIsLoading(true);
      const response = await getAllProducts(limit, page);
      if (response?.ProductList) {
        setAllProducts(response.ProductList);
        setDisplayedProducts(response.ProductList);
      }
    } catch (error) {
      console.error("Error fetching products:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDelete = async () => {
    try {
      setIsLoading(true);
      await handleDelteProduct(delteProdId);
      setDeleteProduct(false);
      await handleGetAllProducts(30, 1);
    } catch (error) {
      console.error("Error deleting product:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    handleGetAllProducts(80, 1);
  }, []);

  useEffect(() => {
    if (createdSuccess) {
      handleGetAllProducts(80, 1);
    }
  }, [createdSuccess]);

  return (
    <div className="relative mx-auto px-6 pt-5   bg-gradient-to-r from-blue-100 to-blue-50 py-10 pb-7 rounded-xl shadow-xl">
      <div className="w-full flex justify-between items-center mb-5">
        <span className="text-2xl font-semibold text-black">
          Here you can manage all products (Total Products: {displayedProducts.length})
        </span>
        <div className=" transform transition-all duration-700">
          <div className="relative">
            <input
              type="text"
              placeholder="Search services..."
              value={searchValue}
              onChange={(e) => handleSearch(e.target.value)}
              style={{ border: "2px solid #dbdbdb" }}
              className="w-full px-4 py-2 pl-12 shadow-xl rounded-full border-2 border-blue-200 focus:border-blue-400 focus:ring-2 focus:ring-blue-200 transition-all duration-300 outline-none"
            />
            <Search className="absolute left-4 top-1/2 transform -translate-y-1/2 text-blue-400 w-5 h-5" />
          </div>
        </div>
        <Link
          to="/adminRk/products/addproduct"
          onClick={() => {
            setCreatedSuccess(false);
          }}
          className="px-4 py-3 bg-gray-500 hover:bg-gray-700 rounded-lg text-white font-bold text-lg flex justify-center items-center"
        >
          <FiPlus className="text-white text-2xl font-bold" />
          Add Product
        </Link>
      </div>
      {isLoading ? (
        <div className="flex justify-center items-center min-h-[200px]">
          <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900"></div>
        </div>
      ) : (
        <>
          {displayedProducts?.length === 0 ? (
            <div className="text-center py-8 text-gray-500">
              No products found
            </div>
          ) : (
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4 gap-8">
              {displayedProducts?.map((product, index) => (
                <ProductCardsAdmin
                  key={product.id || index}
                  product={product}
                  setDeleteProduct={setDeleteProduct}
                  setDeleteProdId={setDeleteProdId}
                  setSeletecedProd={setSeletecedProd}
                />
              ))}
            </div>
          )}
        </>
      )}

      {deleteProduct && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-80 z-50">
          <div className="rounded-lg backdrop bg-opacity-50 bg-gradient-to-b from-gray-500/50 to-gray-900/50 backdrop-blur-md border border-slate-500 shadow-lg animate-fadeIn p-5">
            <h2 className="text-lg font-bold mb-1 text-slate-100">
              Confirm Delete
            </h2>
            <p className="mb-4 text-zinc-400 text-base">
              Are you sure you want to delete this product?
            </p>
            <div className="flex justify-end">
              <button
                className="bg-red-500 text-white px-4 py-2 rounded-md mr-2 disabled:opacity-50"
                onClick={handleDelete}
                disabled={isLoading}
              >
                {isLoading ? "Deleting..." : "Yes"}
              </button>
              <button
                className="bg-gray-300 text-gray-800 px-4 py-2 rounded-md"
                onClick={() => setDeleteProduct(false)}
                disabled={isLoading}
              >
                No
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ManageProducts;

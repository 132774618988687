import React, { useContext, useEffect, useState, useLayoutEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import AppContext from "../AppContext/AppContext";

const ProductDetail = ({ setSigninClicked }) => {
  const appContext = useContext(AppContext);
  const navigate = useNavigate();
  const [activeSection, setActiveSection] = useState(0);
  const [isScrolling, setIsScrolling] = useState(false);
  const [isVisible, setIsVisible] = useState(false);

  const notify = (text, time) => {
    toast.dismiss();
    toast.info(text, {
      toastId: "random1",
      position: "top-center",
      autoClose: time || 1500,
      theme: "light",
      style: {
        // width: "400px",
        // maxWidth: "90vw",
        padding: "15px 20px",
        fontSize: "16px",
        textAlign: "center",
        fontWeight: "500",
      },
    });
  };

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);

    setIsVisible(true);

    const handleScroll = () => {
      const sections = document.querySelectorAll(".section");
      const scrollPosition = window.scrollY + window.innerHeight / 2;

      sections.forEach((section, index) => {
        if (isElementInViewport(section)) {
          section.classList.add("animate-fade-in-up");
        }

        const top = section.offsetTop;
        const height = section.offsetHeight;

        if (scrollPosition >= top && scrollPosition < top + height) {
          setActiveSection(index);
        }
      });

      setIsScrolling(window.scrollY > 100);
    };

    const isElementInViewport = (el) => {
      const rect = el.getBoundingClientRect();
      return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <=
          (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <=
          (window.innerWidth || document.documentElement.clientWidth)
      );
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);

    setIsVisible(true);

    const handleScroll = () => {
      const sections = document.querySelectorAll(".section");
      const scrollPosition = window.scrollY + window.innerHeight / 2;

      sections.forEach((section, index) => {
        if (isElementInViewport(section)) {
          section.classList.add("animate-fade-in-up");
        }

        const top = section.offsetTop;
        const height = section.offsetHeight;

        if (scrollPosition >= top && scrollPosition < top + height) {
          setActiveSection(index);
        }
      });

      setIsScrolling(window.scrollY > 100);
    };

    const isElementInViewport = (el) => {
      const rect = el.getBoundingClientRect();
      return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <=
          (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <=
          (window.innerWidth || document.documentElement.clientWidth)
      );
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const handleApplyNow = (e) => {
    e.preventDefault();
    if (appContext.userInfoVal.sessionId === null) {
      setSigninClicked(true);
      notify("Please login before applying", 3000);
    } else {
      navigate("/checkout");
    }
  };

  return (
    <div
      className={`min-h-screen bg-gray-50 transition-opacity duration-1000 ${
        isVisible ? "opacity-100" : "opacity-0"
      }`}
    >
      <section className="section relative flex  py-10 justify-center overflow-hidden transition-all duration-700 ease-in-out transform">
        <div className="bg-gradient-to-br from-blue-50 to-indigo-50 animate-gradient-x" />
        <div className="px-[4%] relative z-10">
          <div className="flex flex-col lg:flex-row items-center gap-16">
            <div className="lg:w-1/2 space-y-8 animate-fade-in-left">
              <h1 className="text-4xl lg:text-5xl font-bold leading-tight transform transition-all duration-500 hover:scale-102">
                <span className="bg-gradient-to-r from-blue-600 to-indigo-600 bg-clip-text text-transparent animate-gradient-x">
                  {appContext.productInfoVal.productName}
                </span>
              </h1>

              <div className="flex flex-col gap-4">
                <p className="text-lg text-gray-600 leading-relaxed transform transition-all duration-500">
                  {appContext.productInfoVal.description}
                </p>
                <span className="font-bold  text-xl text-blue-600 leading-relaxed transform transition-all duration-500">
                  Product Price: {appContext.productInfoVal.price}
                </span>
              </div>

              <div className="pt-4">
                <button
                  onClick={handleApplyNow}
                  className="group relative inline-flex items-center justify-center transform transition-all duration-300 hover:scale-105"
                >
                  <span className="absolute inset-0 bg-gradient-to-r from-blue-500 to-indigo-500 rounded-lg blur-lg opacity-75 group-hover:opacity-100 transition-all duration-500 animate-pulse" />
                  <span className="relative px-8 py-4 bg-white rounded-lg text-gray-900 font-medium text-lg shadow-xl">
                    Apply Now
                  </span>
                </button>
              </div>
            </div>

            <div className="lg:w-1/2 relative animate-fade-in-right">
              <div className="relative group">
                <div className="absolute -inset-1 bg-gradient-to-r from-blue-500 to-indigo-500 rounded-2xl blur opacity-30 group-hover:opacity-60 transition duration-1000 animate-pulse" />
                <div className="relative overflow-hidden rounded-2xl">
                  <img
                    src={appContext.productInfoVal.image}
                    alt="Product Preview"
                    className="rounded-2xl shadow-2xl w-full object-cover transform transition duration-700 group-hover:scale-110"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="section relative py-10 bg-white">
        <div className="container mx-auto px-6">
          <div className="max-w-3xl mx-auto">
            <h2 className="text-3xl font-bold mb-12 text-center transform transition-all duration-500 hover:scale-105">
              <span className="bg-gradient-to-r from-blue-600 to-indigo-600 bg-clip-text text-transparent animate-gradient-x">
                Required Documents
              </span>
            </h2>

            <div className="grid gap-6">
              {Object.entries(appContext.productInfoVal.requiredDocs).map(
                ([key, value], index) => (
                  <div
                    key={index}
                    className="group relative transform transition-all duration-500 hover:-translate-y-1"
                    style={{ animationDelay: `${index * 100}ms` }}
                  >
                    <div className="absolute -inset-0.5 bg-gradient-to-r from-blue-500 to-indigo-500 rounded-lg blur opacity-30 group-hover:opacity-60 transition duration-500" />
                    <div className="relative flex items-center gap-4 p-6 bg-white rounded-lg shadow-lg border border-gray-100 transition duration-300 hover:bg-gray-50">
                      <div className="flex-shrink-0 w-12 h-12 flex items-center justify-center rounded-full bg-blue-100 transform transition-transform duration-500 group-hover:rotate-12">
                        <svg
                          className="w-6 h-6 text-blue-600"
                          fill="none"
                          stroke="currentColor"
                          viewBox="0 0 24 24"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
                          />
                        </svg>
                      </div>
                      <span className="text-lg text-gray-700 transform transition-all duration-300 group-hover:translate-x-2">
                        {value}
                      </span>
                    </div>
                  </div>
                )
              )}
            </div>
          </div>
        </div>
      </section>

      <section className="section relative py-10 bg-gray-50">
        <div className="container mx-auto px-6">
          <h2 className="text-3xl font-bold mb-16 text-center transform transition-all duration-500 hover:scale-105">
            <span className="bg-gradient-to-r from-blue-600 to-indigo-600 bg-clip-text text-transparent animate-gradient-x">
              Application Process
            </span>
          </h2>

          <div className="max-w-4xl mx-auto">
            {Object.values(appContext.productInfoVal.processFlow).map(
              (step, index) => (
                <div
                  key={index}
                  className="group relative mb-8 last:mb-0 transform transition-all duration-500 hover:-translate-y-1"
                  style={{ animationDelay: `${index * 150}ms` }}
                >
                  <div className="absolute left-8 top-8 -bottom-8 w-0.5 bg-gradient-to-b from-blue-500 to-indigo-500 last:hidden animate-pulse" />
                  <div className="relative flex gap-8">
                    <div className="relative">
                      <div className="absolute -inset-2 rounded-full blur opacity-30 group-hover:opacity-60 transition duration-500" />
                      <div className="relative w-16 h-16 flex items-center justify-center rounded-full bg-white shadow-lg border border-gray-100 transform transition-all duration-500 group-hover:rotate-12">
                        <span className="text-2xl text-gray-800 font-bold bg-gradient-to-r from-blue-600 to-indigo-600 bg-clip-text">
                          {index + 1}
                        </span>
                      </div>
                    </div>

                    <div className="flex-1 relative">
                      <div className="absolute -inset-0.5 bg-gradient-to-r from-blue-500 to-indigo-500 rounded-lg blur opacity-30 group-hover:opacity-60 transition duration-500" />
                      <div className="relative p-6 bg-white rounded-lg shadow-lg border border-gray-100 transition duration-300 hover:bg-gray-50 transform group-hover:translate-x-2">
                        <p className="text-lg text-gray-700">{step}</p>
                      </div>
                    </div>
                  </div>
                </div>
              )
            )}
          </div>

          <div className="mt-16 text-center">
            <button
              onClick={handleApplyNow}
              className="group relative inline-flex items-center justify-center transform transition-all duration-300 hover:scale-105"
            >
              <span className="absolute inset-0 bg-gradient-to-r from-blue-500 to-indigo-500 rounded-lg blur-lg opacity-75 group-hover:opacity-100 transition-opacity duration-500 animate-pulse" />
              <span className="relative px-12 py-6 bg-white rounded-lg text-gray-900 font-medium text-xl shadow-xl flex items-center gap-3">
                Start Your Application
                <svg
                  className="w-6 h-6 transform transition-all duration-300 group-hover:translate-x-3"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M17 8l4 4m0 0l-4 4m4-4H3"
                  />
                </svg>
              </span>
            </button>
          </div>
        </div>
      </section>
    </div>
  );
};

// Add these custom animations to your global CSS or Tailwind config
const style = document.createElement("style");
style.textContent = `
  @keyframes gradient-x {
    0%, 100% {
      background-size: 200% 200%;
      background-position: left center;
    }
    50% {
      background-size: 200% 200%;
      background-position: right center;
    }
  }
  
  .animate-gradient-x {
    animation: gradient-x 15s ease infinite;
  }
  
  .animate-fade-in-up {
    animation: fade-in-up 0.8s ease-out forwards;
  }
  
  .animate-fade-in-left {
    animation: fade-in-left 0.8s ease-out forwards;
  }
  
  .animate-fade-in-right {
    animation: fade-in-right 0.8s ease-out forwards;
  }
  
  @keyframes fade-in-up {
    0% {
      opacity: 0;
      transform: translateY(20px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  @keyframes fade-in-left {
    0% {
      opacity: 0;
      transform: translateX(-20px);
    }
    100% {
      opacity: 1;
      transform: translateX(0);
    }
  }
  
  @keyframes fade-in-right {
    0% {
      opacity: 0;
      transform: translateX(20px);
    }
    100% {
      opacity: 1;
      transform: translateX(0);
    }
  }
`;
document.head.appendChild(style);

export default ProductDetail;

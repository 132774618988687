import React, { useContext, useEffect, useState, useRef } from "react";
import AppContext from "../AppContext/AppContext";
import { toast } from "react-toastify";
import axios from "axios";
import Core from "../core/Core";

const ApplyNow = ({ setShowAddeWallet, setSigninClicked }) => {
  const appContext = useContext(AppContext);
  const { handleCreateOrder } = Core();
  const notify = (text, time) => {
    toast.dismiss();

    toast.info(text, {
      toastId: "random1",
      closeButton: false,
      position: "top-center",
      autoClose: time ? time : 1500,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      style: {
        // width: "400px",
        // maxWidth: "90vw",
        padding: "15px 20px",
        fontSize: "16px",
        textAlign: "center",
        fontWeight: "500",
      },
    });
  };
  const fileInputRefs = useRef({});
  const [fileUploading, setFileUploading] = useState(false);
  const [formData, setFormData] = useState({
    applicantFullName: "",
    applicantFatherName: "",
    applicantMobileNumber: "",
    email: "",
  });
  const [uploadedDocs, setUploadedDocs] = useState([]);
  const [termsAccepted, setTermsAccepted] = useState(false);

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const validatePhone = (phone) => {
    const phoneRegex = /^[6-9]\d{9}$/;
    return phoneRegex.test(phone);
  };

  const validateName = (name) => {
    return name.trim().length >= 3 && /^[a-zA-Z\s]+$/.test(name);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });

    toast.dismiss();

    // switch (name) {
    //   case 'email':
    //     if (value && !validateEmail(value)) {
    //       notify("Please enter a valid email address", 2000);
    //     }
    //     break;
    //   case 'applicantMobileNumber':
    //     if (value && !validatePhone(value)) {
    //       notify("Please enter a valid 10-digit mobile number", 2000);
    //     }
    //     break;
    //   case 'applicantFullName':
    //     if (value && !validateName(value)) {
    //       notify("Name should contain only letters and be at least 3 characters long", 2000);
    //     }
    //     break;
    //   case 'applicantFatherName':
    //     if (value && !validateName(value)) {
    //       notify("Father's name should contain only letters and be at least 3 characters long", 2000);
    //     }
    //     break;
    //   default:
    //     break;
    // }
  };

  const handleTermsChange = (e) => {
    setTermsAccepted(e.target.checked);
  };

  const validateForm = () => {
    if (
      !formData.applicantFullName ||
      !validateName(formData.applicantFullName)
    ) {
      notify("Please enter a valid full name", 3000);
      return false;
    }

    if (
      !formData.applicantFatherName ||
      !validateName(formData.applicantFatherName)
    ) {
      notify("Please enter a valid father's name", 3000);
      return false;
    }

    if (
      !formData.applicantMobileNumber ||
      !validatePhone(formData.applicantMobileNumber)
    ) {
      notify("Please enter a valid 10-digit mobile number", 3000);
      return false;
    }

    if (!formData.email || !validateEmail(formData.email)) {
      notify("Please enter a valid email address", 3000);
      return false;
    }

    return true;
  };

  const handlePhotoUpload = async (file) => {
    const formData = new FormData();
    formData.append("file", file);

    const options = {
      method: "POST",
      url: process.env.REACT_APP_BASE_URL + `/api/v1/user/s3/operations`,
      headers: {
        Authorization: `Bearer ${appContext.userInfoVal.sessionId}`,
        "content-type": "multipart/form-data",
      },
      data: formData,
    };

    try {
      const { data } = await axios.request(options);
      if (data && data?.data?.[0]) {
        return data?.data?.[0];
      }
    } catch (error) {
      console.error("Failed to upload file:", error);
      notify("Failed to upload file. Please try again.", 3000);
      if (
        error.response.data.message &&
        error.response.data.message.includes("jwt expired")
      ) {
        notify("Your login session Expired. Please login again.", 2000);
        setSigninClicked(true);
      }
      throw error;
    }
  };

  const handleFileUpload = async (e, docKey, docLabel) => {
    const file = e.target.files[0];
    if (!file) return;

    const maxSize = 5 * 1024 * 1024; // 5MB
    if (file.size > maxSize) {
      notify("File size should be less than 5MB", 3000);
      return;
    }

    setFileUploading(true);
    try {
      const fileUrl = await handlePhotoUpload(file);
      setUploadedDocs((prev) => {
        const filtered = prev.filter((doc) => doc.key !== docKey);
        return [
          ...filtered,
          {
            key: docKey,
            name: docLabel,
            url: fileUrl,
          },
        ];
      });
      notify("File uploaded successfully!", 1000);
    } catch (error) {
      console.error("Error uploading file:", error);
      notify("Failed to upload file. Please try again.", 3000);
    } finally {
      setFileUploading(false);
    }
  };

  const handleSubmit = async () => {
    if (!validateForm()) {
      return;
    }

    const requiredDocs = Object.keys(
      appContext.productInfoVal.requiredDocs || {}
    );
    const uploadedDocsKeys = uploadedDocs.map((doc) => doc.key);
    const missingDocs = requiredDocs.filter(
      (doc) => !uploadedDocsKeys.includes(doc)
    );

    if (missingDocs.length > 0) {
      notify("Please upload all required documents", 3000);
      return;
    }

    const orderData = {
      ...formData,
      product: appContext.productInfoVal._id,
      uploadedDocs: uploadedDocs,
    };

    try {
      const response = await handleCreateOrder(orderData, setShowAddeWallet);
      if (response && response.data) {
        handleReset();
        notify("Order created successfully", 2000);
      } else {
        notify("Failed to checkout please try again", 3000);
      }
    } catch (error) {
      console.error("Error creating order:", error);
      // if (error.response.data.message === "insufficient balance") {
      //   notify("Insufficient Balance in your wallet", 3000);
      //   setShowAddeWallet(true);
      // } else {
      //   notify("Failed to checkout please try again", 3000);
      // }
    }
  };

  const handleReset = () => {
    setFormData({
      applicantFullName: "",
      applicantFatherName: "",
      applicantMobileNumber: "",
      email: "",
    });
    setUploadedDocs([]);
    resetFileInputs();
  };

  const resetFileInputs = () => {
    Object.values(fileInputRefs.current).forEach((ref) => {
      if (ref) {
        ref.value = "";
      }
    });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    resetFileInputs();
    return () => {
      resetFileInputs();
    };
  }, []);

  return (
    <div className="min-h-screen bg-gray-50">
      <div className="container mx-auto px-4 py-8">
        <h1 className="text-3xl font-bold text-center mb-8">Checkout</h1>

        <div className="flex flex-col lg:flex-row gap-6 max-w-7xl mx-auto">
          <div className="flex-1 bg-white rounded-lg shadow-xl overflow-hidden">
            <div className="bg-gray-200 p-6">
              <h2 className="text-xl font-semibold text-gray-800">
                Fill Details
              </h2>
              <p className="text-sm text-gray-600 mt-1">
                All fields are required *
              </p>
            </div>

            <div className="p-6">
              <div className="grid md:grid-cols-2 gap-6">
                <div className="space-y-4">
                  <div className="relative">
                    <input
                      type="text"
                      required
                      className="w-full px-4 py-3 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent outline-none transition"
                      placeholder="Applicant Full Name"
                      name="applicantFullName"
                      value={formData.applicantFullName}
                      onChange={handleInputChange}
                      style={{ border: "1px solid black" }}
                    />
                  </div>

                  <div className="relative">
                    <input
                      type="text"
                      required
                      className="w-full px-4 py-3 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent outline-none transition"
                      placeholder="Applicant Father's Name"
                      name="applicantFatherName"
                      value={formData.applicantFatherName}
                      onChange={handleInputChange}
                      style={{ border: "1px solid black" }}
                    />
                  </div>
                </div>

                <div className="space-y-4">
                  <div className="relative">
                    <input
                      type="tel"
                      required
                      className="w-full px-4 py-3 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent outline-none transition"
                      placeholder="Mobile Number"
                      name="applicantMobileNumber"
                      value={formData.applicantMobileNumber}
                      onChange={handleInputChange}
                      style={{ border: "1px solid black" }}
                    />
                  </div>

                  <div className="relative">
                    <input
                      type="email"
                      required
                      className="w-full px-4 py-3 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent outline-none transition"
                      placeholder="Email Address"
                      name="email"
                      value={formData.email}
                      onChange={handleInputChange}
                      style={{ border: "1px solid black" }}
                    />
                  </div>
                </div>
              </div>
              <div className="mt-8 grid md:grid-cols-2 gap-6">
                {Object.entries(
                  appContext.productInfoVal.requiredDocs || {}
                ).map(([key, label]) => (
                  <div key={key} className="space-y-2">
                    <label className="block text-sm font-medium text-gray-700">
                      {label}
                    </label>
                    <input
                      type="file"
                      accept="image/*,.pdf"
                      required
                      style={{ border: "1px solid black" }}
                      ref={(el) => (fileInputRefs.current[key] = el)}
                      onChange={(e) => handleFileUpload(e, key, label)}
                      className="w-full px-4 py-2 border rounded-lg text-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                    />
                  </div>
                ))}
              </div>

              <div className="mt-8 flex items-center">
                <input
                  type="checkbox"
                  id="terms"
                  checked={termsAccepted}
                  onChange={handleTermsChange}
                  className="h-4 w-4 text-blue-600 rounded border-gray-300 focus:ring-blue-500"
                />
                <label
                  htmlFor="terms"
                  className="ml-2 block text-sm text-gray-700"
                >
                  I agree to the{" "}
                  <a
                    href="https://www.termsfeed.com/live/05cb0efc-1996-4d5f-a606-a2e7ba052431"
                    className="text-blue-600 hover:underline"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Terms and Conditions
                  </a>{" "}
                  and{" "}
                  <a
                    href="https://www.termsfeed.com/live/05cb0efc-1996-4d5f-a606-a2e7ba052431"
                    className="text-blue-600 hover:underline"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Privacy Policy
                  </a>
                </label>
              </div>

              <div className="mt-8 flex flex-wrap gap-4">
                <button
                  onClick={handleSubmit}
                  disabled={fileUploading || !termsAccepted}
                  className="px-6 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 focus:outline-none focus:ring-4 focus:ring-blue-500/50 transition disabled:opacity-50"
                >
                  {fileUploading ? "Uploading..." : "Submit & Pay"}
                </button>
                <button
                  onClick={handleReset}
                  className="px-6 py-2 bg-gray-200 text-gray-700 rounded-lg hover:bg-gray-300 focus:outline-none focus:ring-4 focus:ring-gray-500/50 transition"
                >
                  Reset
                </button>
              </div>
            </div>
          </div>

          <div className="lg:w-1/3 bg-white rounded-lg shadow-xl overflow-hidden">
            <div className="p-6">
              <h2 className="text-xl font-semibold border-b pb-4 mb-6">
                Order Details
              </h2>

              <div className="space-y-4">
                <div className="flex justify-between items-center">
                  <span className="text-gray-600">Product Name:</span>
                  <span className="font-medium">
                    {appContext.productInfoVal.productName}
                  </span>
                </div>

                <div className="flex justify-between items-center">
                  <span className="text-gray-600">Product Price:</span>
                  <span className="font-medium">
                    INR{" "}
                    {appContext.userInfoVal.accountType === "member"
                      ? appContext.productInfoVal.member_price
                      : appContext.productInfoVal.price}
                  </span>
                </div>

                <div className="flex justify-between items-center">
                  <span className="text-gray-600">Quantity:</span>
                  <span className="font-medium">1</span>
                </div>

                <div className="flex justify-between items-center pt-4 border-t">
                  <span className="text-gray-600">Total:</span>
                  <span className="text-lg font-bold">
                    INR{" "}
                    {appContext.userInfoVal.accountType === "member"
                      ? appContext.productInfoVal.member_price
                      : appContext.productInfoVal.price}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ApplyNow;

import React, { useContext, useState } from "react";
import { FiPlus } from "react-icons/fi";
import { FiMinus } from "react-icons/fi";
import Core from "../core/Core";
import axios from "axios";
import AppContext from "../AppContext/AppContext";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const AddProduct = ({ selectedProd, setSelectedProd, setCreatedSuccess }) => {
  const notify = (text, time) => {
    toast.dismiss();

    toast.info(text, {
      toastId: "random1",
      closeButton: false,
      position: "top-center",
      autoClose: time ? time : 1500,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      style: {
        // width: "400px",
        // maxWidth: "90vw",
        padding: "15px 20px",
        fontSize: "16px",
        textAlign: "center",
        fontWeight: "500",
      },
    });
  };

  const { handleCreateProduct } = Core();
  const navigate = useNavigate();
  const appContext = useContext(AppContext);

  const [productData, setProductData] = useState({
    productName: "",
    description: "",
    image: "",
    price: "",
    member_price: "",
  });

  const [docs, setDocs] = useState([{ id: 1, value: "" }]);
  const [steps, setSteps] = useState([{ id: 1, value: "" }]);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setProductData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleImageChange = async (e) => {
    const file = e.target.files[0];

    const maxSize = 5 * 1024 * 1024;
    if (file.size > maxSize) {
      notify("File size should be less than 5MB", 3000);
      return;
    }

    const fileUrl = await handlePhotoUpload(file);
    setProductData((prev) => ({
      ...prev,
      image: fileUrl,
    }));
  };

  const addDoc = () => {
    const newDoc = {
      id: docs.length + 1,
      value: "",
    };
    setDocs([...docs, newDoc]);
  };

  const removeDoc = () => {
    setDocs(docs.slice(0, -1));
  };

  const addStep = () => {
    const newStep = {
      id: steps.length + 1,
      value: "",
    };
    setSteps([...steps, newStep]);
  };

  const removeStep = () => {
    setSteps(steps.slice(0, -1));
  };

  const handleDocChange = (id, value) => {
    setDocs(docs.map((doc) => (doc.id === id ? { ...doc, value } : doc)));
  };

  const handleStepChange = (id, value) => {
    setSteps(steps.map((step) => (step.id === id ? { ...step, value } : step)));
  };

  const handlePhotoUpload = async (file) => {
    const formData = new FormData();
    formData.append("file", file);

    const options = {
      method: "POST",
      url: process.env.REACT_APP_BASE_URL + `/api/v1/user/s3/operations`,
      headers: {
        Authorization: `Bearer ${appContext.userInfoVal.sessionId}`,
        "content-type": "multipart/form-data",
      },
      data: formData,
    };

    try {
      const { data } = await axios.request(options);
      console.log(data?.data?.[0]);

      if (data && data?.data?.[0]) {
        notify("File uploaded successfully!", 1500);
        return data?.data?.[0];
      }
    } catch (error) {
      console.error(error);
      console.log("Failed to upload photo. Please try again.");
    } finally {
    }
  };

  const formatDataForSubmission = () => {
    const data = {
      productName: productData.productName,
      description: productData.description,
      price: productData.price,
      member_price: productData.member_price,
      image: productData.image,
      requiredDocs: docs.reduce((acc, doc) => {
        acc[`doc${doc.id}`] = doc.value;
        return acc;
      }, {}),
      processFlow: steps.reduce((acc, step) => {
        acc[`step${step.id}`] = step.value;
        return acc;
      }, {}),
    };

    console.log("Data being sent to backend:", data);

    return data;
  };

  const createProduct = async () => {
    try {
      setIsSubmitting(true);
      const formData = formatDataForSubmission();
      console.log("create product data", formData);
      const response = await handleCreateProduct(formData);
      console.log("Created Response Data", response);
      notify("Product created successfully!", 2000);
      setCreatedSuccess(true);
      setTimeout(() => {
        navigate("/adminRk/products");
      }, 2000);
    } catch (error) {
      console.error("Error creating product:", error);
      notify("Failed to create product. Please try again.", 3000);
    } finally {
      setIsSubmitting(false);
    }
  };
  
  return (
    <div className="p-6 max-w-4xl mx-auto">
      <h1 className="text-3xl font-bold text-blue-600 mb-8">Create Product</h1>

      <div className="bg-white rounded-lg shadow-lg p-6 space-y-6">
        <div className="space-y-4">
          <div>
            <label className="block text-gray-700 font-medium mb-2">
              Product Name
            </label>
            <input
              type="text"
              name="productName"
              value={productData.productName}
              onChange={handleInputChange}
              className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
              placeholder="Enter product name"
              style={{ border: "2px solid gray" }}
            />
          </div>

          <div>
            <label className="block text-gray-700 font-medium mb-2">
              Product Image
            </label>
            <input
              type="file"
              onChange={handleImageChange}
              className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500 file:mr-4 file:py-2 file:px-4 file:rounded-lg file:border-0 file:text-sm file:bg-blue-50 file:text-blue-700 hover:file:bg-blue-100"
              accept="image/*"
              style={{ border: "2px solid gray" }}
            />
          </div>

          <div>
            <label className="block text-gray-700 font-medium mb-2">
              Product Description
            </label>
            <textarea
              className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
              rows="4"
              name="description"
              value={productData.description}
              onChange={handleInputChange}
              placeholder="Enter product description"
              style={{ border: "2px solid gray" }}
            ></textarea>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div>
              <label className="block text-gray-700 font-medium mb-2">
                Price for Users
              </label>
              <input
                type="number"
                name="price"
                value={productData.price}
                onChange={handleInputChange}
                className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                placeholder="Enter price for users"
                style={{ border: "2px solid gray" }}
              />
            </div>
            <div>
              <label className="block text-gray-700 font-medium mb-2">
                Price for Members
              </label>
              <input
                type="number"
                name="member_price"
                value={productData.member_price}
                onChange={handleInputChange}
                className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                placeholder="Enter price for members"
                style={{ border: "2px solid gray" }}
              />
            </div>
          </div>
        </div>

        <div className="border-t pt-6">
          <h2 className="text-xl font-semibold text-gray-800 mb-4">
            Required Documents
          </h2>
          {docs.map((doc, index) => (
            <div key={doc.id} className="flex gap-4 mb-4">
              <div className="flex-grow">
                <label className="block text-gray-700 font-medium mb-2">
                  Doc {doc.id}
                </label>
                <input
                  type="text"
                  className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  value={doc.value}
                  onChange={(e) => handleDocChange(doc.id, e.target.value)}
                  style={{ border: "2px solid gray" }}
                />
              </div>
              <div className="flex items-end">
                {index === docs.length - 1 ? (
                  <button
                    onClick={addDoc}
                    className="px-3 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors"
                  >
                    <FiPlus size={20} />
                  </button>
                ) : (
                  <button
                    onClick={removeDoc}
                    className="px-3 py-2 bg-red-500 text-white rounded-lg hover:bg-red-600 transition-colors"
                  >
                    <FiMinus size={20} />
                  </button>
                )}
              </div>
            </div>
          ))}
        </div>

        <div className="border-t pt-6">
          <h2 className="text-xl font-semibold text-gray-800 mb-4">
            Process Flow
          </h2>
          {steps.map((step, index) => (
            <div key={step.id} className="flex gap-4 mb-4">
              <div className="flex-grow">
                <label className="block text-gray-700 font-medium mb-2">
                  Step {step.id}
                </label>
                <textarea
                  className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  rows="2"
                  value={step.value}
                  onChange={(e) => handleStepChange(step.id, e.target.value)}
                  style={{ border: "2px solid gray" }}
                ></textarea>
              </div>
              <div className="flex items-end">
                {index === steps.length - 1 ? (
                  <button
                    onClick={addStep}
                    className="px-3 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors"
                  >
                    <FiPlus size={20} />
                  </button>
                ) : (
                  <button
                    onClick={removeStep}
                    className="px-3 py-2 bg-red-500 text-white rounded-lg hover:bg-red-600 transition-colors"
                  >
                    <FiMinus size={20} />
                  </button>
                )}
              </div>
            </div>
          ))}
        </div>

        <div className="border-t pt-6">
          <button
            onClick={createProduct}
            disabled={isSubmitting}
            className={`w-full ${
              isSubmitting ? "bg-blue-400" : "bg-blue-600 hover:bg-blue-700"
            } text-white py-3 rounded-lg transition-colors font-medium`}
          >
            {isSubmitting ? "Creating Product..." : "Create Product"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddProduct;

import React, { useState, useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';
import MobileLoginSignup from './MobileLoginSignup';
import DesktopLoginSignup from './LoginSignup';

const LoginWrapper = ({
  navRef,
  setSigninClicked,
  setUserSignUp,
  userSignUp,
  signinClicked,
  memberSignUp,
  setMemberSignUp,
  setIsSignup,
  isSignup,
  setShowGlobalLoader,
  showGlobalLoader,
  setShowNotification,
  setFailedNoti
}) => {
  const isMobileOrTablet = useMediaQuery({ maxWidth: 900 });

  return (
    <>
      {isMobileOrTablet ? (
        <MobileLoginSignup
          setSigninClicked={setSigninClicked}
          setUserSignUp={setUserSignUp}
          userSignUp={userSignUp}
          signinClicked={signinClicked}
          memberSignUp={memberSignUp}
          setMemberSignUp={setMemberSignUp}
          setIsSignup={setIsSignup}
          isSignup={isSignup}
          setShowGlobalLoader={setShowGlobalLoader}
          showGlobalLoader={showGlobalLoader}
          setShowNotification={setShowNotification}
          setFailedNoti={setFailedNoti}
          navRef={navRef}
        />
      ) : (
        <DesktopLoginSignup
          setSigninClicked={setSigninClicked}
          setUserSignUp={setUserSignUp}
          userSignUp={userSignUp}
          signinClicked={signinClicked}
          memberSignUp={memberSignUp}
          setMemberSignUp={setMemberSignUp}
          setIsSignup={setIsSignup}
          isSignup={isSignup}
          setShowGlobalLoader={setShowGlobalLoader}
          showGlobalLoader={showGlobalLoader}
          setShowNotification={setShowNotification}
          setFailedNoti={setFailedNoti}
          navRef={navRef}
        />
      )}
    </>
  );
};

export default LoginWrapper;
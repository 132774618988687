import React, { useState, useEffect } from "react";
import ScrollReveal from "./components/ScrollReveal"; 
import Navbar from "./components/Navbar";
import LoginSignup from "./components/LoginSignup";
import ContactUs from "./components/ContactUs";
import FixedButtons from "./components/FixedButtons";
import HeroSection from "./components/HeroSection";
import AppContext from "./AppContext/AppContext";
import Footer from "./components/Footer";
import Cards from "./components/Cards";
import About from "./components/About";
import Loader from "./components/Loader";
import Core from "./core/Core";
import AddWallet from "./components/AddWallet";

const Landing = ({
  navRef,
  signinClicked,
  setSigninClicked,
  isLoggedIn,
  setISsLoggedIn,
  userSignUp,
  memberSignUp,
  setUserSignUp,
  setMemberSignUp,
  setIsSignup,
  isSignup,
}) => {
  const { getAllProducts } = Core();
  const [products, setProducts] = useState([]);

  const handleGetAllProducts = async () => {
    try {
      const response = await getAllProducts(8, 1);
      setProducts(response?.ProductList);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    handleGetAllProducts();
  }, []);

  return (
    <div className="relative">
      <HeroSection />
      <ScrollReveal>
        <Cards products={products} setSigninClicked={setSigninClicked} />
      </ScrollReveal>
      <ScrollReveal>
        <About />
      </ScrollReveal>
      <ScrollReveal>
        <ContactUs showOtherDetails = {true}/>
      </ScrollReveal>
      <FixedButtons navRef={navRef} />
    </div>
  );
};

export default Landing;
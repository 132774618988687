import React, { useEffect, useState } from "react";
import {
  FaFileAlt,
  FaCertificate,
  FaDesktop,
  FaIdCard,
  FaPencilAlt,
  FaPassport,
} from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { useInView } from "react-intersection-observer";

const useCounter = (end, duration = 1500) => {
  const [count, setCount] = useState(0);
  const [isAnimating, setIsAnimating] = useState(false);

  useEffect(() => {
    if (!isAnimating) return;
    let startTime = null;
    const endValue = parseFloat(end);

    const animate = (currentTime) => {
      if (!startTime) startTime = currentTime;
      const progress = Math.min((currentTime - startTime) / duration, 1);
      const easeOutProgress = 1 - Math.pow(1 - progress, 3);
      const currentCount = Math.floor(easeOutProgress * endValue * 10) / 10;
      setCount(currentCount);

      if (progress < 1) {
        requestAnimationFrame(animate);
      }
    };

    requestAnimationFrame(animate);
  }, [end, duration, isAnimating]);

  return [count, setIsAnimating];
};

const StatisticCounter = ({ label, value }) => {
  const { ref, inView } = useInView({
    threshold: 0.3,
    triggerOnce: true,
  });

  const numericValue = parseFloat(value);
  const suffix = value.replace(/[\d.]/g, "");
  const [count, setIsAnimating] = useCounter(numericValue);

  useEffect(() => {
    if (inView) {
      setIsAnimating(true);
    }
  }, [inView]);

  return (
    <div
      ref={ref}
      className="p-6 bg-white rounded-lg shadow-lg transform transition-all duration-500 hover:shadow-xl hover:scale-105"
    >
      <p className="text-4xl font-bold text-blue-600 mb-2">
        {inView ? `${count.toFixed(1)}${suffix}` : "0"}
      </p>
      <p className="text-lg text-gray-700">{label}</p>
    </div>
  );
};

const services = [
  {
    name: "Document Processing",
    icon: FaFileAlt,
    description: "Efficient handling of all types of documents",
  },
  {
    name: "Certificate Applications",
    icon: FaCertificate,
    description: "Assistance with various certificate requests",
  },
  {
    name: "Online Applications",
    icon: FaDesktop,
    description: "Guidance for complex online application processes",
  },
  {
    name: "Food License Services",
    icon: FaIdCard,
    description: "Fast and hassle-free food license registration and updates.",
  },
  {
    name: "PAN Card Services",
    icon: FaPencilAlt,
    description: "New PAN card applications and corrections",
  },
  {
    name: "Passport Services",
    icon: FaPassport,
    description: "Assistance with passport applications and renewals",
  },
];

const features = [
  "Expert document handling",
  "Fast turnaround times",
  "Secure and confidential service",
  "Assistance with complex applications",
  "Multilingual support",
  "Regular status updates",
];

const testimonials = [
  {
    name: "Rajesh Kumar",
    comment:
      "RK Common Service Center made my passport application process so smooth. Highly recommended!",
  },
  {
    name: "Priya Sharma",
    comment:
      "I was amazed by their efficiency in handling my food license registration and updates. Great service!",
  },
  {
    name: "Amit Patel",
    comment:
      "Their assistance with my scholarship application was invaluable. Thank you, RK Common Service Center!",
  },
];

const statistics = [
  { label: "AADHAAR CARD UPDATED", value: "1.2K+" },
  { label: "PAN CARD APPLIED", value: "1.5K+" },
  { label: "PASSPORT APPLIED", value: "0.3K+" },
  { label: "Years on market", value: "4.2+" },
];

const ServiceCard = ({ Icon, name, description }) => {
  const [ref, inView] = useInView({
    threshold: 0.2,
    triggerOnce: true,
  });

  return (
    <div
      ref={ref}
      className={`bg-white p-8 rounded-lg shadow-lg transition-all hover:scale-105 duration-600 ${
        inView
          ? "opacity-100 transform translate-y-0"
          : "opacity-0 transform translate-y-10"
      }`}
    >
      <div className="flex flex-col items-center">
        <Icon className="text-5xl text-blue-600 mb-4 transition-transform duration-300 hover:scale-110 hover:rotate-12" />
        <h3 className="text-2xl font-semibold mb-3 text-blue-800">{name}</h3>
        <p className="text-lg text-gray-600 text-center">{description}</p>
      </div>
    </div>
  );
};

const TestimonialCard = ({ name, comment }) => {
  const [ref, inView] = useInView({
    threshold: 0.2,
    triggerOnce: true,
  });

  return (
    <div
      ref={ref}
      className={`bg-white p-8 rounded-lg shadow-lg transition-all hover:scale-105 duration-600 ${
        inView
          ? "opacity-100 transform translate-y-0"
          : "opacity-0 transform translate-y-10"
      }`}
    >
      <div className="relative">
        <div className="absolute -top-4 -left-4 text-6xl text-blue-200 opacity-50">
          "
        </div>
        <p className="text-lg text-gray-700 mb-6 relative z-10 italic">
          {comment}
        </p>
        <div className="h-px w-16 bg-blue-600 mb-4"></div>
        <p className="font-semibold text-blue-800">- {name}</p>
      </div>
    </div>
  );
};

export default function About() {
  const navigate = useNavigate();
  const [headerRef, headerInView] = useInView({
    threshold: 0.1,
    triggerOnce: true,
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="min-h-screen bg-gray-50">
      <main className="container mx-auto px-4 py-16">
        {/* About Us Section */}
        <section
          ref={headerRef}
          className={`mb-20 transition-all duration-1000 ${
            headerInView
              ? "opacity-100 transform translate-y-0"
              : "opacity-0 transform -translate-y-10"
          }`}
        >
          <div className="max-w-4xl mx-auto text-center">
            <h1 className="text-5xl font-bold text-blue-800 mb-8 relative">
              <span className="absolute -top-12 left-1/2 transform -translate-x-1/2 text-6xl text-gray-200 opacity-60">
                About Us
              </span>
              About Us
            </h1>
            <p className="text-xl leading-relaxed text-gray-700">
              Welcome to RK Common Service Center, your trusted partner for all
              types of document processing and online applications. We excel at
              ensuring flawless completion of all types of Documents,
              Certificates & various other online applications, guaranteeing
              100% accuracy every time.
            </p>
          </div>
        </section>

        {/* Services Grid */}
        <section className="mb-20">
          <h2 className="text-4xl font-bold text-blue-800 text-center mb-12">
            Our Services
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {services.map((service, index) => (
              <ServiceCard
                key={index}
                Icon={service.icon}
                name={service.name}
                description={service.description}
              />
            ))}
          </div>
        </section>

        {/* Statistics Section */}
        <section className="mb-20 bg-blue-50 py-16 px-4 rounded-2xl">
          <h2 className="text-4xl font-bold text-blue-800 text-center mb-12">
            Our Impact
          </h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8">
            {statistics.map((stat, index) => (
              <StatisticCounter
                key={index}
                label={stat.label}
                value={stat.value}
              />
            ))}
          </div>
        </section>

        <section className="mb-20">
          <h2 className="text-4xl font-bold text-blue-800 text-center mb-12">
            What Our Clients Say
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {testimonials.map((testimonial, index) => (
              <TestimonialCard
                key={index}
                name={testimonial.name}
                comment={testimonial.comment}
              />
            ))}
          </div>
        </section>

        <section className="text-center">
          <div className="bg-gradient-to-r from-blue-600 to-indigo-600 p-12 rounded-2xl shadow-2xl">
            <h2 className="text-4xl font-bold text-white mb-6">
              Ready to Get Started?
            </h2>
            <p className="text-xl text-gray-200 mb-8">
              Let us handle your document needs with precision and care.
            </p>
            <button
              onClick={() => navigate("/contact")}
              className="bg-white text-blue-600 py-4 px-8 rounded-lg text-lg font-semibold 
                         transition-all duration-300 hover:bg-blue-50 hover:shadow-lg 
                         transform hover:scale-105"
            >
              Contact Us Now
            </button>
          </div>
        </section>
      </main>
    </div>
  );
}

import React, { useContext, useEffect, useState } from "react";
import Core from "../core/Core";
import AppContext from "../AppContext/AppContext";
import { Link } from "react-router-dom";
import totalRevenue from "../assets/totalRevenue.png";
import productsAll from "../assets/productsAll.png";
import dahsboardUsers from "../assets/dahsboardUsers.png";
import ordersDashoard from "../assets/ordersDashoard.png";
import totalOrder from "../assets/totalOrder.png";
import memberShip from "../assets/memberShip.png";

const Dashboard = () => {
  const { handleDashBoeard } = Core();
  const appContext = useContext(AppContext);
  const [allOrders, setAllOrders] = useState(0);
  const [completedOrders, setCompletedOrders] = useState(0);
  const [allProducts, setAllProducts] = useState(0);
  const [allMembers, setAllMembers] = useState(0);
  const [allAdmins, setAllAdmins] = useState(0);
  const [allPseudoAdmins, setAllPseudoAdmins] = useState(0);
  const [totalrevenue, setTotalRevenue] = useState(0);

  const [dataDashboard, setDataDashboard] = useState(null);

  const dashBoardData = async () => {
    try {
      const response = await handleDashBoeard(appContext.userInfoVal.sessionId);
      if (response) {
        console.log("all orders", response);
        setDataDashboard(response);
      }
    } catch (error) {
      console.error(error);
    } finally {
    }
  };

  useEffect(() => {
    dashBoardData();
  }, []);

  return (
    <div className="h-[55vh] w-full px-[4%]">
      <div className="flex gap-3 w-full justify-end mt-5 items-center">
        <span className=" text-xl font-bold ">
          Hii {appContext.userInfoVal.firstName.trim().split(" ")[0]}
        </span>
        <Link to="/" className="px-3 py-2 font-semibold bg-blue-600/90 rounded-lg">
          User Panel
        </Link>
      </div>

      <div className="flex flex-wrap gap-[5%] justify-center items-center w-full h-full mt-10">
        <div className="w-[33%] h-[55%] bg-blue-400/90 hover:scale-105 transition-all duration-200 flex flex-col rounded-lg justify-center items-center">
          <div>
            <span className="text-2xl font-bold flex justify-center items-center gap-2">
              <img src={ordersDashoard} className="w-10 h-10" />
              Total Orders
            </span>
            <span className="flex justify-center items-center text-4xl font-bold">
              {dataDashboard?.[0]?.totalOrders || 0}
            </span>
          </div>
          <div>
            <span className="text-2xl font-bold flex justify-center items-center gap-2">
              <img src={totalOrder} className="w-10 h-10" />
              Completed Orders
            </span>
            <span className="flex justify-center items-center text-4xl font-bold">
              {dataDashboard?.[0]?.completedOrders || 0}
            </span>
          </div>
        </div>

        <div className="w-[33%] h-[55%]  gap-5  bg-green-500/90 hover:scale-105 transition-all duration-200 flex-col rounded-lg flex justify-center items-center">
          <div>
            <span className="text-2xl font-bold flex justify-center items-center gap-2">
              <img src={dahsboardUsers} className="w-10 h-10" />
              Total Users
            </span>
            <span className="flex justify-center items-center text-4xl font-bold">
              {dataDashboard?.[0]?.usersByAccountType?.find(
                (type) => type.accountType === "user"
              )?.count || 0}
            </span>
          </div>
          <div>
            <span className="text-2xl font-bold flex justify-center items-center gap-2">
              <img src={memberShip} className="w-10 h-10" />
              Total Members
            </span>
            <span className="flex justify-center items-center text-4xl font-bold">
              {dataDashboard?.[0]?.usersByAccountType?.find(
                (type) => type.accountType === "member"
              )?.count || 0}
            </span>
          </div>
        </div>

        <div className="w-[33%] h-[55%] mt-10  bg-orange-400/90 hover:scale-105 transition-all duration-200 flex-col rounded-lg flex justify-center items-center">
          <div>
            <span className="text-2xl font-bold flex justify-center items-center gap-2">
              <img src={totalRevenue} className="w-10 h-10" />
              Total Revenue
            </span>
            <span className="flex  justify-center items-center text-4xl font-bold">
              {dataDashboard?.[0]?.totalRevenue || 0}
            </span>
          </div>
        </div>

        <div className="w-[33%] h-[55%] mt-10 hover:scale-105 transition-all duration-200 bg-red-400/90 flex-col rounded-lg flex justify-center items-center">
          <div>
            <span className="text-2xl font-bold flex justify-center items-center gap-2">
              <img src={productsAll} className="w-10 h-10" />
              Total Products
            </span>
            <span className="flex justify-center items-center text-4xl font-bold">
              {dataDashboard?.[0]?.totalProducts  - 2 || 0}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;

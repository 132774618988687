import React, { useState, useRef } from "react";
import {
  AiOutlineEye,
  AiOutlineEyeInvisible,
  AiOutlineClose,
} from "react-icons/ai";
import { motion, AnimatePresence } from "framer-motion";
import logo from "../assets/LogoRkcsc.png";
import Core from "../core/Core";
import { toast } from "react-toastify";

const ModalLoginSignup = ({
  setSigninClicked,
  setShowGlobalLoader,
  setShowNotification,
  setFailedNoti,
}) => {
  const notify = (text, time) => {
    toast.dismiss();

    toast.info(text, {
      toastId: "random1",
      closeButton: false,
      position: "top-center",
      autoClose: time ? time : 1500,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      style: {
        // width: "400px",
        // maxWidth: "90vw",
        padding: "15px 20px",
        fontSize: "16px",
        textAlign: "center",
        fontWeight: "500",
      },
    });
  };
  const { loginEmailPass, signupEmailPass, handleForgotPass } = Core();
  const [showPassword, setShowPassword] = useState(false);
  const [activeTab, setActiveTab] = useState("login");
  const [fileName, setFileName] = useState(null);

  const fNameInputRef = useRef(null);
  const phoneInputRef = useRef(null);
  const emailInputRef = useRef(null);
  const signupPassInputRef = useRef(null);
  const userNameInputRef = useRef(null);
  const loginPassRef = useRef(null);
  const firmNameRef = useRef(null);
  const docsRef = useRef(null);

  const handleLogin = (e) => {
    e.preventDefault();
    const email = userNameInputRef.current?.value;
    const password = loginPassRef.current?.value;

    if (email && password) {
      loginEmailPass(
        email,
        password,
        setSigninClicked,
        setShowGlobalLoader,
        setShowNotification,
        setFailedNoti
      );
    } else {
      notify("Please fill all fields", 2000);
    }
  };

  const handleSignUp = (e) => {
    e.preventDefault();
    const firstName = fNameInputRef.current?.value;
    const phone = phoneInputRef.current?.value;
    const email = emailInputRef.current?.value;
    const password = signupPassInputRef.current?.value;
    const firmName = firmNameRef.current?.value;
    const docs = docsRef.current?.files?.[0];

    if (activeTab === "partner" && !docs) {
      notify("Please upload required documents", 2000);
      return;
    }

    if (firstName && phone && email && password) {
      signupEmailPass(
        firstName,
        phone,
        email,
        password,
        firmName,
        docs,
        setSigninClicked,
        setShowGlobalLoader
      );
    } else {
      notify("Please fill all required fields", 2000);
    }
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setFileName(file.name);
    }
  };

  const overlayVariants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1 },
    exit: { opacity: 0 },
  };

  const modalVariants = {
    hidden: {
      scale: 0.8,
      opacity: 0,
      y: 20,
    },
    visible: {
      scale: 1,
      opacity: 1,
      y: 0,
      transition: {
        type: "spring",
        duration: 0.5,
        bounce: 0.4,
      },
    },
    exit: {
      scale: 0.8,
      opacity: 0,
      y: 20,
      transition: {
        duration: 0.3,
      },
    },
  };

  const contentVariants = {
    hidden: {
      opacity: 0,
      x: -20,
    },
    visible: {
      opacity: 1,
      x: 0,
      transition: {
        duration: 0.3,
      },
    },
    exit: {
      opacity: 0,
      x: 20,
      transition: {
        duration: 0.3,
      },
    },
  };

  return (
    <AnimatePresence>
      <motion.div
        className="fixed inset-0 z-50 flex items-center justify-center bg-black/50 p-4"
        variants={overlayVariants}
        initial="hidden"
        animate="visible"
        exit="exit"
      >
        <motion.div
          className="relative w-full max-w-md max-h-[90vh] overflow-y-auto rounded-2xl bg-gradient-to-br from-white via-blue-50 to-blue-100 shadow-xl"
          variants={modalVariants}
          initial="hidden"
          animate="visible"
          exit="exit"
        >
          <button
            onClick={() => setSigninClicked(false)}
            className="absolute right-3 top-3 z-50 text-gray-500 hover:text-gray-700"
          >
            <AiOutlineClose size={20} />
          </button>

          <div className="sticky top-0 flex items-center justify-center border-b border-gray-100 bg-gray-50/50 px-4 py-3 z-10">
            <img src={logo} alt="RK Logo" className="h-10" />
          </div>

          <AnimatePresence mode="wait">
            {activeTab === "login" ? (
              <motion.div
                key="login"
                variants={contentVariants}
                initial="hidden"
                animate="visible"
                exit="exit"
                className="px-4 py-3"
              >
                <h2 className="mb-4 text-center text-xl font-semibold text-gray-800">
                  Welcome Back
                </h2>
                <form onSubmit={handleLogin} className="space-y-3">
                  <input
                    ref={userNameInputRef}
                    type="text"
                    placeholder="Email or Phone"
                    style={{ border: "1px solid black" }}
                    className="w-full rounded-lg border border-gray-800 px-3 py-2 text-sm focus:border-blue-500 focus:outline-none"
                  />
                  <div className="relative">
                    <input
                      ref={loginPassRef}
                      type={showPassword ? "text" : "password"}
                      placeholder="Password"
                      style={{ border: "1px solid black" }}
                      className="w-full rounded-lg border border-gray-800 px-3 py-2 text-sm focus:border-blue-500 focus:outline-none"
                    />
                    <button
                      type="button"
                      onClick={() => setShowPassword(!showPassword)}
                      className="absolute right-2 top-1/2 -translate-y-1/2 text-gray-600"
                    >
                      {showPassword ? (
                        <AiOutlineEyeInvisible size={16} />
                      ) : (
                        <AiOutlineEye size={16} />
                      )}
                    </button>
                  </div>
                  <button
                    type="submit"
                    className="w-full rounded-lg bg-blue-600 py-2 text-sm font-medium text-white transition-colors hover:bg-blue-700"
                  >
                    Sign In
                  </button>
                </form>
                <div className="mt-4 text-center">
                  <p className="text-sm text-gray-600">
                    Don't have an account?{" "}
                    <button
                      onClick={() => setActiveTab("signup")}
                      className="font-medium text-base text-blue-600 hover:text-blue-700"
                    >
                      Sign Up
                    </button>
                  </p>
                </div>
              </motion.div>
            ) : (
              <motion.div
                key="signup"
                variants={contentVariants}
                initial="hidden"
                animate="visible"
                exit="exit"
                className="px-4 py-3"
              >
                <div className="mb-4">
                  <motion.div
                    className="flex rounded-lg bg-gray-100 p-1"
                    layout
                  >
                    <motion.button
                      onClick={() => setActiveTab("signup")}
                      className={`flex-1 rounded-md px-3 py-1.5 text-sm font-medium transition-colors ${
                        activeTab === "signup"
                          ? "bg-white text-blue-600 shadow-sm"
                          : "text-gray-600 hover:bg-gray-200"
                      }`}
                      layout
                    >
                      Sign Up
                    </motion.button>
                    <motion.button
                      onClick={() => setActiveTab("partner")}
                      className={`flex-1 rounded-md px-3 py-1.5 text-sm font-medium transition-colors ${
                        activeTab === "partner"
                          ? "bg-white text-blue-600 shadow-sm"
                          : "text-gray-600 hover:bg-gray-200"
                      }`}
                      layout
                    >
                      Partner
                    </motion.button>
                  </motion.div>
                </div>

                <AnimatePresence mode="wait">
                  <motion.form
                    key={activeTab}
                    variants={contentVariants}
                    initial="hidden"
                    animate="visible"
                    exit="exit"
                    onSubmit={handleSignUp}
                    className="space-y-3"
                  >
                    <input
                      ref={fNameInputRef}
                      type="text"
                      placeholder="Full Name"
                      style={{ border: "1px solid black" }}
                      className="w-full rounded-lg border border-gray-200 px-3 py-2 text-sm focus:border-blue-500 focus:outline-none"
                    />
                    <input
                      ref={phoneInputRef}
                      type="tel"
                      placeholder="Phone Number"
                      style={{ border: "1px solid black" }}
                      className="w-full rounded-lg border border-gray-200 px-3 py-2 text-sm focus:border-blue-500 focus:outline-none"
                    />
                    <input
                      ref={emailInputRef}
                      type="email"
                      placeholder="Email Address"
                      style={{ border: "1px solid black" }}
                      className="w-full rounded-lg border border-gray-200 px-3 py-2 text-sm focus:border-blue-500 focus:outline-none"
                    />
                    {activeTab === "partner" && (
                      <input
                        ref={firmNameRef}
                        type="text"
                        placeholder="Firm Name"
                        style={{ border: "1px solid black" }}
                        className="w-full rounded-lg border border-gray-200 px-3 py-2 text-sm focus:border-blue-500 focus:outline-none"
                      />
                    )}
                    <div className="relative">
                      <input
                        ref={signupPassInputRef}
                        type={showPassword ? "text" : "password"}
                        style={{ border: "1px solid black" }}
                        placeholder="Password"
                        className="w-full rounded-lg border border-gray-200 px-3 py-2 text-sm focus:border-blue-500 focus:outline-none"
                      />
                      <button
                        type="button"
                        onClick={() => setShowPassword(!showPassword)}
                        className="absolute right-2 top-1/2 -translate-y-1/2 text-gray-600"
                      >
                        {showPassword ? (
                          <AiOutlineEyeInvisible size={16} />
                        ) : (
                          <AiOutlineEye size={16} />
                        )}
                      </button>
                    </div>

                    {activeTab === "partner" && (
                      <div className="relative">
                        <input
                          ref={docsRef}
                          type="file"
                          onChange={handleFileChange}
                          className="hidden"
                          id="document-upload"
                        />
                        <label
                          htmlFor="document-upload"
                          className="flex w-full cursor-pointer items-center justify-center rounded-lg border border-gray-200 px-3 py-2 text-sm text-gray-600 transition-colors hover:bg-gray-50"
                        >
                          {fileName || "Upload Required Documents"}
                        </label>
                      </div>
                    )}

                    <button
                      type="submit"
                      className="w-full rounded-lg bg-blue-600 py-2 text-sm font-medium text-white transition-colors hover:bg-blue-700"
                    >
                      {activeTab === "partner"
                        ? "Join as Partner"
                        : "Create Account"}
                    </button>
                  </motion.form>

                  <div className="mt-4 text-center">
                    <p className="text-sm text-gray-600">
                      Already have an account?{" "}
                      <button
                        onClick={() => setActiveTab("login")}
                        className="font-medium text-base text-blue-600 hover:text-blue-700"
                      >
                        Sign In
                      </button>
                    </p>
                  </div>
                </AnimatePresence>
              </motion.div>
            )}
          </AnimatePresence>
        </motion.div>
      </motion.div>
    </AnimatePresence>
  );
};

export default ModalLoginSignup;



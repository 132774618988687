import React, { useEffect, useContext, useState } from "react";
import ProfileTabs from "./ProfileTabs";
import Core from "../core/Core";
import AppContext from "../AppContext/AppContext";
import UpdateProfile from "./UpdateProfile";

const ProfileSection = ({
  setShowAddeWallet,
  showAddWallet,
  isClickedAccount,
  setIsClickedAccount,
  isClickedBilling,
  setIsClickedBilling,
  isClickedSupport,
  setIsClickedSupport,
  isWalletClicked,
  setIsWalletClicked,
}) => {
  const { getUserData } = Core();
  const appContext = useContext(AppContext);
  const [showUpdateProfile, setShowUpdateProfile] = useState(false);

  const handleGetUserData = async () => {
    try {
      const response = await getUserData(appContext.userInfoVal.sessionId);

      if (response) {
        console.log("get profile response", response);
        appContext.setUserInfo((prev) => ({
          ...prev,
          firstName: response?.userData?.firstName,
          phoneNumber: response?.userData?.phoneNumber,
          email: response?.userData?.email,
          picture: response?.userData?.photoUrl,
          username: response?.userData?.username,
          accountType: response?.userData?.accountType,
          orders: response?.orderData,
          userId: response?.userData?._id,
        }));
        if (response?.userData?.accountType === "member") {
          appContext.setUserInfo((prev) => ({
            ...prev,
            wallet: response?.userData?.wallet,
            firmName: response?.userData?.firmName,
            walletHistory: response?.walletData,
          }));
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    handleGetUserData();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className="relative">
        <div className="text-center pb-2 mt-10 px-0 mr-[4%] relative">
          <h1 className="text-5xl font-bold text-blue-800 mb-8 relative">
            <span className="absolute -top-12 left-1/2 transform -translate-x-1/2 text-6xl text-gray-200 opacity-70">
              Profile
            </span>
            Profile
          </h1>
        </div>
        <div
          className=" text-slate-100 font-semibold text-md  
        flex flex-col gap-4 px-[2.5%] py-6
        backdrop  bg-opacity-10 bg-gradient-to-b from-gray-700/20 to-gray-950/20 "
        >
          <ProfileTabs
            setShowAddeWallet={setShowAddeWallet}
            showAddWallet={showAddWallet}
            showUpdateProfile={showUpdateProfile}
            setShowUpdateProfile={setShowUpdateProfile}
            isClickedAccount={isClickedAccount}
            setIsClickedAccount={setIsClickedAccount}
            isClickedBilling={isClickedBilling}
            setIsClickedBilling={setIsClickedBilling}
            isClickedSupport={isClickedSupport}
            setIsClickedSupport={setIsClickedSupport}
            isWalletClicked={isWalletClicked}
            setIsWalletClicked={setIsWalletClicked}
          />
        </div>
        {showUpdateProfile && (
          <UpdateProfile
            showUpdateProfile={showUpdateProfile}
            setShowUpdateProfile={setShowUpdateProfile}
          />
        )}
      </div>
    </>
  );
};

export default ProfileSection;

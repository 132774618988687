import React, { useContext } from "react";
import AppContext from "../AppContext/AppContext";
import clsx from "clsx";

const Orders = () => {
  const appContext = useContext(AppContext);

  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleDateString("en-IN", {
      day: "2-digit",
      month: "long",
      year: "numeric",
    });
  };

  const capitalizeFirst = (str) => {
    return str ? str.charAt(0).toUpperCase() + str.slice(1) : "";
  };

  const formatURL = (url) => {
    if (!url) return ""; 
    if (url.startsWith('http://') || url.startsWith('https://')) {
      return url;
    } 
    return `https://${url}`;
  };

  const renderStatusCell = (status, statusType) => {
    const statusClassMap = {
      Processing: "text-yellow-800 bg-yellow-200",
      Cancelled: "text-red-800 bg-red-200",
      Completed: "text-green-800 bg-green-200",
      Refunded: "text-blue-800 bg-blue-200",
      Incompleted: "text-gray-50 bg-stone-700",
      PAYMENT_INITIATED: "text-yellow-800 bg-yellow-200",
      PAYMENT_ERROR: "text-red-800 bg-red-200",
      PAYMENT_SUCCESS: "text-green-800 bg-green-200",
    };

    let displayStatus = status;
    if (statusType === "order" && status === "Pending") {
      displayStatus = "Processing";
    }

    return (
      <div className="bg-opacity-50 h-6 rounded-md flex justify-center items-center text-center">
        <p
          className={clsx(
            "rounded-md p-1 xl:text-base text-sm",
            statusClassMap[displayStatus]
          )}
        >
          {displayStatus}
        </p>
      </div>
    );
  };

  return (
    <div className="w-full h-full mx-auto relative">
      <table className="w-full h-[10%]  table-auto text-sm sm:text-base border border-gray-300">
        <thead className="bg-gradient-to-r from-blue-600 to-blue-700 sticky top-0 z-50 ">
          <tr>
            <th className="px-4 py-3">Serial No</th>
            <th className="px-4 py-2">Date</th>
            <th className="px-4 py-2">Service</th>
            <th className="px-4 py-2">Applicant Name</th>
            <th className="px-4 py-2">Amount</th>

            <th className="px-4 py-2">Payment Status</th>
            <th className="px-4 py-2">Order Status</th>
            <th className="px-4 py-2">Acknowledgements</th>
            <th className="px-4 py-2">Check Status</th>
            <th className="px-4 py-2">Downloads</th>
            <th className="px-4 py-2">Message</th>
          </tr>
        </thead>
        <tbody className="overflow-y-auto max-h-[90%] text-black">
          {appContext.userInfoVal.orders?.length > 0 ? (
            appContext.userInfoVal.orders
            .map((order, index) => (
              <tr
                key={index}
                className="bg-transparent hover:bg-[#f0eeee37] transition-all duration-300"
              >
                <td className="px-4 py-4 text-center">{appContext.userInfoVal.orders?.length - index }</td>
                <td className="px-4 py-4 text-center">
                  {formatDate(order?.createdAt)}
                </td>
                <td className="px-4 py-4 text-center">
                  {capitalizeFirst(order?.productList?.productName)}
                </td>
                <td className="px-4 py-4 text-center">
                  {order?.applicantFullName || ""}
                </td>
                <td className="px-4 py-4 text-center">
                  {order?.paymentData?.amount
                    ? `INR ${order.paymentData.amount}`
                    : ""}
                </td>

                <td className="px-4 py-4 text-center">
                  <div className="flex flex-col gap-3">
                    {renderStatusCell(order?.paymentData?.status, "payment")}

                    {order?.invoice?.length > 0 &&
                      order.invoice.map((inv, idx) => (
                        <a
                          href={inv?.url}
                          target="_blank"
                          rel="noopener noreferrer"
                          key={idx}
                          className="bg-blue-700 xl:text-sm text-xs text-white px-3 py-2 rounded-lg hover:bg-blue-800"
                        >
                          {inv?.name}
                        </a>
                      ))}
                  </div>
                </td>
                <td className="px-4 py-4 text-center">
                  {renderStatusCell(order.Status, "order")}
                </td>
                <td className="px-4 py-4 text-center">
                  <div className="flex flex-col gap-2">
                    {order?.acknowledgements?.length > 0 ? (
                      order.acknowledgements.map((ack, idx) => (
                        <a
                          href={ack?.url}
                          key={idx}
                          className="bg-blue-700 text-sm text-white px-3 py-2 rounded-lg hover:bg-blue-800"
                        >
                          {capitalizeFirst(ack.name)}
                        </a>
                      ))
                    ) : (
                      <span>-</span>
                    )}
                  </div>
                </td>
                <td className="px-4 py-4 text-center">
                  {order?.check_status ? (
                    <a
                       href={formatURL(order.check_status)}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-blue-600 underline hover:text-blue-800"
                    >
                      Link
                    </a>
                  ) : (
                    <span>-</span>
                  )}
                </td>
                <td className="px-4 py-4 text-center">
                <div className="flex flex-col gap-2">
                    {order?.downloads?.length > 0 ? (
                      order.downloads.map((download, idx) => (
                        <a
                          href={download?.url}
                          key={idx}
                          className="bg-blue-700 text-sm text-white px-3 py-2 rounded-lg hover:bg-blue-800"
                        >
                          {download?.name || "Download"}
                        </a>
                      ))
                    ) : (
                      <span>-</span>
                    )}
                  </div>
                </td>

                <td className="px-4 py-4 text-center">
                  {order?.message || "-"}
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={12} className="px-4 py-2 text-lg text-center">
                No orders
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default Orders;

// import React, { useContext, useState } from "react";
// import AppContext from "../AppContext/AppContext";
// import { ChevronDown, FileText, Download, ExternalLink, MessageCircle, ChevronRight } from "lucide-react";

// const Orders = () => {
//   const appContext = useContext(AppContext);
//   const [sortConfig, setSortConfig] = useState({ key: null, direction: 'asc' });
//   const [expandedRow, setExpandedRow] = useState(null);

//   const formatDate = (dateString) => {
//     return new Date(dateString).toLocaleDateString("en-IN", {
//       day: "2-digit",
//       month: "long",
//       year: "numeric",
//     });
//   };

//   const capitalizeFirst = (str) => {
//     return str ? str.charAt(0).toUpperCase() + str.slice(1) : "";
//   };

//   const statusClassMap = {
//     Processing: "text-yellow-800 bg-yellow-100 border border-yellow-300",
//     Cancelled: "text-red-800 bg-red-100 border border-red-300",
//     Completed: "text-green-800 bg-green-100 border border-green-300",
//     Refunded: "text-blue-800 bg-blue-100 border border-blue-300",
//     Incompleted: "text-gray-800 bg-gray-100 border border-gray-300",
//     PAYMENT_INITIATED: "text-yellow-800 bg-yellow-100 border border-yellow-300",
//     PAYMENT_ERROR: "text-red-800 bg-red-100 border border-red-300",
//     PAYMENT_SUCCESS: "text-green-800 bg-green-100 border border-green-300",
//   };

//   const renderStatusCell = (status, statusType) => {
//     let displayStatus = status;
//     if (statusType === "order" && status === "Pending") {
//       displayStatus = "Processing";
//     }

//     return (
//       <div className="flex justify-center items-center">
//         <span
//           className={`px-3 py-1 rounded-full text-sm font-medium ${
//             statusClassMap[displayStatus]
//           } transition-all duration-300 hover:opacity-80`}
//         >
//           {displayStatus}
//         </span>
//       </div>
//     );
//   };

//   const handleSort = (key) => {
//     let direction = 'asc';
//     if (sortConfig.key === key && sortConfig.direction === 'asc') {
//       direction = 'desc';
//     }
//     setSortConfig({ key, direction });
//   };

//   const getSortedOrders = () => {
//     if (!sortConfig.key) return appContext.userInfoVal.orders;

//     return [...appContext.userInfoVal.orders].sort((a, b) => {
//       if (sortConfig.key === 'date') {
//         return sortConfig.direction === 'asc'
//           ? new Date(a.createdAt) - new Date(b.createdAt)
//           : new Date(b.createdAt) - new Date(a.createdAt);
//       }
//       return sortConfig.direction === 'asc'
//         ? a[sortConfig.key]?.localeCompare(b[sortConfig.key])
//         : b[sortConfig.key]?.localeCompare(a[sortConfig.key]);
//     });
//   };

//   const ActionButton = ({ href, children, icon: Icon }) => (
//     <a
//       href={href}
//       target="_blank"
//       rel="noopener noreferrer"
//       className="inline-flex items-center px-3 py-2 text-sm font-medium text-white bg-blue-600 rounded-md hover:bg-blue-700 transition-colors duration-200 gap-2"
//     >
//       <Icon size={16} />
//       {children}
//     </a>
//   );

//   return (
//     <div className="w-full h-full bg-white rounded-lg shadow-lg overflow-hidden flex flex-col">
//       <div className="bg-gray-50 p-4 border-b border-gray-200">
//         <p className="text-sm text-gray-600 mt-1">
//           Click on any order row to view detailed information
//         </p>
//       </div>

//       <div className="flex-1 overflow-hidden">
//         <div className="overflow-x-auto overflow-y-auto h-full relative">
//           <table className="w-full">
//             <thead className="bg-blue-50 border-b border-gray-200 sticky top-0 z-50">
//               <tr>
//                 <th className="px-6 py-5 text-left bg-blue-50">
//                   <button
//                     onClick={() => handleSort('serialNo')}
//                     className="flex items-center gap-1 hover:text-gray-700 text-lg font-semibold text-gray-800"
//                   >
//                     #
//                     <ChevronDown size={20} className={`transform transition-transform ${
//                       sortConfig.key === 'serialNo' && sortConfig.direction === 'desc' ? 'rotate-180' : ''
//                     }`} />
//                   </button>
//                 </th>
//                 <th className="px-6 py-5 text-left bg-blue-50">
//                   <button
//                     onClick={() => handleSort('date')}
//                     className="flex items-center gap-1 hover:text-gray-700 text-lg font-semibold text-gray-800"
//                   >
//                     Date
//                     <ChevronDown size={20} className={`transform transition-transform ${
//                       sortConfig.key === 'date' && sortConfig.direction === 'desc' ? 'rotate-180' : ''
//                     }`} />
//                   </button>
//                 </th>
//                 <th className="px-6 py-5 text-left text-lg font-semibold text-gray-800 bg-blue-50">Service</th>
//                 <th className="px-6 py-5 text-left text-lg font-semibold text-gray-800 bg-blue-50">Status</th>
//                 <th className="px-6 py-5 text-left text-lg font-semibold text-gray-800 bg-blue-50">Payment</th>
//                 <th className="px-6 py-5 text-left text-lg font-semibold text-gray-800 bg-blue-50">Actions</th>
//               </tr>
//             </thead>
//             <tbody className="bg-white divide-y divide-gray-200">
//               {/* Rest of the table body remains the same */}
//               {getSortedOrders()?.length > 0 ? (
//                 getSortedOrders().map((order, index) => (
//                   <React.Fragment key={index}>
//                     <tr
//                       className={`hover:bg-gray-50 transition-colors duration-200 cursor-pointer group ${
//                         expandedRow === index ? 'bg-gray-50' : ''
//                       }`}
//                       onClick={() => setExpandedRow(expandedRow === index ? null : index)}
//                     >
//                       <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 flex items-center gap-2">
//                         <ChevronRight
//                           size={20}
//                           className={`transform transition-transform duration-200 text-gray-400 group-hover:text-gray-600 ${
//                             expandedRow === index ? 'rotate-90' : ''
//                           }`}
//                         />
//                         {index + 1}
//                       </td>
//                       <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
//                         {formatDate(order?.createdAt)}
//                       </td>
//                       <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
//                         {capitalizeFirst(order?.productList?.productName)}
//                       </td>
//                       <td className="px-6 py-4 whitespace-nowrap">
//                         {renderStatusCell(order.Status, "order")}
//                       </td>
//                       <td className="px-6 py-4 whitespace-nowrap">
//                         {renderStatusCell(order?.paymentData?.status, "payment")}
//                       </td>
//                       <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
//                         <div className="flex gap-2">
//                           {order?.downloads?.length > 0 && (
//                             <ActionButton href={order.downloads[0].url} icon={Download}>
//                               Download
//                             </ActionButton>
//                           )}
//                           {order?.check_status && (
//                             <ActionButton href={order.check_status} icon={ExternalLink}>
//                               Check Status
//                             </ActionButton>
//                           )}
//                         </div>
//                       </td>
//                     </tr>
//                     {expandedRow === index && (
//                       <tr className="bg-gray-50 animate-fadeIn">
//                         <td colSpan={6} className="px-6 py-4">
//                           <div className="grid grid-cols-2 gap-4">
//                             <div>
//                               <h4 className="font-medium text-gray-900 mb-2">Order Details</h4>
//                               <p className="text-sm text-gray-500">Applicant: {order?.applicantFullName}</p>
//                               <p className="text-sm text-gray-500">Amount: {order?.paymentData?.amount ? `INR ${order.paymentData.amount}` : '-'}</p>
//                               {order?.message && (
//                                 <div className="mt-2">
//                                   <h4 className="font-medium text-gray-900">Message</h4>
//                                   <p className="text-sm text-gray-500">{order.message}</p>
//                                 </div>
//                               )}
//                             </div>
//                             <div>
//                               <h4 className="font-medium text-gray-900 mb-2">Documents</h4>
//                               <div className="space-x-2">
//                                 {order?.acknowledgements?.map((ack, idx) => (
//                                   <ActionButton key={idx} href={ack.url} icon={FileText}>
//                                     {capitalizeFirst(ack.name)}
//                                   </ActionButton>
//                                 ))}
//                                 {order?.invoice?.map((inv, idx) => (
//                                   <ActionButton key={idx} href={inv.url} icon={FileText}>
//                                     {inv.name || "Invoice"}
//                                   </ActionButton>
//                                 ))}
//                               </div>
//                             </div>
//                           </div>
//                         </td>
//                       </tr>
//                     )}
//                   </React.Fragment>
//                 ))
//               ) : (
//                 <tr>
//                   <td colSpan={6} className="px-6 py-4 text-center text-sm text-gray-500">
//                     No orders found
//                   </td>
//                 </tr>
//               )}
//             </tbody>
//           </table>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Orders;

import React, { useEffect, useState, useContext } from "react";
import ProductCards from "./ProductCards";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import Core from "../core/Core";
import AppContext from "../AppContext/AppContext";
import ServicesHeader from "./ServiceHeader";
import AnimatedViewMore from "./ui/AnimatedViewMore";

const AllServices = () => {
  const location = useLocation();
  const appContext = useContext(AppContext);
  const [allServices, setAllServices] = useState([]);
  const [filteredServices, setFilteredServices] = useState([]);

  useEffect(() => {
  window.scrollTo(0, 0);
}, [location.pathname]);

  useEffect(() => {
    setFilteredServices(allServices);
  }, [allServices]);

  const { getAllProducts } = Core();

  const handleGetAllProducts = async () => {
    try {
      const response = await getAllProducts(30, 1);
      console.log(response?.ProductList);
      setAllServices(response?.ProductList);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    handleGetAllProducts();
  }, []);

  const handleSearch = (searchTerm) => {
    const filtered = allServices.filter(
      (service) =>
        service.productName.toLowerCase().includes(searchTerm.toLowerCase()) ||
        service.description.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredServices(filtered);
  };

  const handleViewMore = () => {
    console.log("View more clicked");
  };

  return (
    <div className=" relative mx-auto px-6 bg-gradient-to-br from-white via-blue-50 to-blue-100 pt-4 rounded-xl shadow-xl">
      <ServicesHeader onSearch={handleSearch} />
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4 gap-8">
        {filteredServices && filteredServices.map((product, index) => (
          <Link
            to="/product"
            key={index}
            onClick={() => {
              appContext.setProductInfo((prev) => ({
                ...prev,
                description: product.description,
                image: product.image,
                member_price: product.member_price,
                price: product.price,
                processFlow: product.processFlow,
                productName: product.productName,
                requiredDocs: product.requiredDocs,
                _id: product._id,
              }));
            }}
          >
            <ProductCards product={product} />
          </Link>
        ))}
      </div>

      <AnimatedViewMore onClick={handleViewMore} />
    </div>
  );
};

export default AllServices;

import React, { useContext, useState } from "react";
import AppContext from "../AppContext/AppContext";
import clsx from "clsx";
import editOrder from "../assets/editOrder.png";
import Core from "../core/Core";
import { toast } from "react-toastify";

const AllOrdersTable = ({
  allOrders,
  showDetails,
  setShowDetails,
  setOrderedByDetails,
  setShowOrderedBy,
  setSelectedOrder,
  setIsUpdated,
  setOrderSerialNo,
}) => {
  const { deleteAndVerify, getUserDataAdmin } = Core();

  const notify = (text, time) => {
    toast.dismiss();

    toast.info(text, {
      toastId: "random1",
      closeButton: false,
      position: "top-center",
      autoClose: time ? time : 1500,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      style: {
        // width: "400px",
        // maxWidth: "90vw",
        padding: "15px 20px",
        fontSize: "16px",
        textAlign: "center",
        fontWeight: "500",
      },
    });
  };

  const handleGetOrderedBy = async (userId) => {
    try {
      const response = await getUserDataAdmin(userId);

      if (response) {
        console.log("get profile response", response);
        setOrderedByDetails(response?.userData);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="w-full h-full  mx-auto">
      <div className="custom-scrollbar h-full">
        <table className="w-full h-[10%]  text-sm sm:text-base ">
          <thead className="bg-blue-500 text-gray-300 sticky top-0 z-10 text-lg">
            <tr>
              <th className="px-4 py-3">Serial No</th>
              <th className="px-4 py-3">Order Id</th>
              <th className="px-2 py-2">Date</th>
              <th className="px-2 py-2">Name</th>
              <th className="px-2 py-2">Email</th>
              <th className="px-2 py-2">Phone</th>
              <th className="px-2 py-2">Ordered Item</th>
              <th className="px-2 py-2">Amount</th>
              <th className="px-2 py-2">Order status</th>
              <th className="px-2 py-2">Payment status</th>
              <th className="px-2 py-2">Order Details</th>
            </tr>
          </thead>
          <tbody tbody className="overflow-y-auto max-h-[90%] text-black">
            {allOrders?.length > 0 ? (
              allOrders.slice().map((orders, index) => (
                <tr
                  key={index}
                  className="bg-transparent hover:bg-[#f5f5f537] transition-all duration-300"
                >
                  <td className="px-2 py-4 text-center">{index + 1}</td>
                  <td className="px-2 py-4 text-center">
                    {orders?.merchant_txn_id}
                  </td>

                  <td className="px-2 py-4 text-center">
                    {new Date(orders?.createdAt).toLocaleDateString("en-IN", {
                      day: "2-digit",
                      month: "long",
                      year: "numeric",
                    })}
                  </td>
                  <td className="px-2 py-4 text-center">
                    {orders?.applicantFullName
                      ? orders?.applicantFullName.charAt(0).toUpperCase() +
                        orders?.applicantFullName?.slice(1)
                      : ""}
                  </td>
                  <td className="px-2 py-4 text-center">{orders?.email}</td>
                  <td className="px-2 py-4 text-center">
                    {orders?.applicantMobileNumber}
                  </td>
                  <td className="px-2 py-4 text-center">
                    {orders?.productList?.productName}
                  </td>
                  <td className="px-2 py-4 text-center">
                    {orders?.totalPaymentAmount}
                  </td>
                  <td className="px-2 py-4 text-center">
                    <div className="bg-opacity-50 h-6 rounded-md flex justify-center items-center text-center">
                      <p
                        className={clsx("rounded-md p-1 text-base", {
                          "text-yellow-800 bg-yellow-200":
                            orders.Status === "Processing",
                          "text-red-800 bg-red-200":
                            orders.Status === "Cancelled",
                          "text-green-800 bg-green-200":
                            orders.Status === "Completed",
                          "text-blue-800 bg-blue-200":
                            orders.Status === "Refunded",
                          "text-gray-50 bg-stone-700":
                            orders.Status === "Incompleted",
                          "text-orange-600 bg-orange-200":
                            orders.Status === "Pending",
                        })}
                      >
                        {orders.Status === "Incompleted"
                          ? "Incomplete Payment"
                          : orders.Status}
                      </p>
                    </div>
                  </td>
                  <td className="px-2 py-4 text-center">
                    <div className="bg-opacity-50 h-6 rounded-md flex justify-center items-center text-center">
                      <p
                        className={clsx("rounded-md p-1 text-base", {
                          "text-yellow-800 bg-yellow-200":
                            orders?.paymentData?.status === "PAYMENT_INITIATED",
                          "text-red-800 bg-red-200":
                            orders?.paymentData?.status === "PAYMENT_ERROR",
                          "text-green-800 bg-green-200":
                            orders?.paymentData?.status === "PAYMENT_SUCCESS",
                          "text-blue-800 bg-blue-200":
                            orders?.paymentData?.status === true,
                        })}
                      >
                        {orders?.paymentData
                          ? orders?.paymentData?.status
                          : "Wallet Debited"}
                      </p>
                    </div>
                  </td>

                  {/* <td className="px-2 py-4 text-center">
                  {orders?.paymentLink === "wallet debit" ? "Member" : "User"}
                </td> */}

                  <td className="px-2 py-4 text-center justify-center items-center flex flex-col gap-2">
                    <span
                      onClick={() => {
                        setShowDetails(true);
                        setSelectedOrder(orders);
                        setOrderSerialNo(index + 1);
                        setIsUpdated(false);
                      }}
                      className="cursor-pointer text-sm px-2 py-1 bg-blue-700 text-white rounded-lg"
                    >
                      Details
                    </span>

                    <span
                      onClick={() => {
                        setShowOrderedBy(true);
                        handleGetOrderedBy(orders?.userId);
                        setOrderSerialNo(index + 1);
                        setIsUpdated(false);
                        setShowDetails(false);
                      }}
                      className="cursor-pointer px-2 text-sm py-1 bg-blue-700 text-white rounded-lg"
                    >
                      Ordered by
                    </span>
                  </td>

                  {/* 
                <td className="px-2 py-4 text-center">
                  <div className="bg-opacity-50 h-6 rounded-md flex justify-center items-center text-center">
                    
                    <p
                      className={clsx("rounded-md p-1 text-base", {
                        "text-yellow-800 bg-yellow-200":
                          orders?.paymentData?.status === "PAYMENT_INITIATED",
                        "text-red-800 bg-red-200":
                          orders?.paymentData?.status === "PAYMENT_ERROR",
                        "text-green-800 bg-green-200":
                          orders?.paymentData?.status === "PAYMENT_SUCCESS",
                        "text-blue-800 bg-blue-200":
                          orders?.paymentData?.status === true,
                      })}
                    >
                      {orders.Status}
                    </p>
                  </div>
                </td> */}
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={10} className="px-2 py-2 text-lg text-center">
                  No orders
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default AllOrdersTable;

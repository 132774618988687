import React from 'react';
import bgImage from "../assets/bgImagRK.webp";
import { Link } from "react-router-dom";
import { CheckCircle, Award, Shield } from 'lucide-react';
import logo from "../assets/LogoRkcsc.png";

const features = [
  { 
    icon: CheckCircle, 
    title: "100% Accuracy",
    text: "Guaranteed completion with perfect accuracy" 
  },
  { 
    icon: Award, 
    title: "Certified Professionals",
    text: "Expert team handling your documents" 
  },
  { 
    icon: Shield, 
    title: "Secure Documentation",
    text: "Your documents are in safe hands",
    highlight: true
  }
];

const HeroSection = () => {
  return (
    <div className="w-full bg-gradient-to-br from-white via-blue-50 to-blue-100">
      <div className="mx-auto px-4 sm:px-[4%] lg:px-[4%] py-12 pt-5">
        <div className="lg:flex lg:items-center lg:gap-3 xl:gap-12 mt-3">
          <div className="lg:w-1/2 space-y-8">
            <div className="flex items-center space-x-4 mb-8">
              <h1 className="text-4xl font-bold text-blue-800">
                RK Consultancy
              </h1>
            </div>

            <div className="space-y-4">
              <h2 className="text-3xl md:text-4xl font-bold text-gray-900 leading-tight">
                Professional Document & Certification Services
              </h2>
              <p className="text-xl text-gray-600 leading-relaxed">
                We excel at ensuring flawless completion of all types of Documents, 
                Certificates & various other online applications, guaranteeing 100% 
                accuracy everytime.
              </p>
            </div>

            <div className="grid md:grid-cols-3 gap-4 mt-12">
              {features.map((feature, index) => (
                <div 
                  key={index}
                  className={`
                    relative bg-white rounded-xl p-6 shadow-lg border overflow-hidden
                    ${feature.highlight 
                      ? 'border-blue-300 shadow-xl' 
                      : 'border-blue-100 hover:border-blue-300 hover:shadow-xl'
                    } 
                    transition-all duration-300
                  `}
                >
                  {feature.highlight && (
                    <>
                      <div className="absolute inset-0 rounded-xl z-0 bg-gradient-to-r from-blue-400 via-blue-600 to-blue-400 opacity-75" 
                           style={{
                             backgroundSize: '200% 100%',
                             animation: 'gradientMove 3s linear infinite'
                           }}
                      />
                      
                      <div className="absolute inset-0 rounded-xl z-0 opacity-0"
                           style={{
                             animation: 'pulseEffect 2s ease-in-out infinite',
                             background: 'radial-gradient(circle, rgba(59, 130, 246, 0.3) 0%, rgba(255, 255, 255, 0) 70%)'
                           }}
                      />
                      
                      <div className="absolute inset-0.5 rounded-lg z-0 bg-white" />
                      
                      <style jsx>{`
                        @keyframes gradientMove {
                          0% { background-position: 0% 0; }
                          100% { background-position: 200% 0; }
                        }
                        
                        @keyframes pulseEffect {
                          0% { opacity: 0; transform: scale(0.95); }
                          50% { opacity: 0.5; transform: scale(1); }
                          100% { opacity: 0; transform: scale(0.95); }
                        }
                        
                        @keyframes softGlow {
                          0% { box-shadow: 0 0 5px rgba(59, 130, 246, 0.3); }
                          50% { box-shadow: 0 0 20px rgba(59, 130, 246, 0.6); }
                          100% { box-shadow: 0 0 5px rgba(59, 130, 246, 0.3); }
                        }
                      `}</style>
                    </>
                  )}
                  
                  <div className="relative z-10">
                    <feature.icon 
                      className={`w-8 h-8 ${feature.highlight ? 'text-blue-600' : 'text-blue-600'} mb-4`} 
                      style={feature.highlight ? { animation: 'softGlow 2s infinite' } : {}}
                    />
                    <h3 className={`font-semibold ${feature.highlight ? 'text-blue-700' : 'text-black'} mb-2`}>{feature.title}</h3>
                    <p className="text-gray-800 text-sm">{feature.text}</p>
                    
                    {feature.highlight && (
                      <div className="mt-3 flex items-center">
                        <div className="w-1.5 h-1.5 rounded-full bg-blue-600 mr-2 relative">
                          <div className="absolute inset-0 rounded-full bg-blue-400 animate-ping opacity-75"></div>
                        </div>
                        <span className="text-sm font-medium text-blue-600">Premium Security</span>
                      </div>
                    )}
                  </div>
                </div>
              ))}
            </div>

            <div className="flex flex-col sm:flex-row gap-4">
              <Link
                to="/services"
                className="inline-flex items-center justify-center px-8 py-4 bg-blue-800 text-white 
                         rounded-lg font-semibold shadow-lg hover:bg-blue-700 transition-all duration-300"
              >
                Explore Our Services
              </Link>
              <Link
              to="/about"
                className="inline-flex items-center justify-center px-8 py-4 border-2 border-blue-800 
                         text-blue-800 rounded-lg font-semibold hover:bg-blue-50 transition-all duration-300"
              >
                Why Choose Us?
              </Link>
            </div>
          </div>

          <div className="lg:w-1/2 mt-12 lg:mt-0">
            <div className="relative">
              <div className="absolute top-4 right-4 w-64 h-64 bg-blue-200 rounded-full mix-blend-multiply filter blur-xl opacity-70" />
              <div className="absolute bottom-4 left-4 w-64 h-64 bg-blue-300 rounded-full mix-blend-multiply filter blur-xl opacity-70" />
              
              <div className="relative bg-white rounded-2xl shadow-2xl p-8">
                <div className="aspect-w-16 aspect-h-9 mb-8">
                  <img 
                    src={bgImage} 
                    alt="Professional Services"
                    className="w-full h-full object-cover rounded-xl"
                  />
                </div>
                
                <div className="bg-blue-50 p-6 rounded-xl">
                  <div className="flex items-center justify-between">
                    <div>
                      <h3 className="text-4xl font-bold text-blue-800">10K+</h3>
                      <p className="text-gray-600">Documents Processed</p>
                    </div>
                    <div className="h-12 w-px bg-blue-200" />
                    <div>
                      <h3 className="text-4xl font-bold text-blue-800">100%</h3>
                      <p className="text-gray-600">Customer Satisfaction</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
import React, { useEffect, useState, useContext, useRef } from "react";
import clsx from "clsx";
import AppContext from "../AppContext/AppContext";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { FaRegUser, FaUserEdit } from "react-icons/fa";
import { MdOutlineEmail, MdOutlinePhone } from "react-icons/md";
import { GoOrganization } from "react-icons/go";
import Core from "../core/Core";
import { BsPersonCircle } from "react-icons/bs";
import UsersTable from "./UsersTable";
import MemberTable from "./MemberTable";
import Loader2 from "./Loader2";
import AllAdmins from "./AllAdmins";
import { IoClose } from "react-icons/io5";
import { FiPlus } from "react-icons/fi";
import { toast } from "react-toastify";
import { CiCirclePlus } from "react-icons/ci";
import Loader from "../components/Loader";

const ManageUsers = () => {
  const appContext = useContext(AppContext);
  const navigate = useNavigate();
  const { getAllUsers, handleAddAdmin, deleteAndVerify, getAllCategory } =
    Core();

  const notify = (text, time) => {
    toast.dismiss();

    toast.info(text, {
      toastId: "random1",
      closeButton: false,
      position: "top-center",
      autoClose: time ? time : 1500,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      style: {
        // width: "400px",
        // maxWidth: "90vw",
        padding: "15px 20px",
        fontSize: "16px",
        textAlign: "center",
        fontWeight: "500",
      },
    });
  };

  const detailsRef = useRef(null);
  const createAdminRef = useRef(null);
  const [isAllUsersClicked, setIsAllUsersClicked] = useState(true);
  const [isAllMemberClicked, setIsAllMemberClicked] = useState(false);
  const [isAllAdminClicked, setIsAllAdminClicked] = useState(false);
  const [allUsers, setAllUsers] = useState(null);
  const [allMembers, setAllMembers] = useState(null);
  const [allAdmins, setAllAdmins] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const [createAdmin, setCreateAdmin] = useState(false);
  const [selectedAdmin, setSelectedAdmin] = useState(null);
  const [adminName, setAdminName] = useState("");
  const [adminEmail, setAdminEmail] = useState("");
  const [adminPhone, setAdminPhone] = useState("");
  const [adminPassword, setAdminPassword] = useState("");
  const [adminPermission, setAdminPermission] = useState([]);
  const [showDeleteNoti, setShowDeleteNoti] = useState(false);
  const [deleteAdmin, setDeleteAdmin] = useState(null);
  const [allCategory, setAllCatefgory] = useState(null);
  const [selectedOption, setSelectedOption] = useState("");
  const [showLoader, setShowLoader] = useState(false);

  const getUserData = async (userType) => {
    setIsLoading(true);
    try {
      const response = await getAllUsers(userType);
      if (response) {
        // console.log("All fetched users", response);

        if (response?.user) {
          const allUsersData = response?.user;
          console.log("All Users fetched", allUsersData);
          if (userType === "user") {
            setAllUsers(allUsersData);
          } else if (userType === "member") {
            setAllMembers(allUsersData);
          } else if (userType === "pseudoAdmin") {
            setAllAdmins(allUsersData);
          }
          setIsLoading(false);
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const validatePhone = (phone) => {
    const phoneRegex = /^(?:\+91)?[6-9]\d{9}$/;
    return phoneRegex.test(phone.replace(/\s+/g, ""));
  };

  const handleCreateAdmin = async (
    adminName,
    adminEmail,
    adminPhone,
    adminPassword,
    adminPermission
  ) => {
    try {
      if (
        !adminName ||
        !adminEmail ||
        !adminPhone ||
        !adminPassword ||
        adminPermission.length === 0
      ) {
        notify("All fields are required including permissions", 2000);
        return;
      }

      if (!validateEmail(adminEmail)) {
        notify("Please enter a valid email address", 2000);
        return;
      }

      if (!validatePhone(adminPhone)) {
        notify("Please enter a valid Indian phone number (10 digits)", 2000);
        return;
      }

      setShowLoader(true);

      const adminData = {
        firstName: adminName,
        email: adminEmail,
        phone: adminPhone,
        password: adminPassword,
        permission: adminPermission,
      };

      const jsonData = JSON.stringify(adminData);

      const response = await handleAddAdmin(jsonData);

      if (response) {
        setAdminName("");
        setAdminEmail("");
        setAdminPhone("");
        setAdminPassword("");
        setAdminPermission([]);
        setCreateAdmin(false);
        getUserData("pseudoAdmin");
        setShowLoader(false);
      }
    } catch (error) {
      console.error("Error creating admin:", error);
    } finally {
      setShowLoader(false);
    }
  };

  const handlePermissionChange = (e) => {
    const selectedValue = e.target.value;

    if (!adminPermission.includes(selectedValue) && selectedValue !== "") {
      setAdminPermission((prev) => [...prev, selectedValue]);
      setSelectedOption("");

      notify(`Added ${selectedValue} to permissions`, 1000);
    } else if (selectedValue !== "") {
      notify(`${selectedValue} is already selected`, 1500);
    }
  };

  const handleRemovePermission = (permissionToRemove) => {
    setAdminPermission((prev) =>
      prev.filter((permission) => permission !== permissionToRemove)
    );
    notify(`Removed ${permissionToRemove}`, 1000);
  };

  const handleUser = () => {
    setIsAllUsersClicked(true);
    setIsAllMemberClicked(false);
    setIsAllAdminClicked(false);
    getUserData("user");
  };

  const handleMember = () => {
    setIsAllUsersClicked(false);
    setIsAllMemberClicked(true);
    setIsAllAdminClicked(false);
    getUserData("member");
  };

  const handleAdmin = () => {
    setIsAllUsersClicked(false);
    setIsAllMemberClicked(false);
    setIsAllAdminClicked(true);
    getUserData("pseudoAdmin");
  };

  const handleGetAllCategory = async () => {
    try {
      const response = await getAllCategory();
      if (response) {
        console.log("all categories", response.ProductList);
        setAllCatefgory(response.ProductList);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const handleDeleteAdmin = async (userId, action) => {
    try {
      const response = await deleteAndVerify(userId, action);
      if (response) {
        if (action === "updated") {
          notify("Sub Admin approved successfully.", 2000);
        } else {
          notify("Sub Admin deleted successfully.", 2000);
        }
        getUserData("pseudoAdmin");
        setShowDeleteNoti(false);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setShowDeleteNoti(false);
    }
  };

  const handleEmailChange = (e) => {
    const email = e.target.value;
    setAdminEmail(email);
    if (email && !validateEmail(email)) {
      notify("Please enter a valid email address", 1500);
    }
  };

  const handlePhoneChange = (e) => {
    const phone = e.target.value;
    setAdminPhone(phone);
    if (phone && !validatePhone(phone)) {
      notify("Please enter a valid Indian phone number (10 digits)", 1500);
    }
  };

  useEffect(() => {
    getUserData("user");
    handleGetAllCategory();
  }, []);

  useEffect(() => {
    const handleClose = (e) => {
      if (detailsRef.current && !detailsRef.current.contains(e.target)) {
        setShowDetails(false);
      }
    };

    document.addEventListener("mousedown", handleClose);
    return () => {
      document.removeEventListener("mousedown", handleClose);
    };
  }, [detailsRef]);

  useEffect(() => {
    const handleClose = (e) => {
      if (
        createAdminRef.current &&
        !createAdminRef.current.contains(e.target)
      ) {
        setCreateAdmin(false);
      }
    };

    document.addEventListener("mousedown", handleClose);
    return () => {
      document.removeEventListener("mousedown", handleClose);
    };
  }, [createAdminRef]);

  return (
    <>
      <div
        className=" text-slate-100 font-semibold text-md  
        flex flex-col gap-4 px-[2%] py-6
        backdrop  bg-opacity-10 bg-gradient-to-b from-gray-700/20 to-gray-950/20 "
      >
        <div className="flex flex-wrap mt-10 md:gap-8 gap-4">
          <button
            onClick={handleUser}
            className={clsx("tabButtons", {
              "bg-gradient-to-r from-[#007bff] to-[#00bfff]": isAllUsersClicked,
            })}
          >
            All Users ({allUsers?.length || 0})
          </button>
          <button
            onClick={handleMember}
            className={clsx("tabButtons", {
              "bg-gradient-to-r from-[#007bff] to-[#00bfff]": isAllMemberClicked,
            })}
          >
            All Members ({allMembers?.length || 0})
          </button>
          <button
            onClick={handleAdmin}
            className={clsx("tabButtons", {
              "bg-gradient-to-r from-[#007bff] to-[#00bfff]": isAllAdminClicked,
            })}
          >
            All Admins ({allAdmins?.length || 0})
          </button>
        </div>
        <div className=" flex w-full md:h-[69.5vh] h-[66vh] justify-center">
          {isAllUsersClicked ? (
            <div className="flex  flex-col h-full w-full gap-4">
              <div
                className="h-full w-full overflow-y-auto text-slate-100 font-semibold text-md flex flex-col gap-4 rounded-lg
         border border-zinc-500 shadow-lg 
         "
              >
                {isLoading ? (
                  <div className="h-full w-full flex justify-center items-cente ">
                    <Loader2 />
                  </div>
                ) : allUsers?.length > 0 ? (
                  <UsersTable getUserData={getUserData} allUsers={allUsers} />
                ) : (
                  <span className="flex justify-center items-center text-xl font-bold h-full text-black">
                    {" "}
                    There are no registered users. Once anyone signedUp on
                    our website, they will be reflected here.
                  </span>
                )}
              </div>
            </div>
          ) : null}

          {isAllMemberClicked ? (
            <div className="flex  flex-col h-full items-end w-full gap-4">
              <div
                className="h-full w-full overflow-y-auto text-slate-100 font-semibold text-md flex flex-col gap-4 rounded-lg
         border border-zinc-500 shadow-lg 
         "
              >
                {isLoading ? (
                  <div className="h-full w-full flex justify-center items-cente ">
                    <Loader2 />
                  </div>
                ) : allMembers?.length > 0 ? (
                  <MemberTable
                    getUserData={getUserData}
                    memberData={allMembers}
                  />
                ) : (
                  <span className="flex justify-center items-center text-xl font-bold h-full text-black">
                    {" "}
                    There are no registered Members. Once anyone signedUp on
                    our website, they will be reflected here.
                  </span>
                )}
              </div>
            </div>
          ) : null}

          {isAllAdminClicked ? (
            <div className="flex  flex-col h-full items-end w-full gap-4">
              <div
                className="h-full w-full overflow-y-auto text-slate-100 font-semibold text-md flex flex-col gap-4 rounded-lg
         border border-zinc-500 shadow-lg 
         "
              >
                {isLoading ? (
                  <div className="h-full w-full  flex justify-center items-cente ">
                    <Loader2 />
                  </div>
                ) : allAdmins?.length > 0 ? (
                  <AllAdmins
                    createAdminRef={createAdminRef}
                    createAdmin={createAdmin}
                    getUserData={getUserData}
                    allAdmins={allAdmins}
                    setShowDetails={setShowDetails}
                    setCreateAdmin={setCreateAdmin}
                    setSelectedAdmin={setSelectedAdmin}
                    setShowDeleteNoti={setShowDeleteNoti}
                    setDeleteAdmin={setDeleteAdmin}
                  />
                ) : (
                  <div
                    className=" bg-blue-300 mt-4 ml-4 h-52 w-44 rounded-2xl bg-clip-padding border border-gray-100 
                                   flex flex-col items-center justify-center gap-4 text-black text-xl 
                                   font-bold cursor-pointer hover:scale-105 transition-all duration-150"
                    onClick={() => {
                      setCreateAdmin(true);
                    }}
                  >
                    <CiCirclePlus className="h-20 w-20 text-black " />
                  </div>
                )}
              </div>
            </div>
          ) : null}
        </div>

        {showDetails && selectedAdmin && (
          <div className="fixed top-[10%] inset-0 z-40 flex justify-center items-center bg-black bg-opacity-50">
            <div
              className="relative p-6 rounded-lg h-5/6 w-[70%] bg-gray-100 text-slate-200 "
              ref={detailsRef}
            >
              <button
                className="absolute flex text-black justify-center items-center right-[0%] top-[1%]"
                onClick={() => {
                  setShowDetails(false);
                }}
              >
                <IoClose className="text-2xl" />
              </button>
              {/* <div>
                <span>{selectedAdmin?.firstName}</span>|
                <span className="text-base text-gray-800">
                  {admins?.accountType === "admin" ? (
                    <span className="flex justify-center items-center gap-2">
                      <LuCrown />
                      Super Admin
                    </span>
                  ) : (
                    "Sub Admin"
                  )}
                </span>
              </div> */}

              <div
                className="md:h-full w-full h-[85%] relative text-slate-100 font-semibold text-md flex flex-col gap-4 p-4 rounded-lg
                      border border-zinc-500 shadow-lg justify-center
                      items-center pt-5 md:pt-2"
              >
                <img
                  src={selectedAdmin?.photoUrl}
                  className="rounded-full"
                  alt={selectedAdmin?.firstName?.split(" ")[0]}
                  width={100}
                  height={100}
                />
                <div className="md:w-full w-full relative h-full flex flex-col justify-center items-center text-slate-200">
                  <div className="flex flex-col gap-4">
                    <div className="flex justify-start items-center gap-8">
                      <div className="w-28 text-md text-black flex items-center gap-2">
                        <FaRegUser /> Name:
                      </div>
                      <div className="text-start text-black text-lg flex-1">
                        {selectedAdmin?.firstName}
                      </div>
                    </div>
                    <div className="flex justify-start items-center gap-8">
                      <div className="w-28 text-md text-black flex items-center gap-2">
                        <MdOutlineEmail /> Email:
                      </div>
                      <div className="text-lg text-black flex-1 overflow-hidden whitespace-nowrap text-ellipsis">
                        {selectedAdmin?.email}
                      </div>
                    </div>
                    <div className="flex justify-start items-center gap-8">
                      <div className="w-28 text-md text-black flex items-center gap-2">
                        <MdOutlinePhone /> Phone:
                      </div>
                      <div className="text-lg text-black flex-1">
                        {selectedAdmin?.phoneNumber}
                      </div>
                    </div>
                    <div className="flex justify-start items-center gap-8">
                      <div className="w-28 text-md text-black flex items-center gap-2">
                        <BsPersonCircle /> Designation:
                      </div>
                      <div className="text-lg text-black flex-1 overflow-hidden whitespace-nowrap text-ellipsis">
                        {selectedAdmin?.accountType === "admin" ? (
                          <div className="flex gap-2 items-center">
                            Super Admin
                          </div>
                        ) : (
                          "Sub Admin"
                        )}
                      </div>
                    </div>

                    <div className="flex justify-start items-center gap-8">
                      <div className="w-28 text-md text-black flex items-center gap-2">
                        <GoOrganization /> Permission:
                      </div>
                      <div className="text-lg text-black text-wrap flex-1 overflow-hidden whitespace-nowrap text-ellipsis">
                        {/* {selectedAdmin?.permission.map((perm, index) => (
                          <span>{perm}, </span>
                        ))} */}
                        {selectedAdmin?.permission.length > 0 && (
                          <div className="flex flex-wrap gap-2 max-h-32 overflow-y-auto p-2 border border-stone-600 rounded-lg">
                            {selectedAdmin?.permission.map((permission, index) => (
                              <span
                                key={index}
                                className="flex items-center gap-1.5 bg-blue-500 px-3 py-1.5 rounded-full text-sm border border-blue-500/30"
                              >
                                {permission}

                              </span>
                            ))}
                          </div>
                        )}
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {createAdmin && (
          <div className="fixed top-[10%] inset-0 z-40 flex items-center justify-center p-4 bg-black/20 backdrop-blur-sm">
            <div
              ref={createAdminRef}
              className="relative w-full max-w-3xl max-h-[85vh] overflow-y-auto bg-white rounded-xl shadow-xl border border-stone-600"
            >

              <button
                className="absolute right-4 top-4 p-2 text-black hover:bg-gray-200 rounded-full transition-colors"
                onClick={() => setCreateAdmin(false)}
              >
                <IoClose className="w-6 h-6" />
              </button>

              <div className="p-6 sm:p-8">
                <h2 className="text-2xl font-bold text-center mb-2 text-black">
                  Create Sub Admin
                </h2>

                <div className="space-y-3">
                  {[
                    {
                      label: "Name",
                      type: "text",
                      value: adminName,
                      placeholder: "Jhon Smith",
                      onChange: (e) => setAdminName(e.target.value),
                    },
                    {
                      label: "Email",
                      type: "email",
                      value: adminEmail,
                      onChange: handleEmailChange,
                      placeholder: "example@domain.com",
                    },
                    {
                      label: "Phone",
                      type: "tel",
                      value: adminPhone,
                      onChange: handlePhoneChange,
                      placeholder: "Enter 10-digit number",
                    },
                    {
                      label: "Password",
                      type: "text",
                      value: adminPassword,
                      onChange: (e) => setAdminPassword(e.target.value),
                    },
                  ].map((field, index) => (
                    <div
                      key={index}
                      className="flex flex-col sm:flex-row items-start sm:items-center gap-2"
                    >
                      <label className="w-24 text-lg text-black font-semibold">
                        {field.label}:
                      </label>
                      <input
                        type={field.type}
                        required
                        className="w-full sm:w-2/3 bg-gray-200 border border-black rounded-lg p-2.5 text-black placeholder-gray-800 focus:ring-2 focus:ring-blue-500/20 transition-all"
                        value={field.value}
                        onChange={field.onChange}
                        placeholder={field.placeholder}
                        style={{ border: "1px solid white" }}
                      />
                    </div>
                  ))}

                  <div className="flex flex-col sm:flex-row items-start sm:items-center gap-2">
                    <label className="w-24 text-lg text-black font-semibold">
                      Permission:
                    </label>
                    <select
                      required
                      className="w-full sm:w-2/3 bg-gray-200 border border-stone-500 focus:border-blue-500 rounded-lg p-2.5 text-black focus:ring-2 focus:ring-blue-500/20 transition-all"
                      onChange={handlePermissionChange}
                    >
                      <option value="">Select permission</option>
                      {allCategory.map((option, index) => (
                        <option
                          key={index}
                          value={option}
                          className="bg-gray-200"
                        >
                          {option}
                        </option>
                      ))}
                    </select>
                  </div>

                  {adminPermission.length > 0 && (
                    <div className="flex flex-wrap gap-2 max-h-28 overflow-y-auto p-2 border border-stone-600 rounded-lg">
                      {adminPermission.map((permission, index) => (
                        <span
                          key={index}
                          className="flex items-center gap-1.5 bg-blue-500 px-3 py-1.5 rounded-full text-sm border border-blue-500/30"
                        >
                          {permission}
                          <button
                            onClick={() => handleRemovePermission(permission)}
                            className="hover:bg-blue-300 hover:text-black rounded-full p-0.5 transition-colors"
                          >
                            <IoClose className="w-4 h-4" />
                          </button>
                        </span>
                      ))}
                    </div>
                  )}
                </div>

                {/* Submit button */}
                <div className="mt-8 flex justify-end">
                  <button
                    className="flex items-center gap-2 bg-blue-600 hover:bg-blue-700 text-white px-4 py-2 rounded-lg transition-colors"
                    onClick={() =>
                      handleCreateAdmin(
                        adminName,
                        adminEmail,
                        adminPhone,
                        adminPassword,
                        adminPermission
                      )
                    }
                  >
                    <FiPlus className="w-5 h-5" />
                    Add Sub Admin
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}

        {showDeleteNoti && (
          <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-80 z-50">
            <div className="rounded-lg backdrop  bg-opacity-50 bg-gradient-to-b from-gray-500/50 to-gray-900/50 backdrop-blur-md border border-slate-500 shadow-lg  animate-fadeIn p-5">
              <h2 className="text-lg font-bold mb-1 text-slate-100">
                Confirm Delete
              </h2>
              <p className="mb-4 text-zinc-400 text-base">
                Are you sure to delete this subAdmin?
              </p>
              <div className="flex justify-end">
                <button
                  className="bg-red-500 text-white px-4 py-2 rounded-md mr-2"
                  onClick={() => {
                    handleDeleteAdmin(deleteAdmin, "deleted");
                  }}
                >
                  Yes
                </button>
                <button
                  className="bg-gray-300 text-gray-800 px-4 py-2 rounded-md"
                  onClick={() => {
                    setShowDeleteNoti(false);
                  }}
                >
                  No
                </button>
              </div>
            </div>
          </div>
        )}

        {showLoader && <Loader />}
      </div>
    </>
  );
};

export default ManageUsers;

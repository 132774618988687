import React, { useContext, useState, useEffect } from "react";
import ProductCards from "./ProductCards";
import { Link } from "react-router-dom";
import AppContext from "../AppContext/AppContext";
import { Search } from 'lucide-react';
import Core from "../core/Core";

const Cards = ({ products, setSigninClicked }) => {
  const appContext = useContext(AppContext);
  const { handleSearchProduct } = Core();
  const [searchValue, setSearchValue] = useState("");
  const [displayedProducts, setDisplayedProducts] = useState(products);

  const handleSearch = async (value) => {
    setSearchValue(value);
    
    if (value.trim() === "") {
      setDisplayedProducts(products);
      return;
    }

    const searchResults = await handleSearchProduct(value);
    if (searchResults?.ProductList) {
      setDisplayedProducts(searchResults.ProductList);
    }
  };

  useEffect(() => {
    setDisplayedProducts(products);
  }, [products]);

  return (
    <div className="relative mx-auto px-6 mt-10 bg-gradient-to-br from-white via-blue-50 to-blue-100 py-10 pb-7 rounded-xl shadow-xl">
      <div className="text-center pb-10 px-0 mr-[4%] relative">
        <div className="">
          <h1 className="text-5xl font-bold text-blue-800 mb-8 relative">
            <span className="absolute -top-12 left-1/2 transform -translate-x-1/2 text-6xl text-gray-200 opacity-70">
              Services
            </span>
            Services
          </h1>

          <div className="max-w-md mx-auto mb-2 transform transition-all duration-700">
            <div className="relative">
              <input
                type="text"
                placeholder="Search services..."
                value={searchValue}
                onChange={(e) => handleSearch(e.target.value)}
                style={{border: "2px solid #dbdbdb"}}
                className="w-full px-4 py-2 pl-12 shadow-xl rounded-full border-2 border-blue-200 focus:border-blue-400 focus:ring-2 focus:ring-blue-200 transition-all duration-300 outline-none"
              />
              <Search className="absolute left-4 top-1/2 transform -translate-y-1/2 text-blue-400 w-5 h-5" />
            </div>
          </div> 
        </div>
      </div>

      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4 gap-8">
        {displayedProducts &&
          displayedProducts.slice(0,8).map((product, index) => (
            <Link
              to="/product"
              key={index}
              onClick={() => {
                appContext.setProductInfo((prev) => ({
                  ...prev,
                  description: product.description,
                  image: product.image,
                  member_price: product.member_price,
                  price: product.price,
                  processFlow: product.processFlow,
                  productName: product.productName,
                  requiredDocs: product.requiredDocs,
                  _id: product._id,
                }));
              }}
            >
              <ProductCards
                product={product}
                setSigninClicked={setSigninClicked}
              />
            </Link>
          ))}
      </div>

      <div className="flex justify-center w-full items-center mt-5">
        <Link to="/services" className="custom-btn btn-12">
          <span className="text-white">Click!</span>
          <span className="text-white">View All</span>
        </Link>
      </div>
    </div>
  );
};

export default Cards;
import React, { useState, useContext, useRef, useEffect } from "react";
import { IoMdClose } from "react-icons/io";
import AppContext from "../AppContext/AppContext";
import Core from "../core/Core";
import { toast } from "react-toastify";
import { GoEyeClosed } from "react-icons/go";
import { GoEye } from "react-icons/go";

const UpdatePassword = ({ setShowUpdateProfile, setShowUpdatePassword }) => {
  const notify = (text, time) => {
    toast.dismiss();

    toast.info(text, {
      toastId: "random1",
      closeButton: false,
      position: "top-center",
      autoClose: time ? time : 1500,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      style: {
        // width: "400px",
        // maxWidth: "90vw",
        padding: "15px 20px",
        fontSize: "16px",
        textAlign: "center",
        fontWeight: "500",
      },
    });
  };

  const updatePassRef = useRef(null);
  const [passwordData, setPasswordData] = useState({
    password: "",
    newPassword: "",
    cnewPassword: "",
  });

  const [showPasswords, setShowPasswords] = useState({
    current: false,
    new: false,
    confirm: false
  });

  const { handleUpdatePass } = Core();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setPasswordData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (passwordData.newPassword !== passwordData.cnewPassword) {
      notify("New passwords don't match!");
      return;
    }

    handleUpdatePass(passwordData, setShowUpdatePassword,setShowUpdateProfile);
  };

  useEffect(() => {
    const handleClose = (e) => {
      if (updatePassRef.current && !updatePassRef.current.contains(e.target)) {
        setShowUpdatePassword(false);
      }
    };

    document.addEventListener("mousedown", handleClose);
    return () => {
      document.removeEventListener("mousedown", handleClose);
    };
  }, [updatePassRef]);

  const togglePasswordVisibility = (field) => {
    setShowPasswords(prev => ({
      ...prev,
      [field]: !prev[field]
    }));
  };

  return (
    <div className="flex justify-center items-center px-[4%] absolute top-0 h-full bg-black/50 w-full z-[1000] left-0">
      <div
        ref={updatePassRef}
        className="bg-white rounded-lg w-full max-w-md px-[2%] relative"
      >
        <div className="p-4 border-b">
          <h2 className="text-xl font-semibold">Update Password</h2>
          <button
            onClick={() => setShowUpdatePassword(false)}
            className="cursor-pointer text-2xl absolute rounded-tr-md top-0 right-0 px-2 bg-slate-600 text-white flex justify-center items-center hover:bg-slate-700"
          >
            <IoMdClose />
          </button>
        </div>

        <form onSubmit={handleSubmit} className="p-4">
          <div className="space-y-4">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Current Password
              </label>
              <div className="relative">
                <input
                  type={showPasswords.current ? "text" : "password"}
                  name="password"
                  value={passwordData.password}
                  onChange={handleInputChange}
                  className="w-full px-3 py-2 border text-base font-semibold border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500"
                  placeholder="Enter current password"
                  style={{border:"1px solid black"}}
                />
                <button
                  type="button"
                  onClick={() => togglePasswordVisibility('current')}
                  className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-600 hover:text-gray-800"
                >
                  {showPasswords.current ? <GoEyeClosed size={20} /> : <GoEye size={20} />}
                </button>
              </div>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                New Password
              </label>
              <div className="relative">
                <input
                  type={showPasswords.new ? "text" : "password"}
                  name="newPassword"
                  value={passwordData.newPassword}
                  onChange={handleInputChange}
                  className="w-full px-3 py-2 border text-base font-semibold border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500"
                  placeholder="Enter new password"
                  style={{border:"1px solid black"}}
                />
                <button
                  type="button"
                  onClick={() => togglePasswordVisibility('new')}
                  className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-600 hover:text-gray-800"
                >
                  {showPasswords.new ? <GoEyeClosed size={20} /> : <GoEye size={20} />}
                </button>
              </div>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Confirm New Password
              </label>
              <div className="relative">
                <input
                  type={showPasswords.confirm ? "text" : "password"}
                  name="cnewPassword"
                  value={passwordData.cnewPassword}
                  onChange={handleInputChange}
                  className="w-full px-3 py-2 border text-base font-semibold border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500"
                  placeholder="Confirm new password"
                  style={{border:"1px solid black"}}
                />
                <button
                  type="button"
                  onClick={() => togglePasswordVisibility('confirm')}
                  className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-600 hover:text-gray-800"
                >
                  {showPasswords.confirm ? <GoEyeClosed size={20} /> : <GoEye size={20} />}
                </button>
              </div>
            </div>
          </div>

          <div className="mt-6 flex justify-end">
            <button
              type="submit"
              className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 font-medium"
            >
              Update Password
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default UpdatePassword;
import React, { useEffect, useState, useRef, useContext } from "react";
import clsx from "clsx";
import AppContext from "../AppContext/AppContext";
import Core from "../core/Core";
import Loader2 from "./Loader2";
import AllOrdersTable from "./AllOrdersTable";
import { IoClose } from "react-icons/io5";
import { FaRegUser } from "react-icons/fa";
import { MdOutlineEmail, MdOutlinePhone } from "react-icons/md";
import { FaEdit } from "react-icons/fa";
import { FaCheck } from "react-icons/fa";
import { IoMdClose } from "react-icons/io";
import { toast } from "react-toastify";
import { IoCaretBack } from "react-icons/io5";
import axios from "axios";
import { IoMdAdd } from "react-icons/io";

const ManageOrders = () => {
  // const appContext = useContext(AppContext);
  // const navigate = useNavigate();
  const { getAdminAllOrders, handleSearchOrder, handleUpdateOrder } = Core();
  const notify = (text, time) => {
    toast.dismiss();

    toast.info(text, {
      toastId: "random1",
      closeButton: false,
      position: "top-center",
      autoClose: time ? time : 1500,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      style: {
        // width: "400px",
        // maxWidth: "90vw",
        padding: "15px 20px",
        fontSize: "16px",
        textAlign: "center",
        fontWeight: "500",
      },
    });
  };

  const detailsRef = useRef(null);
  const appContext = useContext(AppContext);

  const [allOrders, setAllOrders] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedPaymentFilter, setSelectedPaymentFilter] = useState(null);
  const [selectedOrderFilter, setSelectedOrderFilter] = useState("All");
  const [showDetails, setShowDetails] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [orderSerialNo, setOrderSerialNo] = useState(null);
  const [isUpdated, setIsUpdated] = useState(false);
  const [searchType, setSearchType] = useState("name");
  const [searchValue, setSearchValue] = useState("");
  const [showOrderedBy, setShowOrderedBy] = useState(false);
  const [orderedByDetails, setOrderedByDetails] = useState(null);

  const [updateOrderSatatus, setUpdateOrderSatatus] = useState("Abcd");

  const [fileUploading, setFileUploading] = useState(false);
  const [acknowledgements, setAcknowledgements] = useState([
    { buttonName: "", url: "" },
  ]);
  const [downloads, setDownloads] = useState([{ buttonName: "", url: "" }]);

  const [message, setMessage] = useState("");
  const [checkStatus, setCheckStatus] = useState("");
  const [orderCounts, setOrderCounts] = useState({
    All: 0,
    Pending: 0,
    Processing: 0,
    Completed: 0,
    Incompleted: 0,
    Refunded: 0,
    Cancelled: 0,
  });
  const [acknowledgementToDelete, setAcknowledgementToDelete] = useState(null);
  const [downloadToDelete, setDownloadToDelete] = useState(null);


  const handleDeleteAcknowledgement = async (ack, idx) => {
    try {
      const updateData = {
        _id: selectedOrder._id,
        acknowledgementToDelete: {
          name: ack.name,
          url: ack.url
        }
      };

      console.log("delete acknowledgement",updateData);

      const response = await handleUpdateOrder(updateData);
      if (response) {
        notify("Acknowledgement deleted successfully", 2000);

        const updatedAcknowledgements = selectedOrder.acknowledgements.filter((_, index) => index !== idx);
        setSelectedOrder({
          ...selectedOrder,
          acknowledgements: updatedAcknowledgements
        });
      }
    } catch (error) {
      console.error("Error deleting acknowledgement:", error);
      notify("Failed to delete acknowledgement", 3000);
    }
  };

  const handleDeleteDownload = async (download, idx) => {
    try {
      const updateData = {
        _id: selectedOrder._id,
        downloadToDelete: {
          name: download.name,
          url: download.url
        }
      };

      console.log("delete downloads",updateData);

      const response = await handleUpdateOrder(updateData);
      if (response) {
        notify("Download deleted successfully", 2000);

        const updatedDownloads = selectedOrder.downloads.filter((_, index) => index !== idx);
        setSelectedOrder({
          ...selectedOrder,
          downloads: updatedDownloads
        });
      }
    } catch (error) {
      console.error("Error deleting download:", error);
      notify("Failed to delete download", 3000);
    }
  };

  const handleAllOrders = async (paymentFilter = null, orderFilter = "All") => {
    try {
      setIsLoading(true);
      const response = await getAdminAllOrders(paymentFilter, orderFilter);
      if (response?.orderList) {
        console.log("all orders", response?.orderList);
        setAllOrders(response.orderList);

        const counts = {
          All: response.orderList.length,
          Pending: 0,
          Processing: 0,
          Completed: 0,
          Incompleted: 0,
          Refunded: 0,
          Cancelled: 0,
        };

        response.orderList.forEach((order) => {
          if (order.Status && counts.hasOwnProperty(order.Status)) {
            counts[order.Status]++;
          }
        });

        setOrderCounts(counts);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const searchOrder = async (searchType, searchValue) => {
    console.log("searchType", searchType);
    console.log("searchValue", searchValue);
    setAllOrders(null);
    try {
      setIsLoading(true);
      const response = await handleSearchOrder(searchType, searchValue);
      console.log("response", response?.orders);
      if (response) {
        setAllOrders(response?.orders);
        setSelectedPaymentFilter(null);
        setSelectedOrderFilter("All");

        const counts = {
          All: response.orders.length,
          Pending: 0,
          Processing: 0,
          Completed: 0,
          Incompleted: 0,
          Refunded: 0,
          Cancelled: 0,
        };

        response.orders.forEach((order) => {
          if (order.Status && counts.hasOwnProperty(order.Status)) {
            counts[order.Status]++;
          }
        });

        setOrderCounts(counts);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  // const searchOrder = async (searchType, searchValue) => {
  //   console.log("searchType", searchType);
  //   let valueToSearch = searchValue;
    
  //   if (searchType === "orderId" && valueToSearch.length > 4) {
  //     valueToSearch = valueToSearch.substring(4);
  //   }
    
  //   console.log("searchValue", valueToSearch);
  //   setAllOrders(null);
  //   try {
  //     setIsLoading(true);
  //     const response = await handleSearchOrder(searchType, valueToSearch);
  //     console.log("response", response?.orders);
  //     if (response) {
  //       setAllOrders(response?.orders);
  //       setSelectedPaymentFilter(null);
  //       setSelectedOrderFilter("All");
  
  //       const counts = {
  //         All: response.orders.length,
  //         Pending: 0,
  //         Processing: 0,
  //         Completed: 0,
  //         Incompleted: 0,
  //         Refunded: 0,
  //         Cancelled: 0,
  //       };
  
  //       response.orders.forEach((order) => {
  //         if (order.Status && counts.hasOwnProperty(order.Status)) {
  //           counts[order.Status]++;
  //         }
  //       });
  
  //       setOrderCounts(counts);
  //     }
  //   } catch (error) {
  //     console.error(error);
  //   } finally {
  //     setIsLoading(false);
  //   }
  // };

  const handlePaymentFilter = (filter) => {
    if (searchValue.trim().length > 0) {
      notify("Please clear search value to apply filters", 3000);
      return;
    }
    setSelectedPaymentFilter(filter);
    handleAllOrders(filter, selectedOrderFilter);
  };

  const handleOrderFilter = (filter) => {
    if (searchValue.trim().length > 0) {
      notify("Please clear search value to apply filters", 3000);
      return;
    }
    setSelectedOrderFilter(filter);
    handleAllOrders(selectedPaymentFilter, filter);
  };

  const clearSearch = () => {
    setSearchValue("");
    handleAllOrders(selectedPaymentFilter, selectedOrderFilter);
  };

  useEffect(() => {
    handleAllOrders();
  }, []);

  // useEffect(() => {
  //   // to close create assistant card on click outside
  //   const handleClose = (e) => {
  //     if (detailsRef.current && !detailsRef.current.contains(e.target)) {
  //       setShowDetails(false);
  //     }
  //   };

  //   document.addEventListener("mousedown", handleClose);
  //   return () => {
  //     document.removeEventListener("mousedown", handleClose);
  //   };
  // }, [detailsRef]);

  const handleAddField = (section) => {
    switch (section) {
      case "acknowledgement":
        setAcknowledgements([
          ...acknowledgements,
          { buttonName: "", file: null },
        ]);
        break;

      case "download":
        setDownloads([...downloads, { buttonName: "", file: null }]);
        break;
    }
  };

  const handleFieldChange = async (section, index, field, value) => {
    try {
      if (field === "file") {
        setFileUploading(true);
        const file = value;

        const maxSize = 5 * 1024 * 1024;
        if (file.size > maxSize) {
          notify("File size should be less than 5MB", 3000);
          return;
        }

        const fileUrl = await handlePhotoUpload(file);

        switch (section) {
          case "acknowledgement":
            const newAcks = [...acknowledgements];
            newAcks[index].url = fileUrl;
            setAcknowledgements(newAcks);
            break;
          case "download":
            const newDownloads = [...downloads];
            newDownloads[index].url = fileUrl;
            setDownloads(newDownloads);
            break;
        }
        notify("File uploaded successfully!", 1500);
      } else {
        switch (section) {
          case "acknowledgement":
            const newAcks = [...acknowledgements];
            newAcks[index][field] = value;
            setAcknowledgements(newAcks);
            break;
          case "download":
            const newDownloads = [...downloads];
            newDownloads[index][field] = value;
            setDownloads(newDownloads);
            break;
        }
      }
    } catch (error) {
      console.error("Error handling field change:", error);
      notify("Failed to process file. Please try again.", 3000);
    } finally {
      setFileUploading(false);
    }
  };

  useEffect(() => {
    console.log("acknowledgements", acknowledgements);
    console.log("downloads", downloads);
  }, [acknowledgements, downloads]);

  const handleRemoveField = (section, index) => {
    switch (section) {
      case "acknowledgement":
        setAcknowledgements(acknowledgements.filter((_, i) => i !== index));
        break;

      case "download":
        setDownloads(downloads.filter((_, i) => i !== index));
        break;
    }
  };

  const renderFields = (section, fields, title) => (
    <div className="flex flex-col gap-2">
      <p className="xl:text-lg text-base text-black font-semibold flex justify-start items-center">
        {title}:
      </p>
      {fields.map((field, index) => (
        <div key={index} className="flex gap-2 items-center">
          <input
            className="flex rounded-lg border border-blue-300 placeholder:text-gray-700 border-input bg-gray-300 p-2 text-lg text-black file:border-0 font-semibold"
            type="text"
            placeholder="Button Name"
            value={field.buttonName}
            onChange={(e) =>
              handleFieldChange(section, index, "buttonName", e.target.value)
            }
          />
          <span className="text-white text-xl">:</span>
          <div className="flex-1 flex gap-2 items-center">
            <input
              className="flex-1 border p-2 rounded-lg border-blue-300 border-input text-gray-800 file:text-gray-800 bg-gray-200"
              type="file"
              onChange={(e) =>
                handleFieldChange(section, index, "file", e.target.files[0])
              }
              disabled={fileUploading}
            />
            {/* {field.url && (
              <a
                href={field.url}
                target="_blank"
                rel="noopener noreferrer"
                className="bg-blue-600 text-white px-3 py-2 rounded-lg hover:bg-blue-700"
              >
                View File
              </a>
            )} */}
          </div>
          {index === fields.length - 1 ? (
            <button
              className="bg-blue-600 text-white px-3 py-2 text-lg rounded-lg"
              onClick={() => handleAddField(section)}
            >
              +
            </button>
          ) : (
            <button
              className="bg-red-600 text-white px-3 py-2 text-base rounded-lg"
              onClick={() => handleRemoveField(section, index)}
            >
              X
            </button>
          )}
        </div>
      ))}
    </div>
  );

  const handlePhotoUpload = async (file) => {
    const formData = new FormData();
    formData.append("file", file);

    const options = {
      method: "POST",
      url: process.env.REACT_APP_BASE_URL + `/api/v1/user/s3/operations`,
      headers: {
        Authorization: `Bearer ${appContext.userInfoVal.sessionId}`,
        "content-type": "multipart/form-data",
      },
      data: formData,
    };

    try {
      const { data } = await axios.request(options);
      console.log(data?.data?.[0]);

      if (data && data?.data?.[0]) {
        return data?.data?.[0];
      }
    } catch (error) {
      console.error(error);
      console.log("Failed to upload photo. Please try again.");
    } finally {
    }
  };

  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    if (file) {
      // const validTypes = ["image/jpeg", "image/png", "image/gif"];
      const maxSize = 5 * 1024 * 1024;

      // if (!validTypes.includes(file.type)) {
      //   console.log("Please upload a valid image (JPEG, PNG, or GIF)");
      //   return;
      // }

      if (file.size > maxSize) {
        console.log("Image size should be less than 5MB");
        return;
      }

      const reader = new FileReader();
      reader.onloadend = async () => {
        await handlePhotoUpload(file);
      };
      reader.readAsDataURL(file);
    }
  };

  const capitalizeFirst = (str) => {
    return str ? str.charAt(0).toUpperCase() + str.slice(1) : "";
  };

  const resetFields = () => {
    setMessage(selectedOrder?.message || "");
    setCheckStatus(selectedOrder?.check_status || "");
    setUpdateOrderSatatus(selectedOrder?.Status || null);
    setAcknowledgements([{ buttonName: "", url: "" }]);
    setDownloads([{ buttonName: "", url: "" }]);
  };

  const handleSubmitUpdate = async () => {
    const updateData = {
      message: message,
      Status: updateOrderSatatus,
      check_status: checkStatus,
      _id: selectedOrder._id,
      downloads: downloads
        .map((item) => ({
          name: item.buttonName,
          url: item.url,
        }))
        .filter((item) => item.name && item.url),
      acknowledgements: acknowledgements
        .map((item) => ({
          name: item.buttonName,
          url: item.url,
        }))
        .filter((item) => item.name && item.url),
    };

    console.log("updateData", updateData);

    try {
      const response = await handleUpdateOrder(updateData);
      if (response) {
        notify("Order updated successfully", 2000);
        resetFields();
        setShowDetails(false);
        setIsUpdated(false);
        handleAllOrders();
      }
    } catch (error) {
      console.error("Error updating order:", error);
      notify("Failed to update order", 3000);
    }
  };

  return (
    <>
      <div
        className=" text-slate-100 font-semibold text-md  
        flex flex-col gap-4 px-[1%] py-4
        backdrop  bg-opacity-10 bg-gradient-to-b from-gray-700/20 to-gray-950/20 "
      >
        <div>
          <div className="flex flex-col mb-3">
            <span className="text-black text-lg">
              Filter based on payment status
            </span>
            <div className="flex justify-between items-center">
              <div className="flex flex-wrap md:gap-8 gap-4">
                {[null, "Initiated", "Success", "Error"].map((filter) => (
                  <button
                    key={filter || "All"}
                    onClick={() => handlePaymentFilter(filter)}
                    disabled={searchValue.trim().length > 0}
                    className={clsx("tabButtons", {
                      "bg-gradient-to-r from-[#007bff] to-[#00bfff]":
                        selectedPaymentFilter === filter,
                      "opacity-50 cursor-not-allowed":
                        searchValue.trim().length > 0,
                    })}
                  >
                    {filter || "All Payments"}
                  </button>
                ))}
              </div>
              <div className="flex items-center gap-4">
                <select
                  type="text"
                  required
                  className="bg-blue-600 border-2 border-black rounded-md p-2 text-lg w-48"
                  onChange={(e) => setSearchType(e.target.value)}
                >
                  <option value="name" className="bg-stone-600">
                    Applicant Name
                  </option>
                  <option value="product" className="bg-stone-600">
                    Product Name
                  </option>
                  <option value="orderId" className="bg-stone-600">
                    Order Id
                  </option>
                  <option value="number" className="bg-stone-600">
                    Phone Number
                  </option>
                </select>
                <div className="flex items-center justify-center">
                  <div className="flex rounded-lg border border-gray-700">
                    <div className="flex w-10 items-center justify-center rounded-tl-lg rounded-bl-lg border-r border-gray-700 bg-white p-5">
                      <svg
                        viewBox="0 0 20 20"
                        aria-hidden="true"
                        className="pointer-events-none absolute w-5 fill-gray-500 transition"
                      >
                        <path d="M16.72 17.78a.75.75 0 1 0 1.06-1.06l-1.06 1.06ZM9 14.5A5.5 5.5 0 0 1 3.5 9H2a7 7 0 0 0 7 7v-1.5ZM3.5 9A5.5 5.5 0 0 1 9 3.5V2a7 7 0 0 0-7 7h1.5ZM9 3.5A5.5 5.5 0 0 1 14.5 9H16a7 7 0 0 0-7-7v1.5Zm3.89 10.45 3.83 3.83 1.06-1.06-3.83-3.83-1.06 1.06ZM14.5 9a5.48 5.48 0 0 1-1.61 3.89l1.06 1.06A6.98 6.98 0 0 0 16 9h-1.5Zm-1.61 3.89A5.48 5.48 0 0 1 9 14.5V16a6.98 6.98 0 0 0 4.95-2.05l-1.06-1.06Z"></path>
                      </svg>
                    </div>
                    <input
                      type="text"
                      className="w-full max-w-[180px] bg-white text-black pl-2 text-base font-semibold outline-0"
                      placeholder="Search..."
                      value={searchValue}
                      id=""
                      onChange={(e) => setSearchValue(e.target.value)}
                    />
                    {searchValue.trim().length > 0 && (
                      <button
                        onClick={clearSearch}
                        className="cursor-pointer text-2xl px-2 bg-slate-600 text-white flex justify-center items-center hover:bg-slate-700"
                      >
                        <IoMdClose />
                      </button>
                    )}
                    <input
                      type="button"
                      onClick={() => searchOrder(searchType, searchValue)}
                      value="Search"
                      className="bg-blue-500 p-2 cursor-pointer rounded-tr-lg rounded-br-lg text-black font-semibold hover:bg-blue-800 "
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col">
            <span className="text-black text-lg">
              Filter based on order status
            </span>
            <div className="flex flex-wrap md:gap-6 gap-4">
              {[
                "All",
                "Pending",
                "Processing",
                "Completed",
                "Incompleted",
                "Refunded",
                "Cancelled",
              ].map((filter) => (
                <button
                  key={filter}
                  onClick={() => handleOrderFilter(filter)}
                  disabled={searchValue.trim().length > 0}
                  className={clsx("tabButtons", {
                    "bg-gradient-to-r from-[#007bff] to-[#00bfff]":
                      selectedOrderFilter === filter,
                    "opacity-50 cursor-not-allowed":
                      searchValue.trim().length > 0,
                  })}
                >
                  {filter === "All"
                    ? `All Orders (${orderCounts.All})`
                    : `${filter} ${filter === "Incompleted" ? "Payments" : "Orders"
                    } (${orderCounts[filter]})`}
                </button>
              ))}
            </div>
          </div>
        </div>
        <div className="flex w-full md:h-[70vh] h-[66vh] justify-center">
          <div className="flex flex-col h-full w-full gap-4">
            <div className="h-full w-full text-slate-100 font-semibold text-md flex flex-col gap-4 rounded-lg border border-zinc-500 shadow-lg">
              {isLoading ? (
                <div className="h-full w-full flex justify-center items-center">
                  <Loader2 />
                </div>
              ) : allOrders?.length > 0 ? (
                <AllOrdersTable
                  allOrders={allOrders}
                  showDetails={showDetails}
                  setShowDetails={setShowDetails}
                  setSelectedOrder={setSelectedOrder}
                  setOrderSerialNo={setOrderSerialNo}
                  setIsUpdated={setIsUpdated}
                  showOrderedBy={showOrderedBy}
                  setShowOrderedBy={setShowOrderedBy}
                  setOrderedByDetails={setOrderedByDetails}
                />
              ) : (
                <span className="flex justify-center items-center text-xl font-bold h-full text-black">
                  No orders found.
                </span>
              )}
            </div>
          </div>
        </div>

        {showDetails ? (
          <div className="fixed top-[10%] inset-0 z-40 flex items-center justify-center p-4 bg-black/50 backdrop-blur-sm">
            <div className="relative w-full max-w-5xl h-[80vh] bg-gray-100 rounded-xl shadow-2xl">
              <div className="sticky top-0 z-10 bg-gray11 p-4 border-b border-stone-500">
                <button
                  className="absolute right-4 text-black top-4 p-2 hover:bg-gray-200 rounded-full transition-colors"
                  onClick={() => setShowDetails(false)}
                >
                  <IoClose className="w-6 h-6" />
                </button>

                <div className="text-black text-center space-y-2">
                  <h3 className="text-lg font-medium">Order Details</h3>
                  <h2 className="text-2xl font-bold">
                    Order Number {orderSerialNo}
                  </h2>
                </div>
              </div>

              <div className="p-6 overflow-y-auto h-[calc(100%-180px)]">
                <div className="space-y-6">
                  <div className="grid gap-4">
                    {[
                      {
                        icon: <FaRegUser />,
                        label: "Name",
                        value: selectedOrder?.applicantFullName,
                      },
                      {
                        icon: <MdOutlineEmail />,
                        label: "Email",
                        value: selectedOrder?.email,
                      },
                      {
                        icon: <MdOutlinePhone />,
                        label: "Phone",
                        value: selectedOrder?.applicantMobileNumber,
                      },
                      {
                        icon: null,
                        label: "Order Item",
                        value: selectedOrder?.productList?.productName,
                      },
                    ].map((item, index) => (
                      <div
                        key={index}
                        className="flex flex-col sm:flex-row gap-2 sm:items-center"
                      >
                        <div className="min-w-[8rem] flex items-center gap-2 text-black">
                          {item.icon} {item.label}:
                        </div>
                        <div className="flex-1 text-black">{item.value}</div>
                      </div>
                    ))}

                    {selectedOrder?.applicantFatherName && (
                      <div className="flex flex-col sm:flex-row gap-2 sm:items-center">
                        <div className="min-w-[8rem] text-black">
                          Father's Name:
                        </div>
                        <div className="flex-1 text-black">
                          {selectedOrder?.applicantFatherName}
                        </div>
                      </div>
                    )}

                    {selectedOrder?.Status && (
                      <div className="flex flex-col sm:flex-row gap-2 sm:items-center">
                        <div className="min-w-[8rem] text-black">
                          Order Status:
                        </div>
                        <div className="flex-1 text-black">
                          {selectedOrder?.Status}
                        </div>
                      </div>
                    )}
                  </div>

                  {selectedOrder?.uploadedDocs && (
                    <div className="space-y-2">
                      <h3 className="text-black">Uploaded Documents:</h3>
                      <div className="flex flex-wrap gap-3 max-h-[220px] overflow-y-auto p-2 border border-stone-500 rounded-lg">
                        {selectedOrder.uploadedDocs.map((doc, index) => (
                          <a
                            key={index}
                            href={doc?.url}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="inline-flex px-4 py-2 bg-blue-600 hover:bg-blue-700 rounded-lg transition-colors text-white"
                          >
                            {doc?.name}
                          </a>
                        ))}
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <div className="absolute bottom-0 left-0 right-0 p-4 border-t border-stone-500 bg-gray-100">
                <button
                  onClick={() => {
                    setIsUpdated(true);
                    setShowDetails(false);
                    setUpdateOrderSatatus(
                      selectedOrder?.Status || "Processing"
                    );
                    setMessage(selectedOrder?.message || "");
                    setCheckStatus(selectedOrder?.check_status || "");
                  }}
                  className="ml-auto flex items-center gap-2 bg-black hover:bg-gray-800 text-white px-6 py-2 rounded-lg transition-colors"
                >
                  <FaEdit />
                  Update Order
                </button>
              </div>
            </div>
          </div>
        ) : (
          isUpdated && (
            <div className="fixed top-[10%] inset-0 z-40 flex items-center justify-center p-4 bg-black/50 backdrop-blur-sm">
              <div
                ref={detailsRef}
                className="relative w-full max-w-5xl h-[85vh] bg-gray-100 rounded-xl shadow-2xl"
              >
                <div className="sticky top-0 z-10 bg-gray-100 p-4 border-b border-stone-500">
                  <button
                    className="absolute right-4 top-4 p-2 text-black hover:bg-gray-200 rounded-full transition-colors"
                    onClick={() => {
                      setShowDetails(false);
                      setIsUpdated(false);
                      resetFields();
                    }}
                  >
                    <IoClose className="w-6 h-6" />
                  </button>

                  <div className="flex items-center gap-4">
                    <button
                      className="p-2 text-black hover:bg-gray-200 rounded-lg transition-colors"
                      onClick={() => {
                        setIsUpdated(false);
                        setShowDetails(true);
                      }}
                    >
                      <IoCaretBack className="w-6 h-6" />
                    </button>
                    <h2 className="text-xl text-black font-bold text-center flex-1">
                      Update Order
                    </h2>
                  </div>
                </div>

                <div className="px-6 py-0 pt-2 overflow-y-auto h-[calc(100%-180px)]">
                  <div className="space-y-6">
                    <div className="flex flex-col sm:flex-row gap-4 items-start sm:items-center">
                      <label className="min-w-[8rem] font-medium text-black text-lg">
                        Order Status:
                      </label>
                      <select
                        value={updateOrderSatatus}
                        onChange={(e) => setUpdateOrderSatatus(e.target.value)}
                        className="flex-1 max-w-xs text-lg bg-gray-200 border border-stone-500 rounded-lg p-2.5 text-black focus:ring-2 focus:ring-blue-500/20"
                      >
                        {[
                          "Pending",
                          "Processing",
                          "Completed",
                          "Incompleted",
                          "Cancelled",
                          "Refunded",
                        ].map((status) => (
                          <option
                            key={status}
                            value={status}
                            className="bg-gray-200 text-black"
                          >
                            {status}
                          </option>
                        ))}
                      </select>
                    </div>

                    <div className="space-y-2">
                      {renderFields(
                        "acknowledgement",
                        acknowledgements,
                        "Acknowledgement"
                      )}
                      {selectedOrder?.acknowledgements?.length > 0 && (
                        <div className="flex flex-wrap gap-2">
                          {selectedOrder.acknowledgements.map((ack, idx) => (
                            <div key={idx} className="flex items-center">
                              <a
                                href={ack?.url}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="px-4 py-2 bg-blue-700 hover:bg-blue-800 rounded-l-lg text-sm transition-colors text-white"
                              >
                                {capitalizeFirst(ack.name)}
                              </a>
                              <button
                                onClick={() => handleDeleteAcknowledgement(ack, idx)}
                                className="px-3 py-2 bg-red-600 hover:bg-red-700 rounded-r-lg text-white transition-colors"
                              >
                                <IoMdClose />
                              </button>
                            </div>
                          ))}
                        </div>
                      )}

                      {renderFields("download", downloads, "Download")}
                      {selectedOrder?.downloads?.length > 0 && (
                        <div className="flex flex-wrap gap-2">
                          {selectedOrder.downloads.map((download, idx) => (
                            <div key={idx} className="flex items-center">
                              <a
                                href={download?.url}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="px-4 py-2 bg-blue-700 hover:bg-blue-800 rounded-l-lg text-sm transition-colors text-white"
                              >
                                {download?.name || "Download"}
                              </a>
                              <button
                                onClick={() => handleDeleteDownload(download, idx)}
                                className="px-3 py-2 bg-red-600 hover:bg-red-700 rounded-r-lg text-white transition-colors"
                              >
                                <IoMdClose />
                              </button>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>

                    <div className="space-y-2">
                      <div className="flex flex-col sm:flex-row gap-4 items-start sm:items-center">
                        <label className="min-w-[8rem] text-black text-lg font-medium">
                          Check Status Link:
                        </label>
                        <input
                          type="text"
                          value={checkStatus}
                          onChange={(e) => setCheckStatus(e.target.value)}
                          placeholder="Link"
                          className="flex-1 bg-gray-200 border border-blue-300 rounded-lg p-2.5 text-black placeholder:text-gray-700"
                        />
                      </div>

                      <div className="flex flex-col sm:flex-row gap-4 items-start sm:items-center">
                        <label className="min-w-[8rem] text-black text-lg font-medium">
                          Message:
                        </label>
                        <input
                          type="text"
                          value={message}
                          onChange={(e) => setMessage(e.target.value)}
                          placeholder="Message"
                          className="flex-1 bg-gray-200 border border-blue-300 rounded-lg p-2.5 text-black placeholder:text-gray-700"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="absolute bottom-0 left-0 right-0 p-4 border-t border-stone-500 bg-gray-100">
                  <button
                    onClick={handleSubmitUpdate}
                    className="ml-auto flex items-center gap-2 bg-green-600 hover:bg-green-700 text-white px-6 py-2 rounded-lg transition-colors"
                  >
                    <FaCheck />
                    Update
                  </button>
                </div>
              </div>
            </div>
          )
        )}

        {showOrderedBy && (
          <div className="fixed top-[10%] inset-0 z-40 flex items-center justify-center p-4 bg-black/50 backdrop-blur-sm">
            <div className="relative w-full max-w-5xl h-[80vh] bg-gray-100 rounded-xl shadow-2xl">
              <div className="sticky top-0 z-10 bg-gray-100 p-4 border-b border-stone-500">
                <button
                  className="absolute right-4 text-black top-4 p-2 hover:bg-gray-200 rounded-full transition-colors"
                  onClick={() => setShowOrderedBy(false)}
                >
                  <IoClose className="w-6 h-6" />
                </button>

                <div className="text-white text-center space-y-2">
                  <h3 className="text-lg font-medium text-black">Ordered By</h3>
                  <h2 className="text-2xl text-black font-bold">
                    Order Number {orderSerialNo}
                  </h2>
                </div>
              </div>

              <div className="p-6 overflow-y-auto h-[calc(100%-180px)]">
                <div className="space-y-6">
                  <div className="grid gap-4">
                    {[
                      {
                        icon: <FaRegUser />,
                        label: "Name",
                        value: orderedByDetails?.firstName,
                      },
                      {
                        icon: <MdOutlineEmail />,
                        label: "Email",
                        value: orderedByDetails?.email,
                      },
                      {
                        icon: <MdOutlinePhone />,
                        label: "Phone",
                        value: orderedByDetails?.phoneNumber,
                      },
                      {
                        icon: null,
                        label: "Account Type",
                        value: orderedByDetails?.accountType,
                      },
                    ].map((item, index) => (
                      <div
                        key={index}
                        className="flex flex-col sm:flex-row gap-2 sm:items-center"
                      >
                        <div className="min-w-[8rem] text-lg flex items-center gap-2 text-black">
                          {item.icon} {item.label}:
                        </div>
                        <div className="flex-1 text-lg text-black">
                          {item.value}
                        </div>
                      </div>
                    ))}

                    {/* {selectedOrder?.applicantFatherName && (
                      <div className="flex flex-col sm:flex-row gap-2 sm:items-center">
                        <div className="min-w-[8rem] text-black">
                          Father's Name:
                        </div>
                        <div className="flex-1 text-black">
                          {selectedOrder?.applicantFatherName}
                        </div>
                      </div>
                    )} */}

                    {/* {selectedOrder?.Status && (
                      <div className="flex flex-col sm:flex-row gap-2 sm:items-center">
                        <div className="min-w-[8rem] text-black">
                          Order Status:
                        </div>
                        <div className="flex-1 text-black">
                          {selectedOrder?.Status}
                        </div>
                      </div>
                    )} */}
                  </div>

                  {/* {selectedOrder?.uploadedDocs && (
                    <div className="space-y-2">
                      <h3 className="text-black">Uploaded Documents:</h3>
                      <div className="flex flex-wrap gap-3 max-h-[220px] overflow-y-auto p-2 border border-stone-500 rounded-lg">
                        {selectedOrder.uploadedDocs.map((doc, index) => (
                          <a
                            key={index}
                            href={doc?.url}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="inline-flex px-4 py-2 bg-blue-600 hover:bg-blue-700 rounded-lg transition-colors text-white"
                          >
                            {doc?.name}
                          </a>
                        ))}
                      </div>
                    </div>
                  )} */}
                </div>
              </div>

              {/* <div className="absolute bottom-0 left-0 right-0 p-4 border-t border-stone-500 bg-gray-100">
                <button
                  onClick={() => {
                    setIsUpdated(true);
                    setShowOrderedBy(false)
                    // setShowDetails(false)
                    setUpdateOrderSatatus(
                      selectedOrder?.Status || "Processing"
                    );
                    setMessage(selectedOrder?.message || "");
                    setCheckStatus(selectedOrder?.check_status || "");
                  }}
                  className="ml-auto flex items-center gap-2 bg-black hover:bg-gray-800 text-white px-6 py-2 rounded-lg transition-colors"
                >
                  <FaEdit />
                  Update Order
                </button>
              </div> */}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default ManageOrders;

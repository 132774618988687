import React, { useContext } from "react";
import descriptionImg from "../assets/description.png";
import titleImg from "../assets/cardServiceIcon.png";
import AppContext from "../AppContext/AppContext";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";

export const ProductCardsAdmin = ({
  product,
  setDeleteProduct,
  setDeleteProdId,
  setSeletecedProd,
}) => {
  const appContext = useContext(AppContext);
  const navigate = useNavigate();

  const notify = (text, time) => {
    toast.dismiss();

    toast.info(text, {
      toastId: "random1",
      closeButton: false,
      position: "top-center",
      autoClose: time ? time : 1500,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      style: {
        // width: "400px",
        // maxWidth: "90vw",
        padding: "15px 20px",
        fontSize: "16px",
        textAlign: "center",
        fontWeight: "500",
      },
    });
  };

  return (
    <div className="bg-white shadow-lg hover:shadow-2xl hover:scale-104 duration-200 transition-all ease-in-out rounded-lg overflow-hidden w-full max-w-sm">
      <div className="relative overflow-hidden">
        <img
          src={product.image}
          alt={product.productName}
          width={500}
          height={300}
          loading="lazy"
          className="object-cover hover:scale-105 rounded-lg w-full h-48 transition-all duration-300 "
        />
      </div>

      <div className="px-2 py-4 space-y-2 cursor-pointer">
        <h3 className="text-base font-sans font-semibold flex justify-start items-start gap-2 text-blue-800 truncate">
          <img
            src={titleImg}
            alt="Apartment icon"
            className="h-[30px] w-[30px]"
          />
          <span className="truncate" title={product.productName}>
            {product.productName}
          </span>
        </h3>
        <span className="flex justify-center items-start gap-2">
          <img
            src={descriptionImg}
            alt="Location icon"
            className="h-[25px] w-[25px]"
          />
          <p className="text-gray-500 text-[15px] line-clamp-2 overflow-hidden">
            {product.description}
          </p>
        </span>
      </div>

      <div className="flex justify-between items-center px-4 py-3 mb-3">
        <button
          onClick={() => {
            setDeleteProdId(product._id);
            setDeleteProduct(true);
          }}
          className="bg-red-500 text-white px-2 py-1 text-base rounded-md font-medium border border-red-800 hover:bg-red-700 transition-all duration-300 ease-in-out"
        >
          Delete Product
        </button>
        <div
          onClick={() => {
            setSeletecedProd(product);
          }}
        >
          <Link
            to="/adminRk/products/updateproduct"
            className="bg-blue-500 text-white px-2 py-1 text-base rounded-md font-medium border border-blue-800 hover:bg-blue-700 transition-all duration-300 ease-in-out"
          >
            Product Details
          </Link>
        </div>
        
      </div>
    </div>
  );
};

export default ProductCardsAdmin;

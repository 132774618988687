import React, { useState } from "react";
import { ChevronDown } from "lucide-react";

const AnimatedViewMore = ({ onClick }) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div className="w-full py-8 px-6">
      <div className="relative w-full max-w-sm mx-auto">
        <button
          onClick={onClick}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
          className="group relative w-full bg-gradient-to-r from-blue-500 to-blue-600 hover:from-blue-600 hover:to-blue-700 text-white font-semibold py-3 px-6 rounded-lg transition-all duration-300 transform hover:-translate-y-1 hover:shadow-lg overflow-hidden"
        >
          <div
            className={`absolute inset-0 bg-gradient-to-r from-blue-400 to-blue-500 transition-transform duration-300 ease-out transform ${
              isHovered ? "translate-x-0" : "-translate-x-full"
            }`}
          />
          <div className="relative flex items-center justify-center space-x-2">
            <span className="transition-transform duration-300 transform group-hover:scale-105">
              View More Services
            </span>
            <ChevronDown
              className={`w-5 h-5 transition-transform duration-300 ${
                isHovered ? "translate-y-1" : "translate-y-0"
              }`}
            />
          </div>

          <div
          
            className={`absolute top-0 left-0 w-full h-full bg-gradient-to-r from-transparent via-white to-transparent opacity-20 transition-transform duration-1000 transform ${
              isHovered ? "translate-x-full" : "-translate-x-full"
            }`}
          />
        </button>

        <div className="absolute -inset-1 bg-gradient-to-r from-blue-400 to-blue-600 rounded-lg blur opacity-30 group-hover:opacity-40 transition duration-300" />
      </div>
    </div>
  );
};

export default AnimatedViewMore;

import React, { useState, useContext } from "react";
import { PencilIcon, Check, X, ImageIcon } from "lucide-react";
import Core from "../core/Core";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import AppContext from "../AppContext/AppContext";
import axios from "axios";

const UpdateProduct = ({ selectedProd }) => {
  const { handleUpdateProduct } = Core();
  const appContext = useContext(AppContext);
  
  const notify = (text, time) => {
    toast.dismiss();
    toast.info(text, {
      toastId: "random1",
      closeButton: false,
      position: "top-center",
      autoClose: time ? time : 1500,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      style: {
        padding: "15px 20px",
        fontSize: "16px",
        textAlign: "center",
        fontWeight: "500",
      },
    });
  };

  const navigate = useNavigate();
  const [product, setProduct] = useState(selectedProd);
  const [editingField, setEditingField] = useState(null);
  const [editValue, setEditValue] = useState("");
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isUploadingImage, setIsUploadingImage] = useState(false);

  const handlePhotoUpload = async (file) => {
    const formData = new FormData();
    formData.append("file", file);

    const options = {
      method: "POST",
      url: process.env.REACT_APP_BASE_URL + `/api/v1/user/s3/operations`,
      headers: {
        Authorization: `Bearer ${appContext.userInfoVal.sessionId}`,
        "content-type": "multipart/form-data",
      },
      data: formData,
    };

    try {
      const { data } = await axios.request(options);
      if (data && data?.data?.[0]) {
        notify("File uploaded successfully!", 1500);
        return data?.data?.[0];
      }
    } catch (error) {
      console.error(error);
      notify("Failed to upload photo. Please try again.", 3000);
      return null;
    }
  };

  const handleImageChange = async (e) => {
    const file = e.target.files[0];
    if (!file) return;

    const maxSize = 5 * 1024 * 1024;
    if (file.size > maxSize) {
      notify("File size should be less than 5MB", 3000);
      return;
    }

    setIsUploadingImage(true);
    try {
      const fileUrl = await handlePhotoUpload(file);
      if (fileUrl) {
        setProduct(prev => ({
          ...prev,
          image: fileUrl
        }));
      }
    } finally {
      setIsUploadingImage(false);
    }
  };

  const handleEdit = (field, value) => {
    setEditingField(field);
    setEditValue(value);
  };

  const handleSave = (field, value) => {
    let updatedProduct = { ...product };

    if (field.includes(".")) {
      const [parentField, childField] = field.split(".");
      updatedProduct[parentField] = {
        ...updatedProduct[parentField],
        [childField]: value,
      };
    } else {
      updatedProduct[field] = value;
    }

    setProduct(updatedProduct);
    setEditingField(null);
  };

  const formatProductData = () => {
    return {
      _id: product._id,
      productName: product.productName,
      description: product.description,
      requiredDocs: product.requiredDocs,
      processFlow: product.processFlow,
      image: product.image,
      price: Number(product.price),
      member_price: Number(product.member_price),
    };
  };

  const updateProduct = async () => {
    try {
      setError(null);
      setIsLoading(true);
      const formattedData = formatProductData();

      const response = await handleUpdateProduct(formattedData);
      if (response) {
        notify("Product updated successfully", 2000);
        navigate("/adminRk/products");
      }
    } catch (error) {
      console.error("Error updating product:", error);
      setError("Failed to update product. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  const renderEditableField = (label, field, value) => (
    <div className="flex items-center justify-between mb-4 gap-4">
      <div className="flex-1">
        <label className="font-medium text-xl text-gray-700">{label}</label>
        {editingField === field ? (
          <div className="flex items-center gap-2 mt-1">
            {field === "description" ? (
              <textarea
                className="w-full p-2 border rounded text-lg focus:outline-none focus:ring-2 focus:ring-blue-600"
                value={editValue}
                onChange={(e) => setEditValue(e.target.value)}
                rows={4}
              />
            ) : (
              <input
                type={typeof value === "number" ? "number" : "text"}
                className="w-full p-2 border text-lg rounded focus:outline-none focus:ring-2 focus:ring-blue-600"
                value={editValue}
                onChange={(e) => setEditValue(e.target.value)}
                style={{ border: "2px solid" }}
              />
            )}
            <button
              onClick={() => handleSave(field, editValue)}
              className="p-1 text-green-600 hover:bg-green-50 rounded"
            >
              <Check size={20} />
            </button>
            <button
              onClick={() => setEditingField(null)}
              className="p-1 text-red-600 hover:bg-red-50 rounded"
            >
              <X size={20} />
            </button>
          </div>
        ) : (
          <div className="flex items-center gap-2">
            <div className="flex-1 mt-1">{value}</div>
            <button
              onClick={() => handleEdit(field, value)}
              className="p-1 text-blue-600 hover:bg-blue-50 rounded"
            >
              <PencilIcon size={20} />
            </button>
          </div>
        )}
      </div>
    </div>
  );

  const renderNestedFields = (parentField, data) => (
    <div className="ml-4 mt-2">
      {Object.entries(data).map(([key, value]) => (
        <div key={key}>
          {renderEditableField(key, `${parentField}.${key}`, value)}
        </div>
      ))}
    </div>
  );

  return (
    <div className="max-w-4xl mx-auto p-6 bg-white rounded-lg shadow">
      <div className="w-full flex justify-center items-center px-4 text-black text-2xl font-semibold mb-10">
        Product Details
      </div>
      <div className="space-y-6">
        {renderEditableField("Product Name", "productName", product?.productName)}
        {renderEditableField("Description", "description", product?.description)}
        {renderEditableField("Price", "price", product?.price)}
        {renderEditableField("Member Price", "member_price", product?.member_price)}

        <div className="mt-6">
          <h3 className="text-lg font-medium text-gray-900 mb-4">
            Required Documents
          </h3>
          {renderNestedFields("requiredDocs", product?.requiredDocs)}
        </div>

        <div className="mt-6">
          <h3 className="text-lg font-medium text-gray-900 mb-4">
            Process Flow
          </h3>
          {renderNestedFields("processFlow", product?.processFlow)}
        </div>

        <div className="mt-6">
          <h3 className="text-lg font-medium text-gray-900 mb-4">
            Product Image
          </h3>
          <div className="space-y-4">
            <div className="relative">
              <img
                src={product?.image}
                alt={product?.productName}
                className="w-full max-w-md rounded-lg shadow"
              />
              <div className="mt-4">
                <label className="block text-gray-700 font-medium mb-2">
                  Update Image
                </label>
                <input
                  type="file"
                  onChange={handleImageChange}
                  disabled={isUploadingImage}
                  className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500 file:mr-4 file:py-2 file:px-4 file:rounded-lg file:border-0 file:text-sm file:bg-blue-50 file:text-blue-700 hover:file:bg-blue-100"
                  accept="image/*"
                  style={{ border: "2px solid gray" }}
                />
                {isUploadingImage && (
                  <p className="text-blue-600 mt-2">Uploading image...</p>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="mt-8">
          <button
            onClick={updateProduct}
            disabled={isLoading || isUploadingImage}
            className={`w-full bg-blue-600 text-white py-2 px-4 rounded-lg hover:bg-blue-700 transition-colors ${
              (isLoading || isUploadingImage) ? "opacity-50 cursor-not-allowed" : ""
            }`}
          >
            {isLoading ? "Updating..." : "Update Product"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default UpdateProduct;
import React, { useContext, useEffect, useState } from "react";
import styles from "./Nav2.module.css";
import logo from "../assets/LogoRkcsc.png";
import AppContext from "../AppContext/AppContext";
import profile from "../assets/userProfile.png";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { TiArrowSortedDown } from "react-icons/ti";
import { motion, AnimatePresence } from "framer-motion";
import Core from "../core/Core";
import { BsCart4 } from "react-icons/bs";
import { MdOutlineCurrencyRupee } from "react-icons/md";

const ProfileDropdown = ({
  onLogout,
  onProfileClick,
  onMYOrdersClick,
  isOpen,
  setIsOpen,
}) => {
  const dropdownRef = React.useRef(null);
  React.useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };
    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen, setIsOpen]);

  return (
    <div className="relative" ref={dropdownRef}>
      <img
        src={profile}
        alt="profile"
        className="h-10 w-10 cursor-pointer rounded-full hover:ring-2 hover:ring-blue-400 transition-all"
        onClick={() => setIsOpen(!isOpen)}
      />
      <AnimatePresence>
        {isOpen && (
          <motion.div
            initial={{ opacity: 0, y: -10 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -10 }}
            transition={{ duration: 0.2 }}
            className="absolute right-0 mt-2 w-48 bg-white rounded-lg shadow-lg border border-gray-200 py-1"
            style={{ zIndex: 1000 }}
          >
            <motion.div
              className="px-4 py-2 text-gray-800 hover:bg-gray-100 cursor-pointer flex items-center"
              whileHover={{ x: 5 }}
              onClick={onProfileClick}
            >
              <svg
                className="w-4 h-4 mr-2"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
                />
              </svg>
              Profile
            </motion.div>
            <motion.div
              className="px-4 py-2 gap-2 text-gray-800 hover:bg-gray-100 cursor-pointer flex items-center"
              whileHover={{ x: 5 }}
              onClick={onMYOrdersClick}
            >
              <BsCart4 />
              My Orders
            </motion.div>
            <motion.div
              className="px-4 py-2 text-red-600 hover:bg-gray-100 cursor-pointer flex items-center"
              whileHover={{ x: 5 }}
              onClick={onLogout}
            >
              <svg
                className="w-4 h-4 mr-2"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"
                />
              </svg>
              Logout
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

const Navbar = ({
  setSigninClicked,
  signinClicked,
  isLoggedIn,
  setISsLoggedIn,
  setUserSignUp,
  navRef,
  memberSignUp,
  setMemberSignUp,
  userSignUp,
  isSignup,
  setIsSignup,
  isClickedAccount,
  setIsClickedAccount,
  isClickedBilling,
  setIsClickedBilling,
  isClickedSupport,
  setIsClickedSupport,
  isWalletClicked,
  setIsWalletClicked,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const appContext = useContext(AppContext);
  const { l } = Core();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 938);
  const [isSignupDropdownOpen, setIsSignupDropdownOpen] = useState(false);
  const [isProfileDropdownOpen, setIsProfileDropdownOpen] = useState(false);

  const handleLogout = () => {
    appContext.setUserInfo((prev) => ({
      ...prev,
      firstName: "rkcsc",
      phoneNumber: null,
      email: "noreply@rkcsc.in",
      picture: "https://picsum.photos/400/400",
      username: null,
      accountType: null,
      wallet: null,
      firmName: null,
      orders: [],
      userId: null,
      sessionId: null,
    }));

    navigate("/");
  };

  const handleProfileClick = () => {
    setIsProfileDropdownOpen(false);
    navigate("/profile");
    setIsClickedBilling(false);
    setIsClickedAccount(true);
    setIsClickedSupport(false);
    setIsWalletClicked(false);
  };

  const handleMyordersClick = () => {
    navigate("/profile");
    setIsClickedBilling(true);
    setIsClickedAccount(false);
    setIsClickedSupport(false);
    setIsWalletClicked(false);
  };

  const handleWalletClick = () => {
    navigate("/profile");
    setIsClickedBilling(false);
    setIsClickedAccount(false);
    setIsClickedSupport(false);
    setIsWalletClicked(true);
  };

  const handleLogoutClick = () => {
    setIsProfileDropdownOpen(false);
    handleLogout();
  };

  useEffect(() => {
    const handleScroll = () => {
      if (isMenuOpen) {
        setIsMenuOpen(false);
      }

      const navbar = document.getElementsByClassName(styles.Navbar)[0];
      if (window.scrollY > 50) {
        navbar.classList.add(styles.scrolled);
      } else {
        navbar.classList.remove(styles.scrolled);
      }
    };

    const handleResize = () => {
      setIsMobile(window.innerWidth <= 938);
    };

    window.addEventListener("scroll", handleScroll);
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("resize", handleResize);
    };
  }, [isMenuOpen]);

  useEffect(() => {
    if (appContext.userInfoVal.sessionId !== null) {
      setISsLoggedIn(true);
    } else {
      setISsLoggedIn(false);
    }
  }, [appContext.userInfoVal.sessionId]);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleProfileNavigation = () => {
    if (location.pathname === "/profile") {
      navigate("/");
    } else {
      navigate("/profile");
    }
  };

  const SignupDropdown = () => (
    <div
      className={`${styles.signupDropdown} ${
        isSignupDropdownOpen ? styles.open : ""
      }`}
    >
      <span className="flex justify-center items-center">
        <span>Sign Up</span>
        <TiArrowSortedDown />
      </span>
      {isSignupDropdownOpen && (
        <div className={styles.dropdownContent}>
          <div
            className={styles.dropdownItem}
            onClick={() => {
              setUserSignUp(true);
              setIsSignup(true);
              setMemberSignUp(false);
              setSigninClicked(true);
              setIsSignupDropdownOpen(false);
            }}
          >
            User Signup
          </div>
          <div
            className={styles.dropdownItem}
            onClick={() => {
              setMemberSignUp(true);
              setSigninClicked(true);
              setUserSignUp(true);
              setIsSignup(true);
              setIsSignupDropdownOpen(false);
            }}
          >
            Partner Signup
          </div>
        </div>
      )}
    </div>
  );

  const renderNavRight = () => (
    <div className={`${styles.navRight}`} ref={navRef}>
      {isLoggedIn ? (
        <>
          {appContext.userInfoVal.accountType === "member" && (
            <li className="select-none" onClick={handleWalletClick}>
              <span className="rounded-xl px-4 py-2 flex justify-center items-center bg-blue-600 text-white text-lg select-none">
              Wallet : <MdOutlineCurrencyRupee /> {appContext.userInfoVal.wallet}
              </span>
            </li>
          )}
          <li className="select-none">
            {`Hi ${appContext.userInfoVal.firstName?.trim().split(" ")[0]}`}
          </li>
          <ProfileDropdown
            onLogout={handleLogoutClick}
            onProfileClick={handleProfileClick}
            onMYOrdersClick={handleMyordersClick}
            isOpen={isProfileDropdownOpen}
            setIsOpen={setIsProfileDropdownOpen}
          />
        </>
      ) : (
        <>
          <li
            onClick={() => {
              setSigninClicked(true);
              setMemberSignUp(false);
              setUserSignUp(false);
              setIsSignup(false);
            }}
          >
            Login
          </li>
          <button
            onMouseEnter={() => setIsSignupDropdownOpen(true)}
            onMouseLeave={() => setIsSignupDropdownOpen(false)}
            className="relative"
          >
            <SignupDropdown />
          </button>
        </>
      )}
    </div>
  );

  const renderMobileNavItems = () => (
    <div className={`${styles.mobileMenu} ${isMenuOpen ? styles.show : ""}`}>
      <ul className={styles.mobileNavList}>
        <li>
          <Link to="/" onClick={toggleMenu}>
            Home
          </Link>
        </li>
        <li>
          <Link to="/profile" onClick={toggleMenu}>
            Profile
          </Link>
        </li>
        <li>
          <Link to="/services" onClick={toggleMenu}>
            Services
          </Link>
        </li>
        <li>
          <Link to="/about" onClick={toggleMenu}>
            About Us
          </Link>
        </li>
        <li>
          <Link to="/contact" onClick={toggleMenu}>
            Contact Us
          </Link>
        </li>
      </ul>
    </div>
  );

  return (
    <div className={styles.Navbar}>
      <Link to="/" className="flex justify-center items-center">
        <img src={logo} alt="Logo" className={styles.logo} />
      </Link>

      {isMobile ? (
        <>
          <div className={styles.navRight}>
            {isLoggedIn ? (
              <img
                src={profile}
                alt="profile"
                className="h-10 w-10 cursor-pointer"
                onClick={handleProfileNavigation}
              />
            ) : (
              <div
                onClick={() => {
                  setSigninClicked(true);
                }}
                className={styles.mobileLoginBtn}
              >
                Login
              </div>
            )}
            <div
              className={`${styles.hamburger} ${isMenuOpen ? styles.open : ""}`}
              onClick={toggleMenu}
            >
              <span></span>
              <span></span>
              <span></span>
            </div>
          </div>
          {isMenuOpen && renderMobileNavItems()}
        </>
      ) : (
        <>
          <ul className={styles.navList}>
            <li
              className={location.pathname === "/" ? styles.activeNavItem : ""}
            >
              <Link to="/">Home</Link>
            </li>
            <li
              className={
                location.pathname === "/services" ? styles.activeNavItem : ""
              }
            >
              <Link to="/services">Services</Link>
            </li>
            <li
              className={
                location.pathname === "/about" ? styles.activeNavItem : ""
              }
            >
              <Link to="/about">About Us</Link>
            </li>
            <li
              className={
                location.pathname === "/contact" ? styles.activeNavItem : ""
              }
            >
              <Link to="/contact">Contact Us</Link>
            </li>
            {(appContext.userInfoVal.accountType === "admin" ||
              appContext.userInfoVal.accountType === "pseudoAdmin") && (
              <li>
                <Link to="/adminRk">Admin Panel</Link>
              </li>
            )}
          </ul>
          {renderNavRight()}
        </>
      )}
    </div>
  );
};

export default Navbar;

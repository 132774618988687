import React, { useContext, useState } from 'react';
import { 
  MdOutlineEmail, 
  MdOutlinePhone, 
  MdOutlineCurrencyRupee 
} from 'react-icons/md';
import { FaRegUser } from 'react-icons/fa';
import { GoOrganization } from 'react-icons/go';
import { IoWalletOutline } from 'react-icons/io5';
import AppContext from '../AppContext/AppContext';

const UserProfile = ({  handleLogout, setShowAddeWallet, setShowUpdateProfile }) => {

    const appContext = useContext(AppContext);
  const [isHovered, setIsHovered] = useState(false);



  return (
      <div className="w-full h-full max-w-2xl transform transition-all duration-500 ease-in-out hover:scale-[1.02]">
        <div className="bg-white rounded-2xl shadow-2xl overflow-hidden">
          <div className="h-20 bg-gradient-to-r from-blue-600 to-blue-400 relative">
            <div className="absolute -bottom-16 left-1/2 transform -translate-x-1/2">
              <div className="relative group">
                <div className="w-28 h-28 rounded-full border-4 border-white overflow-hidden transition-transform duration-300 ease-in-out transform group-hover:scale-110">
                  <img
                    src={appContext.userInfoVal.picture}
                    alt={appContext.userInfoVal.firstName?.trim()?.split(" ")[0]}
                    className="w-full h-full object-cover"
                  />
                </div>
              </div>
            </div>
          </div>

          {/* Content Section */}
          <div className="pt-14 px-8 pb-8">
            <div className="text-center mb-4">
              <h2 className="text-2xl font-bold text-gray-800">
                {appContext.userInfoVal.firstName}
              </h2>
              {/* <p className="text-gray-500">{appContext.userInfoVal.accountType}</p> */}
            </div>

            {/* Info Grid */}
            <div className="space-y-1">
              <InfoItem
                icon={<MdOutlineEmail className="w-5 h-5" />}
                label="Email"
                value={appContext.userInfoVal.email}
              />
              <InfoItem
                icon={<MdOutlinePhone className="w-5 h-5" />}
                label="Phone"
                value={appContext.userInfoVal.phoneNumber}
              />
              {appContext.userInfoVal.accountType === "member" && (
                <>
                  <InfoItem
                    icon={<GoOrganization className="w-5 h-5" />}
                    label="Firm"
                    value={appContext.userInfoVal.firmName}
                  />
                  <div className="flex items-center justify-between p-4 bg-gray-50 rounded-lg transition-all duration-300 hover:bg-gray-100">
                    <div className="flex items-center gap-4">
                      <div className="text-blue-600">
                        <IoWalletOutline className="w-5 h-5" />
                      </div>
                      <div>
                        <p className="text-sm text-gray-500">Wallet Balance</p>
                        <div className="flex items-center text-gray-800">
                          <MdOutlineCurrencyRupee />
                          <span className="font-semibold">{appContext.userInfoVal.wallet}</span>
                        </div>
                      </div>
                    </div>
                    <button
                      onClick={() => setShowAddeWallet(true)}
                      className="px-4 py-2 bg-blue-600 text-white rounded-lg transition-all duration-300 hover:bg-blue-700 transform hover:scale-105"
                    >
                      Add Amount
                    </button>
                  </div>
                </>
              )}
            </div>

            {/* Actions Section */}
            <div className="flex justify-between items-center pt-6 border-t border-gray-200">
              <button
                onClick={() => setShowUpdateProfile(true)}
                className="px-6 py-2 bg-blue-600 text-white rounded-lg transition-all duration-300 hover:bg-blue-700 transform hover:scale-105"
              >
                Update Profile
              </button>
              <button
                onClick={handleLogout}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
                className="group flex items-center gap-2 px-6 py-2 bg-red-50 text-red-600 rounded-lg transition-all duration-300 hover:bg-red-100"
              >
                <span className="transform transition-transform duration-300 group-hover:translate-x-1">
                  Logout
                </span>
                <svg
                  className={`w-5 h-5 transition-all duration-300 ${
                    isHovered ? 'translate-x-1 opacity-100' : 'opacity-0'
                  }`}
                  viewBox="0 0 512 512"
                >
                  <path
                    fill="currentColor"
                    d="M377.9 105.9L500.7 228.7c7.2 7.2 11.3 17.1 11.3 27.3s-4.1 20.1-11.3 27.3L377.9 406.1c-6.4 6.4-15 9.9-24 9.9c-18.7 0-33.9-15.2-33.9-33.9l0-62.1-128 0c-17.7 0-32-14.3-32-32l0-64c0-17.7 14.3-32 32-32l128 0 0-62.1c0-18.7 15.2-33.9 33.9-33.9c9 0 17.6 3.6 24 9.9zM160 96L96 96c-17.7 0-32 14.3-32 32l0 256c0 17.7 14.3 32 32 32l64 0c17.7 0 32 14.3 32 32s-14.3 32-32 32l-64 0c-53 0-96-43-96-96L0 128C0 75 43 32 96 32l64 0c17.7 0 32 14.3 32 32s-14.3 32-32 32z"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>
  );
};

const InfoItem = ({ icon, label, value }) => (
  <div className="flex items-center p-4 bg-gray-50 rounded-lg transition-all duration-300 hover:bg-gray-100">
    <div className="text-blue-600">{icon}</div>
    <div className="ml-4">
      <p className="text-sm text-gray-500">{label}</p>
      <p className="text-gray-800 font-medium overflow-hidden text-ellipsis">{value}</p>
    </div>
  </div>
);

export default UserProfile;